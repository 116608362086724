/*
Template Name: Color Admin - Responsive Admin Dashboard Template build with Twitter Bootstrap 3.3.7 & Bootstrap 4.0.0-Alpha 6
Version: 3.0.0
Author: Sean Ngu
Website: http://www.seantheme.com/color-admin-v3.0/admin/material/

::  1.0 General Reset & Setup
-------------------------------------------
    1.1 Reset and overrides
        
::  2.0 Page Structure Settings
-------------------------------------------
    2.1 Page Options
    2.2 Page Loader
    2.3 Header & header elements
    2.4 Sidebar & sidebar elements
    2.5 Content & content elements
    2.6 Footer & footer elements
    
::  3.0 Component Setting
-------------------------------------------
    3.1 Component - Form Elements
    3.2 Component - Dropdown Menu
    3.3 Component - Tooltip
    3.4 Component - Alert
    3.5 Component - Note Setting
    3.6 Component - Badge & Label Setting
    3.7 Component - Pagination & pager
    3.8 Component - Progress bar
    3.9 Component - Nav Setting
    3.10 Component - Nav Tabs
    3.11 Component - Nav Pills
    3.12 Component - Tab Content
    3.13 Component - Accordion Panel
    3.14 Component - Button
        3.14.1  Component - Button - Default
        3.14.2  Component - Button - White
        3.14.3  Component - Button - Inverse
        3.14.4  Component - Button - Primary
        3.14.5  Component - Button - Success
        3.14.6  Component - Button - Warning
        3.14.7  Component - Button - Danger
        3.14.8  Component - Button - Info
        3.14.9  Component - Button - Pink
        3.14.10 Component - Button - Purple
        3.14.11 Component - Button - Deep Purple
        3.14.12 Component - Button - Indigo
        3.14.13 Component - Button - Light Blue
        3.14.14 Component - Button - Light Green
        3.14.15 Component - Button - Lime
        3.14.16 Component - Button - Yellow
        3.14.17 Component - Button - Amber
        3.14.18 Component - Button - Deep Orange
        3.14.19 Component - Button - Brown
        3.14.20 Component - Button - Blue Grey
        3.14.21 Component - Button - Grey
    3.15 Component - Panel
    3.16.Component - Panel - Panel Expand
    3.17 Component - Panel - Panel loading
    3.18 Component - Modal Setting
    3.19 Component - Media Object
    3.20 Component - Table
    3.21 Component - Well
    3.22 Component - Jumbotron
    3.23 Component - List Group
    3.24 Component - Carousel

::  4.0 Predefined CSS Setting
-------------------------------------------
    4.1 Predefined Classes

::  5.0 Plugins Setting
-------------------------------------------
    5.0 Plugins Setting
    5.1 Plugins - jQuery Draggable
    5.2 Plugins - Slimscrollbar
    5.3 Plugins - Gritter Notification
    5.4 Plugins - Data Table
    5.5 Plugins - Datepicker
    5.6 Plugins - Colorpicker
    5.7 Plugins - Bootstrap Timepicker
    5.8 Plugins - jQuery Autocomplete
    5.9 Plugins - Combobox
    5.10 Plugins - Bootstrap Select
    5.11 Plugins - TagIt
    5.12 Plugins - Parsley
    5.13 Plugins - CKEditor / wysihtml5
    5.14 Plugins - Bootstrap Wizard
    5.15 Plugins - Fullcalendar
    5.16 Plugins - jVectorMap
    5.17 Plugins - Flot Chart
    5.18 Plugins - Sparkline

::  6.0 Page Setting
-------------------------------------------
    6.1 Page - Search Result
    6.2 Media Message
    6.3 Widget Stats
    6.4 Todolist
    6.5 Page - Vector / Google Map
    6.6 Page - Invoice
    6.7 Page - Login
    6.8 Page - Gallery
    6.9 Page - Inbox
    6.10 Page - 404 Error Page

::  7.0 Update - V1.1 
-------------------------------------------
    7.1 Timeline Setting
    7.2 Coming Soon Page Setting

::  8.0 Update - V1.2 
-------------------------------------------
    8.1 Theme Panel Setting
    8.2 Powerange Slider Setting
    8.3 Plugins DateTimepicker
    8.4 Plugins wysihtml5 Sandbox
    8.5 Plugins Typeahead
    8.6 Plugins Select 2 Choice
    8.7 Login Page V2
    
::  9.0 Update - V1.3
-------------------------------------------
    9.1 Widget Chart Setting
    9.2 List Group Item Inverse Setting
    9.3 Bootstrap Calendar Setting
    9.4 Registered Users List Setting
    9.5 Chat List Setting
    9.6 Gallery v2 - Superbox Setting
    
::  10.0 Update - V1.4
-------------------------------------------
    10.1 Page - Email Inbox v2
    10.2 Page - Email Detail v2
    
::  11.0 Update - V1.5
-------------------------------------------
    11.2 Pace Loader Setting
    
::  12.0 Update - V1.6
-------------------------------------------
    12.1 Unlimited Nav Tabs 
    
::  13.0 Update - V1.7
-------------------------------------------
    13.1 Light Sidebar Setting
    13.2 Wide Sidebar Setting
    13.3 Navbar Header Brand Without Background Setting
    13.4 Plugins - Jstree Setting
    13.5 Top Navbar Mega Menu Setting
    13.6 Login & Register Page with News Feed Setting
    13.7 Plugins - Bootstrap Date Range Picker
    13.8 Plugins - Select 2
    13.9 Top Navbar Language Setting
    13.10 Profile Page Setting
    
::  14.0 Update - V1.8
-------------------------------------------
    14.1 Full Color Panel
    14.2 Bootstrap Date Timepicker
    
::  15.0 Update - V1.9
-------------------------------------------
    15.1 Sidebar Transparent
    15.2 Top Menu Element
         15.2.1 Top Menu Sub Menu Setting
         15.2.2 Top Menu Left & Right Control Setting
    15.3 Boxed Layout Setting
    15.4 Nvd3 Chart Setting 
    
::  16.0 Update - V2.0
-------------------------------------------
    16.1 Material Loader
    
::  17.0 Update - V2.1
-------------------------------------------
    17.1 Material Icon Mobile Setting
    17.2 Intro Js Setting
    17.3 Summernote Setting
    17.4 Input Group Addon Focus Setting
    17.5 Bootstrap Color Palette Setting
    17.6 jQuery Simple Colorpicker Setting
    17.7 Dropzone Setting
    
::  18.0 Update - V3.0
-------------------------------------------
    18.1 CSS Checkbox Setting
    18.2 CSS Radio Button Setting
    18.3 Bootstrap SweetAlert Setting
    18.4 Bootstrap 4 - Cards Setting
*/


/* -------------------------------
   1.0 General reset & setup
------------------------------- */

/* 1.1 Reset and overrides */

body,
html {
    height: 100%;
}
body {
    background: #F9F9F9;
    font-family: 'Roboto', "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 13px;
    color: #616161;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}
.ie8 body {
    font-family: Arial,sans-serif;
}
h1, h2, h3, h4, h5, h6{
    font-weight: 500;
    color: #212121;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    font-size: 60%;
    font-weight: normal;
    color: #777;
}
a {
    transition: color 100ms ease-in-out;
    -o-transition: color 100ms ease-in-out;
    -ms-transition: color 100ms ease-in-out;
    -moz-transition: color 100ms ease-in-out;
    -webkit-transition: color 100ms ease-in-out;
}
a:focus {
    outline: none;
}
a {
    color: #2196F3;
}
a:hover, 
a:focus,
 a:active {
    color: #0D47A1;
}
label {
    color: #212121;
}


/* -------------------------------
   2.0 Page Structure Settings
------------------------------- */

/* 2.1 Page Options */

.page-header-fixed {
    padding-top: 60px;
}
.page-without-sidebar .content {
    margin-left: 0;
}
.page-with-right-sidebar .sidebar,
.page-with-right-sidebar .sidebar-bg {
    left: auto;
    right: 0;
}
.page-sidebar-fixed .sidebar {
    position: fixed;
}
.page-with-right-sidebar .content {
    margin-left: 0;
    margin-right: 220px;
}
.page-with-wide-sidebar .footer {
    margin-left: 280px;
}
.page-with-right-sidebar .footer {
    margin-left: 25px;
    margin-right: 280px;
}
.page-with-two-sidebar.page-sidebar-fixed .sidebar.sidebar-right {
    position: fixed;
}
.page-with-two-sidebar .sidebar.sidebar-right,
.page-with-two-sidebar .sidebar-bg.sidebar-right {
    right: 0;
    left: auto;
}
.page-with-two-sidebar .content {
    margin-right: 220px;
}
.page-with-two-sidebar.page-sidebar-minified .sidebar.sidebar-right,
.page-with-two-sidebar.page-sidebar-minified .sidebar-bg.sidebar-right {
    width: 220px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed .sidebar.sidebar-right,
.page-with-two-sidebar.page-right-sidebar-collapsed .sidebar-bg.sidebar-right {
    right: -220px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .sidebar.sidebar-right,
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .sidebar-bg.sidebar-right {
    right: -250px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed .content,
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .content {
    margin-right: 0;
}


/* 2.2 Page Loader */

#page-loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #f7f7f7;
    z-index: 9999;
}
#page-loader.fade {
    display: none;
}
#page-loader.fade.in {
    display: block;
}
@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}
.spinner, 
.spinner-small {
    height: 40px;
    width: 40px;
    margin: 0px auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    border: 2px solid #fff;
    border-top: 2px solid #24B498;
    border-radius: 100%;
}
.fade.in .spinner, 
.fade.in .spinner-small {
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
}
.spinner-small {
    border: 2px solid rgba(0,0,0,0.05);
    border-top: 2px solid #24B498;
    height: 30px;
    width: 30px;
    margin: -15px 0 0 -15px;
}


/* 2.3 Header & header elements */

.header {
    z-index: 1020;
    margin-bottom: 0;
}
.navbar {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.14);
    box-shadow: 0 1px 1px rgba(0,0,0,.14);
    min-height: 60px;
}
.navbar.navbar-default {
    background: #fff;
}
.navbar.navbar-inverse {
    background: #212121;
}
.navbar.navbar-inverse .navbar-brand,
.navbar.navbar-inverse .navbar-nav > li > a {
    color: #a8acb1;
}
.navbar.navbar-inverse .navbar-form .form-control {
    background: url(../../img/transparent/black-0.2.png);
    background: rgba(0,0,0,0.2);
    border-color: #1F2429;
    border-color: rgba(0,0,0,0.2);
    color: #a8acb1;
}
.navbar-brand {
    margin-right: 10px;
    padding: 15px;
    height: 60px;
    width: 220px;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
}
.navbar-default .navbar-brand {
    color: #333;
}
.navbar-logo {
    float: left;
    margin-right: 10px;
    margin-top: 5px;
    border: 10px solid transparent;
    border-color: #4DCACA #31A3A3 #1D8888;
    opacity: 0.9;
    filter: alpha(opacity=90);
}
.navbar-toggle {
    margin: 0;
    padding: 0;
    border: none;
    width: 60px;
    height: 60px;
    border-radius: 0;
    text-align: center;
    color: #fff;
}
.navbar-toggle.navbar-toggle-left {
    float: left;
    margin-left: -15px;
    display: block;
}
.navbar-toggle .icon-bar {
    margin: 0 auto;
    height: 1px;
    width: 20px;
}
.navbar-toggle.navbar-toggle-left .icon-bar {
    background: rgba(0,0,0,0.5);
}
.navbar-inverse .navbar-toggle.navbar-toggle-left .icon-bar {
    background: #fff;
}
.navbar-toggle.pull-left {
    margin-left: 15px;
    margin-right: 0;
}
.navbar-default .navbar-toggle .icon-bar {
    background: #333;
}
.navbar-toggle:hover, 
.navbar-toggle:focus {
    background: none !important;
    opacity: 0.6;
    filter: alpha(opacity=60);
}
.navbar-nav > li > a {
    line-height: 30px;
    padding: 15px;
}
.navbar-default .navbar-nav > li > a {
    color: #585663;
}
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
    opacity: 0.6;
    filter: alpha(opacity=60);
}
.navbar-nav > .open > a, 
.navbar-nav > .open > a:hover, 
.navbar-nav > .open > a:focus {
    background: none !important;
    color: #333;
    opacity: 1.0;
    filter: alpha(opacity=100);
}
.navbar .icon i {
    display: block;
    line-height: 30px;
    font-size: 20px;
}
.navbar-form {
    margin: 13px 0;
}
.fade .navbar-form .form-control {
    -webkit-animation: none;
}
.navbar-form .form-control {
    width: 200px;
    padding: 5px 34px 5px 10px;
    height: 34px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: none;
    background: #eee;
}
.navbar-form .form-control:focus {
    width: 300px;
    animation: expand .2s;
    -webkit-animation: expand .2s;
}
@keyframes expand {
    from { width: 200px; }
    to { width: 300px; }
}
@-webkit-keyframes expand {
    from { width: 200px; }
    to { width: 300px; }
}
.navbar-form .btn-search {
    position: absolute;
    right: 15px;
    top: 13px;
    line-height: 34px;
    height: 34px;
    width: 34px;
    padding: 0;
    border: none;
    background: none;
    text-align: center;
}
.navbar-form .btn-search i {
    line-height: 34px;
    font-size: 20px;
}
.navbar-nav > li > .dropdown-menu {
    margin-top: 0;
}
.navbar-nav > li > .dropdown-menu.media-list .media-heading {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 3px;
}
.navbar-nav > li > a .label {
    position: absolute;
    top: 8px;
    right: 8px;
    display: block;
    background: #F44336;
    line-height: 22px;
    font-weight: 500;
    padding: 0;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    min-width: 22px;
    height: 22px;
}
.navbar-user img {
    float: right;
    width: 36px;
    height: 36px;
    margin: -3px 0px -3px 10px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    border-radius: 36px;
}
.navbar-user img + span {
    display: block;
    margin-right: 46px;
    white-space: nowrap;
}
.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #333;
}
.navbar .navbar-nav > li.divider {
    height: 34px;
    margin-top: 10px;
    background: #E0E0E0;
    width: 1px;
}
.navbar.navbar-inverse .navbar-nav > li.divider {
    background: #3F4B55;
}
.navbar .search-form {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    height: 60px;
    transition: all .3s ease;
}
.navbar.navbar-search-toggled .search-form {
    bottom: 0;
}
.navbar .search-form .search-btn, 
.navbar .search-form .close {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    color: #616161;
    z-index: 1000;
    text-align: center;
    line-height: 60px;
    background: none;
    border: none;
    padding: 0;
    outline: none;
}
.navbar .search-form .search-btn i, .navbar .search-form .close i {
    line-height: 60px;
}
.navbar .search-form .form-control {
    border: none;
    height: inherit;
    box-shadow: none;
    border-radius: 0;
    color: #212121;
    padding-left: 60px;
    padding-right: 60px;
    font-size: 16px;
}
.navbar .search-form .form-control {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.navbar .search-form .close {
    left: auto;
    right: 0;
    text-shadow: none;
    opacity: 1.0;
}


/* 2.4 Sidebar & sidebar elements */

.sidebar {
    width: 200px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-top: 60px;
    background: #003f72;
    z-index: 1010;
    font-size: 13px;
    -webkit-transform: translateZ(0);
}
.sidebar-bg {
    background: #003f72;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    z-index: 1000;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}
.sidebar .nav > li:before,
.sidebar .nav > li:after,
.sidebar .nav > li > a:before,
.sidebar .nav > li > a:after {
    content: '';
    clear: both;
    display: table;
}
.sidebar .nav > li > a {
    padding: 10px 20px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 500;
}
.sidebar.sidebar-grid .nav > li > a {
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
}
.sidebar.sidebar-grid .nav > li.active > a {
    border-color: #212121;
    z-index: 10;
}
.sidebar.sidebar-grid .nav > li + li {
    margin-top: -1px;
}
.sidebar .nav > li.active > a .label.label-theme,
.sidebar .nav > li.active > a .badge {
    background: url(../../img/transparent/black-0.4.png);
    background: rgba(0,0,0,0.4);
}
.sidebar .nav > li.expand > a,
.sidebar .nav > li > a:hover,
.sidebar .nav > li > a:focus {
    background: none;
    color: #CFD8DC;
}
.sidebar .nav > li:hover{
    background: #075799;
}
.sidebar .nav > li.active > a, 
.sidebar .nav > li.active > a:hover, 
.sidebar .nav > li.active > a:focus {
    color: #fff;
    background: #00BCD4;
}
.sidebar .nav > li.active > a .label.label-success,
.sidebar .nav > li.active > a .badge.badge-success {
    background: #008a8a;
}
.sidebar .nav > li > a i {
    float: left;
    margin-right: 10px;
    width: 24px;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    color: #ffffff;
}
.sidebar .nav > li > a i[class*="ion-"] {
    margin-right: 15px;
    width: 18px;
    font-size: 24px;
    margin-top: -2px;
    margin-bottom: -2px;
}
.sidebar .nav > li > a i[class*="icon-"] {
    width: 18px;
    margin-right: 15px;
    font-size: 20px;
}
.sidebar .nav > li > a i.material-icons {
    float: left;
    width: 24px;
    height: 24px;
    text-align: center;
    margin: -2px 10px -2px 0;
    font-size: 24px;
    line-height: 24px;
}
.sidebar .nav > li > a .icon-img {
	float: left;
	margin: -2px 10px -2px 0;
	width: 24px;
	height: 24px;
	overflow: hidden;
	border-radius: 4px;
}
.sidebar .nav > li > a .icon-img img {
	max-width: 100%;
	max-height: 100%;
	display: block;
}
.sidebar .nav > li.active > a i,
.sidebar .nav > li.active > a:hover i,
.sidebar .nav > li.active > a:focus i {
    color: #fff;
}
.sidebar .nav > li > a .badge {
    background: #1b1f24;
    padding: 0 8px;
    margin: 0;
    line-height: 20px;
    font-size: 12px;
    font-weight: normal;
}
.sidebar .sub-menu:before {
    display: none;
}
.sidebar .nav > li > a .caret {
    float: right;
    margin-top: 9px;
}
.sidebar .has-sub.active > .sub-menu {
    display: block;
}
.sidebar .sub-menu {
    list-style-type: none;
    padding: 10px 0 10px 30px;
    margin: 0;
    background: #1a2229;
    position: relative;
    display: none;
}
.sidebar .sub-menu:before {
    content: '';
    position: absolute;
    left: 26px;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #10181F;
}
.sidebar .sub-menu .sub-menu {
    padding: 0 0 0 30px;
    background: none;
}
.sidebar .sub-menu > li > a {
    padding: 5px 20px;
    display:block;
    font-weight: 300;
    color: #889097;
    text-decoration: none;
    position: relative;
}
.sidebar .sub-menu > li > a:hover,
.sidebar .sub-menu > li > a:focus,
.sidebar .sub-menu > li.active > a,
.sidebar .sub-menu > li.active > a:hover,
.sidebar .sub-menu > li.active > a:focus {
    color: #fff;
}
.sidebar .nav > li li.has-sub.active > a {
    color: #889097;
}
.sidebar .nav .sub-menu > li > a .caret {
    float: right; 
    margin-top: 7px;
}
.sidebar .nav > li.has-sub .sub-menu, 
.sidebar li.has-sub .sub-menu > li.active > a, 
.sidebar li.has-sub .sub-menu > li.active > a:hover, 
.sidebar li.has-sub .sub-menu > li.active > a:focus, 
.sidebar .nav > li.has-sub .sub-menu > li > a:hover, 
.sidebar .nav > li.has-sub .sub-menu > li > a:focus {
    background: rgba(0,0,0,0.15);
}
.sidebar .nav > li.has-sub .sub-menu > li > a {
    color: #CFD8DC;
    line-height: 20px;
    padding: 5px 20px 5px 54px;
    display: block;
    font-weight: normal;
}
.sidebar .nav > li.has-sub .sub-menu {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
}
.sidebar .nav > li.nav-header {
    margin: 0;
    padding: 10px 20px 5px;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
}
.sidebar .nav > li.nav-header a {
    padding: 0;
    margin: 0;
    display: inline;
}
.sidebar .nav > li.nav-header a:hover,
.sidebar .nav > li.nav-header a:focus {
    background: none;
    color: #fff;
}
.sidebar .nav > li.nav-header a i {
    float: none;
    margin: 0;
}
.sidebar .nav > li.nav-profile {
    padding: 20px;
    color: #fff;
    background: url(../../img/profile_bg.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
}
.sidebar .nav > li.nav-profile:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(23, 35, 41, 0.75);
    display: block;
}
.sidebar .nav > li.nav-profile > a,
.sidebar .nav > li.nav-profile.active > a,
.sidebar .nav > li.nav-profile.active > a:hover,
.sidebar .nav > li.nav-profile.active > a:focus {
    margin: -20px;
    padding: 20px;
    display: block;
    color: #fff;
    background: none;
}
.sidebar .nav > li.nav-profile > a:hover,
.sidebar .nav > li.nav-profile > a:focus {
    background: none;
}
.sidebar .nav > li.nav-profile > a:before,
.sidebar .nav > li.nav-profile > a:after {
    content: '';
    display: table;
    clear: both;
}
.sidebar .nav > li.nav-profile .image,
.sidebar .nav > li.nav-profile .info {
    position: relative;
}
.sidebar .nav > li.nav-profile .image {
    width: 50px;
    height: 50px;
    margin: 0 0 10px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.sidebar .nav > li.nav-profile .image img {
    max-width: 100%;
    max-height: 100%;
}
.sidebar .nav > li.nav-profile .info {
    font-size: 16px;
    font-weight: 500;
}
.sidebar .nav > li.nav-profile .info small {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #9E9E9E;
}
.sidebar .nav > li.nav-profile .caret {
    margin-top: 15px;
}
.sidebar li.active > a .caret, 
.sidebar li.expand > a .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sidebar li .caret {
    border-width: 5px;
    margin-top: 8px;
    float: right;
    line-height: 20px;
    text-align: center;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    transition: all .2s linear;
}
.sidebar .nav > li.nav-widget {
    padding: 10px 20px;
}
.sidebar .nav > li.nav-widget i {
    margin-right: auto;
}
.page-sidebar-minified .sidebar {
    width: 60px;
    position: absolute;
}
.page-sidebar-minified .sidebar-bg {
    width: 60px;
}
.page-sidebar-minified .content {
    margin-left: 60px;
}
.page-sidebar-minified .footer {
    margin-left: 85px;
}
.page-sidebar-minified .sidebar .slimScrollDiv,
.page-sidebar-minified .sidebar .slimScrollDiv > div {
    overflow: visible !important;
}
.page-sidebar-minified .sidebar.sidebar-right .slimScrollDiv,
.page-sidebar-minified .sidebar.sidebar-right .slimScrollDiv > div {
    overflow: hidden !important;
}
.page-sidebar-minified .sidebar .nav > li > a {
    padding: 10px 17px;
}
.page-sidebar-minified .sidebar .nav-profile,
.page-sidebar-minified .sidebar .nav-header,
.page-sidebar-minified .sidebar .nav > li > a > span {
    display: none;
}
.page-sidebar-minified .sidebar .caret {
    position: absolute;
    top: 10px;
    right: 8px;
    border: 4px solid transparent;
    border-left: 4px solid;
}
.page-sidebar-minified .sidebar .sub-menu .caret {
    top: 10px;
    margin-top: 0 !important;
}
.page-sidebar-minified .sidebar .nav > li > a > i {
    margin: 0;
}
.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    width: 220px;
    display: none;
    padding: 10px 0;
    margin: 0;
    background: #263238;
}
.page-sidebar-minified .sidebar li.active > a .caret, 
.page-sidebar-minified .sidebar li.expand > a .caret,
.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu > li {
    -webkit-animation: none;
    animation: none;
    -webkit-transform: none;
    transform: none;
}
.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu > li > a {
    padding: 5px 30px;
}
.page-sidebar-minified .sidebar .nav > li.has-sub:hover > a,
.page-sidebar-minified .sidebar .nav > li.has-sub:focus > a {
    background: #263238;
}
.page-sidebar-minified .sidebar li.has-sub > .sub-menu {
    display: none !important;
}
.page-sidebar-minified .sidebar li.has-sub:hover > .sub-menu,
.page-sidebar-minified .sidebar li.has-sub:focus > .sub-menu {
    display: block !important;
    overflow: visible !important;
}
.page-sidebar-minified .sidebar .nav li.has-sub {
    position: relative;
}
.page-sidebar-minified .sidebar .nav > li.has-sub li.has-sub .sub-menu {
    margin-top: -10px;
}
.sidebar-minify-btn {
    margin: 10px 0;
    float: right;
    padding: 5px 20px 5px 10px !important;
    background: #1b1f24;
    color: #fff;
    -webkit-border-radius: 20px 0 0 20px;
    -moz-border-radius: 20px 0 0 20px;
    border-radius: 20px 0 0 20px;
}
.sidebar-minify-btn i {
    margin: 0 !important;
    color: #fff;
}
.page-sidebar-minified .sidebar-minify-btn i:before {
    content: '\f101';
}
.page-sidebar-minified.page-with-right-sidebar .content {
    margin-right: 60px;
    margin-left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .footer {
    margin-right: 85px;
    margin-left: 25px;
}
.page-sidebar-minified.page-with-right-sidebar .sub-menu {
    left: auto !important;
    right: 100%;
}
.page-sidebar-minified.page-with-right-sidebar .nav > li.has-sub > a .caret {
    position: absolute;
    left: 5px;
    border: 4px solid transparent;
    border-right: 4px solid;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu .caret {
    left: 0;
    top: 2px;
    border-left: 4px solid transparent !important;
    border-right: 4px solid !important;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .nav li.has-sub > .sub-menu {
    padding: 10px 30px 10px 0;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu:before {
    right: 26px;
    left: auto;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu > li > a:before {
    right: 0;
    left: auto;
    margin-right: -6px;
    margin-left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar-minify-btn i:before {
    content: '\f100';
}
.page-sidebar-minified .sidebar .slimScrollBar,
.page-sidebar-minified .sidebar .slimScrollRail {
    display: none !important;
}
.page-sidebar-minified .sidebar.sidebar-right .slimScrollBar {
    display: block !important;
}
.sidebar .nav.nav-profile.active > li,
.sidebar .nav li.has-sub.expand > .sub-menu > li {
    -webkit-animation: inToRight 0.3s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: inToRight 0.3s both cubic-bezier(0.7, 0, 0.3, 1);
}
.sidebar .nav.nav-profile.active > li:nth-child(2),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(2),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(2) {
    -webkit-animation-delay: 45ms;
    animation-delay: 45ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(3),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(3),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(3) {
    -webkit-animation-delay: 60ms;
    animation-delay: 60ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(4),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(4),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(4) {
    -webkit-animation-delay: 75ms;
    animation-delay: 75ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(5),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(5),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(5) {
    -webkit-animation-delay: 90ms;
    animation-delay: 90ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(6),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(6),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(6) {
    -webkit-animation-delay: 105ms;
    animation-delay: 105ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(7),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(7),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(7) {
    -webkit-animation-delay: 120ms;
    animation-delay: 120ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(8),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(8),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(8) {
    -webkit-animation-delay: 135ms;
    animation-delay: 135ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(9),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(9),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(9) {
    -webkit-animation-delay: 150ms;
    animation-delay: 150ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(10),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(10),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(10) {
    -webkit-animation-delay: 165ms;
    animation-delay: 165ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(11),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(11),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(11) {
    -webkit-animation-delay: 180ms;
    animation-delay: 180ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(12),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(12),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(12) {
    -webkit-animation-delay: 195ms;
    animation-delay: 195ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(13),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(13),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(13) {
    -webkit-animation-delay: 210ms;
    animation-delay: 210ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(14),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(14),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(14) {
    -webkit-animation-delay: 225ms;
    animation-delay: 225ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(15),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(15),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(15) {
    -webkit-animation-delay: 240ms;
    animation-delay: 240ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(16),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(16),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(16) {
    -webkit-animation-delay: 255ms;
    animation-delay: 255ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(17),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(17),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(17) {
    -webkit-animation-delay: 270ms;
    animation-delay: 270ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(18),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(18),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(18) {
    -webkit-animation-delay: 295ms;
    animation-delay: 295ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(19),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(19),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(19) {
    -webkit-animation-delay: 310ms;
    animation-delay: 310ms;
}
.sidebar .nav.nav-profile.active > li:nth-child(20),
.sidebar .nav li.has-sub.active > .sub-menu > li:nth-child(20),
.sidebar .nav li.has-sub.expand > .sub-menu > li:nth-child(20) {
    -webkit-animation-delay: 325ms;
    animation-delay: 325ms;
}
.sidebar .nav.nav-profile > li,
.sidebar .nav li.has-sub.expand > .sub-menu > li {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}
@-webkit-keyframes inToRight {
    to {
        -webkit-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
}
@keyframes inToRight {
    to {
        -webkit-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
}
.sidebar .nav.nav-profile.active {
    display: block;
}
.sidebar .nav.nav-profile {
    display: none;
    padding: 5px 0;
    border-bottom: 1px solid #263238;
    margin-bottom: 5px;
}
.sidebar.sidebar-grid li.nav-profile > a,
.sidebar.sidebar-grid .nav.nav-profile > li > a {
    border: none;
}
.sidebar.sidebar-light .nav.nav-profile {
    border-bottom: 1px solid #E0E0E0;
}
.sidebar .nav > li.divider {
    margin: 5px 0;
    height: 1px;
    background: #263238;
}


/* 2.5 Content & content elements */

.content {
    margin-left: 200px;
    padding: 15px;
}
.content.content-full-width {
    padding: 0;
}
.content.content-full-width .page-header {
    margin: 20px;
}
.content.content-full-width .breadcrumb {
    margin: 0 20px 0 0;
}
.content.content-inverse-mode .page-header,
.content.content-inverse-mode .breadcrumb a,
.content.content-inverse-mode h1,
.content.content-inverse-mode h2,
.content.content-inverse-mode h3,
.content.content-inverse-mode h4,
.content.content-inverse-mode h5,
.content.content-inverse-mode h6 {
    color: #fff;
}
.content.content-inverse-mode .breadcrumb .active,
.content.content-inverse-mode .page-header small {
    color: #fff;
    color: rgba(255,255,255,0.75);
}
.content.content-full-width .page-header,
.content.content-full-width .breadcrumb {
    position: relative;
    z-index: 10;
}
.content.content-full-width .breadcrumb {
    z-index: 20;
}
.page-header {
    font-size: 28px;
    margin: 0 0 20px;
    padding: 0;
    border: none;
    line-height: 28px;
    font-weight: 300;
}
.page-header small {
    font-weight: 300;
}
.breadcrumb {
    padding: 0;
    margin: 0;
    background: none;
    font-size: 14px;
}
.breadcrumb > li {
    line-height: 28px;
}
.ie8 .breadcrumb > li {
    display: inline;
}
.breadcrumb > li.active {
    color: #757575;
}
.breadcrumb > li + li:before {
    content: '›';
    color: #000;
    padding: 0 8px 0 5px;
}



/* 2.6 Footer & footer elements */

.footer {
    z-index: 1020;
    padding: 15px 0;
    margin: 0 25px 0 250px;
    border-top: 1px solid #CAD0D6;
    line-height: 20px;
}


/* -------------------------------
   3. Component Setting
------------------------------- */

/* 3.1 Component - Form Elements */

.form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-control.input-white {
    background: #fff;
    border-color: #fff;
}
.form-control.input-white:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
}
.form-control[disabled], 
.form-control[readonly],
fieldset[disabled] .form-control {
    background: #e0e0e0;
    opacity: 0.6;
    filter: alpha(opacity=60);
}
.form-control[disabled]:focus, 
.form-control[readonly]:focus,
fieldset[disabled] .form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border: 1px solid #ccd0d4;
}
.form-control:focus {
    border-color: #616161;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-control.input-inline {
    display: inline;
    width: auto;
    padding: 0 7px;
}
.form-control.input-xs {
    height: 20px;
}
.form-control.input-lg {
    font-size: 14px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}
.form-horizontal.form-bordered .form-group {
    border-bottom: 1px solid #eee;
    margin: 0;
}
.form-horizontal.form-bordered .form-group:last-child {
    border-bottom: 0;
}
.form-horizontal.form-bordered .form-group > .control-label {
    padding: 22px 15px 15px;
}
.form-horizontal.form-bordered .form-group > div {
    padding: 15px;
}
.form-horizontal.form-bordered .form-group > div {
    border-left: 1px solid #eee;
}
.form-horizontal.form-bordered .form-group > .control-label {
    border-right: 1px solid #eee;
    margin-right: -1px;
}
.form-horizontal.form-bordered .has-feedback .form-control-feedback {
    top: 15px;
}
label {
    font-weight: 500;
}
.has-success .form-control,
.has-success .form-control:focus,
.has-warning .form-control,
.has-warning .form-control:focus,
.has-error .form-control,
.has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.has-success .help-block, 
.has-success .control-label, 
.has-success .radio, 
.has-success .checkbox, 
.has-success .radio-inline, 
.has-success .checkbox-inline,
.has-success .form-control-feedback {
    color: #009688;
}
.has-success .form-control {
    border-color: #009688;
}
.has-success .form-control:focus {
    border-color: #008a8a;
}
.has-warning .help-block, 
.has-warning .control-label, 
.has-warning .radio, 
.has-warning .checkbox, 
.has-warning .radio-inline, 
.has-warning .checkbox-inline,
.has-warning .form-control-feedback {
    color: #FF9800;
}
.has-warning .form-control {
    border-color: #FF9800;
}
.has-warning .form-control:focus {
    border-color: #c47d15;
}
.has-error .help-block, 
.has-error .control-label, 
.has-error .radio, 
.has-error .checkbox, 
.has-error .radio-inline, 
.has-error .checkbox-inline,
.has-error .form-control-feedback {
    color: #F44336;
}
.has-error .form-control {
    border-color: #F44336;
}
.has-error .form-control:focus {
    border-color: #cc4946;
}
.has-error .date-control {
    border-color: #F44336;
}
    .has-error .date-control:focus {
        border-color: #cc4946;
    }
.form-control-feedback {
    line-height: 34px;
}
.input-group-addon {
    background: #E0E0E0;
    border: none;
}
legend {
    padding-bottom: 3px;
    border-bottom: 1px solid #E0E0E0;
    color: #212121;
}
.form-control.without-border {
    border: none;
}
.form-control.inverse-mode {
    background: url(../../img/transparent/white-0.2.png);
    background: rgba(255,255,255,0.2);
    color: #fff;
}


/* 3.2 Component - Dropdown Menu */

.dropdown-menu {
    border: none;
    padding: 10px 0;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.25);
    box-shadow: 0 1px 3px rgba(0,0,0,0.25);
    font-size: 13px;
}
.dropdown-menu > li > a {
    padding: 5px 15px;
}
.dropdown-menu > li > a:hover, 
.dropdown-menu > li > a:focus {
    background: #f5f5f5;
}
.dropdown-menu > .active > a, 
.dropdown-menu > .active > a:hover, 
.dropdown-menu > .active > a:focus {
    background: #2196F3;
}
.dropdown-menu .divider {
    border-color: #eee;
}
.dropdown-menu.media-list {
    max-width: 280px;
    padding: 0;
}
.dropdown-menu.media-list p {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 4px;
    max-width: 205px;
}
.dropdown-menu.media-list .dropdown-header {
    padding: 10px 15px !important;
    background: #e0e0e0;
    position: relative;
    margin-bottom: -1px;
}
.dropdown-menu.media-list > .media {
    margin-top: 0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: -1px;
}
.dropdown-menu.media-list > .media.active {
    border-color: #2196F3;
    color: #BBDEFB !important;
    position: relative;
}
.dropdown-menu.media-list > .media.active .media-heading {
    color: #fff;
}
.dropdown-menu.media-list > .media > a {
    display: block;
    padding: 10px 15px !important;
}
.dropdown-menu.media-list > .media .media-left {
    padding-right: 10px;
}
.dropdown-menu.media-list > .media .media-right {
    padding-left: 10px;
}
.dropdown-menu.media-list > .media .media-object {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}
.dropdown-menu.media-list > .media .media-object.material-icons {
    font-size: 24px;
}
.dropdown-footer {
    padding: 10px 20px;
}
.dropdown-menu > li.dropdown-footer > a {
    padding: 0 !important;
    display: inline !important;
}
.dropdown-menu > li.dropdown-footer > a:hover,
.dropdown-menu > li.dropdown-footer > a:focus {
    background: none !important;
    text-decoration: underline !important;
}


/* 3.3 Component - Tooltip */

.tooltip-inner {
    padding: 4px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}


/* 3.4 Component - Alert */

.alert {
    border: none;
}
.alert.alert-success {
    background: #B2DFDB;
    color: #004D40;
}
.alert.alert-info {
    background: #B3E5FC;
    color: #01579B;
}
.alert.alert-danger {
    background: #FFCDD2;
    color: #B71C1C;
}
.alert.alert-warning {
    background: #FFE0B2;
    color: #E65100;
}


/* 3.5 Component - Note Setting */

.note {
    margin-bottom: 20px;
    padding: 15px;
    border-left: 3px solid;
}
.note.note-success {
    border-color: #009688;
    background: #B2DFDB;
    color: #004D40;
}
.note.note-success h1, 
.note.note-success h2, 
.note.note-success h3, 
.note.note-success h4, 
.note.note-success h5, 
.note.note-success h6 {
    color: #004D40;
}
.note.note-danger {
    border-color: #F44336;
    background: #FFCDD2;
    color: #B71C1C;
}
.note.note-danger h1, 
.note.note-danger h2, 
.note.note-danger h3, 
.note.note-danger h4, 
.note.note-danger h5, 
.note.note-danger h6 {
    color: #B71C1C;
}
.note.note-info {
    border-color: #03A9F4;
    background: #B3E5FC;
    color: #01579B;
}
.note.note-info h1, 
.note.note-info h2, 
.note.note-info h3, 
.note.note-info h4, 
.note.note-info h5, 
.note.note-info h6 {
    color: #01579B;
}
.note.note-warning {
    border-color: #FF9800;
    background: #FFE0B2;
    color: #E65100;
}
.note.note-warning h1, 
.note.note-warning h2, 
.note.note-warning h3, 
.note.note-warning h4, 
.note.note-warning h5, 
.note.note-warning h6 {
    color: #E65100;
}


/* 3.6 Component - Badge & Label Setting */

.badge {
    font-size: 75%;
    line-height: 1.25;
    font-weight: 600;
}
.label {
    font-size: 75%;
    font-weight: 600;
}
.badge.badge-square {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.badge.badge-default,
.label.label-default {
    background: #9E9E9E;
}
.badge.badge-danger,
.label.label-danger {
    background: #F44336;
}
.badge.badge-warning,
.label.label-warning {
    background: #FF9800;
}
.badge.badge-success,
.label.label-success {
    background: #009688;
}
.badge.badge-info,
.label.label-info {
    background: #00BCD4;
}
.badge.badge-primary,
.label.label-primary {
    background: #2196F3;
}
.badge.badge-inverse,
.label.label-inverse {
    background: #000;
}


/* 3.7 Component - Pagination & pager */

.pager li > a, 
.pager li > span,
.pagination > li > a {
    border-color: #E0E0E0;
    color: #212121;
}
.pager.pager-without-border li > a, 
.pager.pager-without-border li > span,
.pagination.pagination-without-border > li > a {
    border-color: #fff;
}
.pagination > .disabled > span, 
.pagination > .disabled > span:hover, 
.pagination > .disabled > span:focus, 
.pagination > .disabled > a, 
.pagination > .disabled > a:hover, 
.pagination > .disabled > a:focus,
.pager > .disabled > span,
.pager > .disabled > a {
    opacity: 0.6;
    filter: alpha(opacity=60);
    border-color: #ddd;
}
.pagination > li > a {
    color: #212121;
    margin-left: 5px;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}
.pagination > li:first-child > a {
    margin-left: 0;
}
.pagination-sm > li > a, 
.pagination-sm > li > span {
    font-size: 10px;
    margin-left: 4px;
}
.pagination-lg > li > a, 
.pagination-lg > li > span {
    font-size: 14px;
    margin-left: 6px;
}
.pager li > a:hover, 
.pager li > a:focus, 
.pager li > span:hover,
.pager li > span:focus,
.pagination > li > a:hover,
.pagination > li > a:focus {
    color: #212121;
    background: #eee;
    border-color: #e0e0e0;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus {
    background: #212121 !important;
    border-color: #212121 !important;
}


/* 3.8 Component - Progress bar */

.progress {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #E0E0E0;
}
.progress-xs {
    height: 5px;
}
.progress-xs .progress-bar {
    line-height: 5px;
}
.progress-sm {
    height: 10px;
}
.progress-sm .progress-bar {
    line-height: 10px;
}
.progress-lg {
    height: 30px;
}
.progress-lg .progress-bar {
    line-height: 30px;
}
.progress-bar {
    background: #2196F3;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress-bar.progress-bar-success {
    background-color: #009688;
}
.progress-bar.progress-bar-info {
    background-color: #03A9F4;
}
.progress-bar.progress-bar-warning {
    background-color: #FF9800;
}
.progress-bar.progress-bar-danger {
    background-color: #F44336;
}
.progress-bar.progress-bar-inverse {
    background-color: #212121;
}


/* 3.9 Component - Nav Setting */

.nav > li > a {
    color: #616161;
}
.nav > li > a:hover,
.nav > li > a:focus {
    color: #212121;
    background: #E0E0E0;
}


/* 3.10 Component - Nav Tabs */

.nav-tabs,
.nav-tabs > li > a,
.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover, 
.nav-tabs > li.active > a:focus,
.nav-tabs.nav-justified > li > a,
.nav-tabs.nav-justified > .active > a, 
.nav-tabs.nav-justified > .active > a:hover, 
.nav-tabs.nav-justified > .active > a:focus {
    border: none !important;
}
.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover, 
.nav-tabs > li.active > a:focus,
.nav-tabs.nav-justified > .active > a, 
.nav-tabs.nav-justified > .active > a:hover, 
.nav-tabs.nav-justified > .active > a:focus {
    color: #212121;
}
.nav-tabs {
    background: #e0e0e0;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}
.nav-tabs.nav-tabs-inverse {
    background: #212121;
}
.nav-tabs.nav-justified > li > a {
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}
.nav-tabs.nav-tabs-inverse > li.active > a,
.nav-tabs.nav-tabs-inverse > li.active > a:hover,
.nav-tabs.nav-tabs-inverse > li.active > a:focus {
    color: #212121;
    background: #fff;
}
.nav-tabs.nav-tabs-inverse > li > a:hover,
.nav-tabs.nav-tabs-inverse > li > a:focus {
    color: #fff;
    background: none;
}
.nav-tabs > li,
.nav-tabs.nav-justified > li {
    margin-bottom: 0;
}
.nav-tabs > li > a {
    margin-right: 5px;
    line-height: 20px;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}


/* 3.11 Component - Nav Pills */

.nav-pills {
    margin-bottom: 10px;
}
.nav-pills > li + li {
    margin-left: 5px;
}
.nav-pills > li > a {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.nav-pills > li.active > a, 
.nav-pills > li.active > a:hover, 
.nav-pills > li.active > a:focus {
    background: #212121;
}

.nav-stacked > li + li {
    margin-left: 0;
    margin-top: 5px;
}


/* 3.12 Component - Tab Content */

.tab-content {
    padding: 15px;
    margin-bottom: 20px;
    background: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.nav-tabs + .tab-content {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
}


/* 3.13 Component - Accordion Panel */

.panel-title a {
    display: block;
}
.panel-title > a:hover,
.panel-title > a:focus {
    text-decoration: none;
}
.panel-inverse .panel-title > a:hover,
.panel-inverse .panel-title > a:focus {
    color: #9E9E9E;
}
.panel-group .panel+.panel {
    margin-top: 10px;
}


/* 3.14 Component - Button */

.btn {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.12),0 2px 2px rgba(0,0,0,0.2);
    box-shadow: 0 0 2px rgba(0,0,0,0.12),0 2px 2px rgba(0,0,0,0.2);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.btn:focus, 
.btn:active:focus, 
.btn.active:focus {
    outline: none;
}
.btn-icon,
.btn.btn-icon {
    display: inline-block;
    width: 28px;
    height: 28px;
    padding: 0;
    border: none;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
}
.btn-circle,
.btn.btn-circle {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.btn-icon.btn-xs {
    width: 16px;
    height: 16px;
    font-size: 8px;
    line-height: 16px;
}
.btn-icon.btn-sm {
    width: 22px;
    height: 22px;
    font-size: 11px;
    line-height: 22px;
}
.btn-icon.btn-lg {
    width: 34px;
    height: 34px;
    font-size: 17px;
    line-height: 34px;
}
.btn-scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 25px;
    z-index: 1020;
    font-size: 23px !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
    -webkit-box-shadow: 0 2px 5px rgba(0,0,0,0.26);
    box-shadow: 0 2px 5px rgba(0,0,0,0.26);
}
.page-with-right-sidebar .btn-scroll-to-top {
    left: 25px;
    right: auto;
}
.btn-block {
    padding-left: 12px;
    padding-right: 12px;
}
.btn:active,
.btn-group .btn:active {
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.2),0 6px 6px rgba(0,0,0,0.23);
    box-shadow: 0 10px 20px rgba(0,0,0,0.2),0 6px 6px rgba(0,0,0,0.23);
} 
.btn.active,
.btn-group .btn.active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
}
.btn-link,
.btn-group .btn {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-group {
    -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.12),0 2px 2px rgba(0,0,0,0.2);
    box-shadow: 0 0 2px rgba(0,0,0,0.12),0 2px 2px rgba(0,0,0,0.2);
}
.btn-link:hover,
.btn-link:focus {
    text-decoration: none;
}
.btn-group .btn+.btn, 
.btn-group .btn+.btn-group, 
.btn-group .btn-group+.btn, 
.btn-group .btn-group+.btn-group {
    margin-left: 0;
}


/* 3.14.1 Component - Button - Default */

.btn.btn-default {
    color: #212121;
    background: #fff;
    border-color: #fff;
}
.btn-default:hover, 
.btn-default:focus, 
.btn-default:active, 
.btn-default:active:focus,
.btn-default.focus,
.btn-default.active,
.btn-default.active.focus, 
.btn-default.active:focus, 
.btn-default.active:hover, 
.btn-default:active.focus, 
.btn-default:active:focus, 
.btn-default:active:hover, 
.open > .dropdown-toggle.btn-default,
.open > .dropdown-toggle.btn-default.focus, 
.open > .dropdown-toggle.btn-default:focus, 
.open > .dropdown-toggle.btn-default:hover {
    background: #EEEEEE;
    border-color: #EEEEEE;
}
.btn-default.disabled, 
.btn-default[disabled], 
fieldset[disabled] .btn-default,
.btn-default.active,
.btn-default.active.focus, 
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default:active:focus, 
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default,
.open > .dropdown-toggle.btn-default.focus, 
.open > .dropdown-toggle.btn-default:focus, 
.open > .dropdown-toggle.btn-default:hover {
    border-color: #BDBDBD;
}
.btn-group .btn.btn-default:not(.active) + .btn.btn-default,
.input-group-btn .btn.btn-default:not(.active) + .btn.btn-default {
    border-left-color: #BDBDBD;
}


/* 3.14.2 Component - Button - White */

.btn.btn-white {
    font-weight: normal;
    color: #333;
    background: #fff;
    border-color: #fff;
}
.btn.btn-white.btn-white-without-border {
    border-color: #fff;
}
.btn.btn-white.btn-white-without-border.active,
.btn.btn-white.btn-white-without-border.active:hover,
.btn.btn-white.btn-white-without-border.active:focus {
    border-color: #EEEEEE;
}
.btn.btn-white.btn-white-without-border:hover,
.btn.btn-white.btn-white-without-border:focus {
    border-color: #EEEEEE;
}
.btn-white:hover, 
.btn-white:focus, 
.btn-white:active, 
.btn-white:active:focus,
.btn-white.focus,
.btn-white.active,
.btn-white.active.focus, 
.btn-white.active:focus, 
.btn-white.active:hover, 
.btn-white:active.focus, 
.btn-white:active:focus, 
.btn-white:active:hover, 
.open > .dropdown-toggle.btn-white,
.open > .dropdown-toggle.btn-white.focus, 
.open > .dropdown-toggle.btn-white:focus, 
.open > .dropdown-toggle.btn-white:hover {
    background: #EEEEEE;
    border-color: #EEEEEE;
}
.btn-white.disabled, 
.btn-white[disabled], 
fieldset[disabled] .btn-white,
.btn-white.active,
.btn-white.active.focus, 
.btn-white.active:hover,
.btn-white.active:focus,
.btn-white:active:focus, 
.btn-white:active:hover {
    border-color: #BDBDBD;
}
.btn-group .btn.btn-white:not(.active) + .btn.btn-white,
.input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
    border-left-color: #BDBDBD;
}


/* 3.14.3 Component - Button - Inverse */

.btn.btn-inverse {
    color: #fff;
    background: #212121;
    border-color: #212121;
}
.btn-inverse:hover, 
.btn-inverse:focus, 
.btn-inverse:active, 
.btn-inverse:active:focus,
.btn-inverse.focus,
.btn-inverse.active,
.btn-inverse.active.focus, 
.btn-inverse.active:focus, 
.btn-inverse.active:hover, 
.btn-inverse:active.focus, 
.btn-inverse:active:focus, 
.btn-inverse:active:hover, 
.open > .dropdown-toggle.btn-inverse,
.open > .dropdown-toggle.btn-inverse.focus, 
.open > .dropdown-toggle.btn-inverse:focus, 
.open > .dropdown-toggle.btn-inverse:hover {
    background: #424242;
    border-color: #424242;
}
.btn-group .btn.btn-inverse:not(.active) + .btn.btn-inverse,
.input-group-btn .btn.btn-inverse:not(.active) + .btn.btn-inverse {
    border-left-color: #000;
}


/* 3.14.4 Component - Button - Primary */

.btn.btn-primary {
    color: #fff;
    background: #1270BC;
    border-color: #1270BC;
}
.btn-primary:hover, 
.btn-primary:focus, 
.btn-primary:active, 
.btn-primary:active:focus,
.btn-primary.focus,
.btn-primary.active,
.btn-primary.active.focus, 
.btn-primary.active:focus, 
.btn-primary.active:hover, 
.btn-primary:active.focus, 
.btn-primary:active:focus, 
.btn-primary:active:hover, 
.open > .dropdown-toggle.btn-primary,
.open > .dropdown-toggle.btn-primary.focus, 
.open > .dropdown-toggle.btn-primary:focus, 
.open > .dropdown-toggle.btn-primary:hover {
    background: #42A5F5;
    border-color: #42A5F5;
}
.btn-group .btn.btn-primary:not(.active) + .btn.btn-primary,
.input-group-btn .btn.btn-primary:not(.active) + .btn.btn-primary {
    border-left-color: #1E88E5;
}


/* 3.14.5 Component - Button - Success */

.btn.btn-success {
    color: #fff;
    background: #009688;
    border-color: #009688;
}
.btn-success:hover, 
.btn-success:focus, 
.btn-success:active, 
.btn-success:active:focus,
.btn-success.focus,
.btn-success.active,
.btn-success.active.focus, 
.btn-success.active:focus, 
.btn-success.active:hover, 
.btn-success:active.focus, 
.btn-success:active:focus, 
.btn-success:active:hover, 
.open > .dropdown-toggle.btn-success,
.open > .dropdown-toggle.btn-success.focus, 
.open > .dropdown-toggle.btn-success:focus, 
.open > .dropdown-toggle.btn-success:hover {
    background: #26A69A;
    border-color: #26A69A;
}
.btn-group .btn.btn-success:not(.active) + .btn.btn-success,
.input-group-btn .btn.btn-success:not(.active) + .btn.btn-success {
    border-left-color: #00897B;
}


/* 3.14.6 Component - Button - Warning */

.btn.btn-warning {
    color: #fff;
    background: #FF9800;
    border-color: #FF9800;
}
.btn-warning:hover, 
.btn-warning:focus, 
.btn-warning:active, 
.btn-warning:active:focus,
.btn-warning.focus,
.btn-warning.active,
.btn-warning.active.focus, 
.btn-warning.active:focus, 
.btn-warning.active:hover, 
.btn-warning:active.focus, 
.btn-warning:active:focus, 
.btn-warning:active:hover, 
.open > .dropdown-toggle.btn-warning,
.open > .dropdown-toggle.btn-warning.focus, 
.open > .dropdown-toggle.btn-warning:focus, 
.open > .dropdown-toggle.btn-warning:hover {
    background: #FFA726;
    border-color: #FFA726;
}
.btn-group .btn.btn-warning:not(.active) + .btn.btn-warning,
.input-group-btn .btn.btn-warning:not(.active) + .btn.btn-warning {
    border-left-color: #FB8C00;
}


/* 3.14.7 Component - Button - Danger */

.btn.btn-danger {
    color: #fff;
    background: #F44336;
    border-color: #F44336;
}
.btn-danger:hover, 
.btn-danger:focus, 
.btn-danger:active, 
.btn-danger:active:focus,
.btn-danger.focus,
.btn-danger.active,
.btn-danger.active.focus, 
.btn-danger.active:focus, 
.btn-danger.active:hover, 
.btn-danger:active.focus, 
.btn-danger:active:focus, 
.btn-danger:active:hover, 
.open > .dropdown-toggle.btn-danger,
.open > .dropdown-toggle.btn-danger.focus, 
.open > .dropdown-toggle.btn-danger:focus, 
.open > .dropdown-toggle.btn-danger:hover {
    background: #EF5350;
    border-color: #EF5350;
}
.btn-group .btn.btn-danger:not(.active) + .btn.btn-danger,
.input-group-btn .btn.btn-danger:not(.active) + .btn.btn-danger {
    border-left-color: #E53935;
}


/* 3.14.8 Component - Button - Info */

.btn.btn-info {
    color: #fff;
    background: #00BCD4;
    border-color: #00BCD4;
}
.btn-info:hover, 
.btn-info:focus, 
.btn-info:active, 
.btn-info:active:focus,
.btn-info.focus,
.btn-info.active,
.btn-info.active.focus, 
.btn-info.active:focus, 
.btn-info.active:hover, 
.btn-info:active.focus, 
.btn-info:active:focus, 
.btn-info:active:hover, 
.open > .dropdown-toggle.btn-info,
.open > .dropdown-toggle.btn-info.focus, 
.open > .dropdown-toggle.btn-info:focus, 
.open > .dropdown-toggle.btn-info:hover {
    background: #26C6DA;
    border-color: #26C6DA;
}
.btn-group .btn.btn-info:not(.active) + .btn.btn-info,
.input-group-btn .btn.btn-info:not(.active) + .btn.btn-info {
    border-left-color: #00ACC1;
}


/* 3.14.9 Component - Button - Pink */

.btn.btn-pink {
    color: #fff;
    background: #E91E63;
    border-color: #E91E63;
}
.btn-pink:hover, 
.btn-pink:focus, 
.btn-pink:active, 
.btn-pink:active:focus,
.btn-pink.focus,
.btn-pink.active,
.btn-pink.active.focus, 
.btn-pink.active:focus, 
.btn-pink.active:hover, 
.btn-pink:active.focus, 
.btn-pink:active:focus, 
.btn-pink:active:hover, 
.open > .dropdown-toggle.btn-pink,
.open > .dropdown-toggle.btn-pink.focus, 
.open > .dropdown-toggle.btn-pink:focus, 
.open > .dropdown-toggle.btn-pink:hover {
    background: #EC407A;
    border-color: #EC407A;
}
.btn-group .btn.btn-pink:not(.active) + .btn.btn-pink,
.input-group-btn .btn.btn-pink:not(.active) + .btn.btn-pink {
    border-left-color: #D81B60;
}


/* 3.14.10 Component - Button - Purple */

.btn.btn-purple {
    color: #fff;
    background: #9C27B0;
    border-color: #9C27B0;
}
.btn-purple:hover, 
.btn-purple:focus, 
.btn-purple:active, 
.btn-purple:active:focus,
.btn-purple.focus,
.btn-purple.active,
.btn-purple.active.focus, 
.btn-purple.active:focus, 
.btn-purple.active:hover, 
.btn-purple:active.focus, 
.btn-purple:active:focus, 
.btn-purple:active:hover, 
.open > .dropdown-toggle.btn-purple,
.open > .dropdown-toggle.btn-purple.focus, 
.open > .dropdown-toggle.btn-purple:focus, 
.open > .dropdown-toggle.btn-purple:hover {
    background: #AB47BC;
    border-color: #AB47BC;
}
.btn-group .btn.btn-purple:not(.active) + .btn.btn-purple,
.input-group-btn .btn.btn-purple:not(.active) + .btn.btn-purple {
    border-left-color: #8E24AA;
}


/* 3.14.11 Component - Button - Deep Purple */

.btn.btn-deep-purple {
    color: #fff;
    background: #673AB7;
    border-color: #673AB7;
}
.btn-deep-purple:hover, 
.btn-deep-purple:focus, 
.btn-deep-purple:active, 
.btn-deep-purple:active:focus,
.btn-deep-purple.focus,
.btn-deep-purple.active,
.btn-deep-purple.active.focus, 
.btn-deep-purple.active:focus, 
.btn-deep-purple.active:hover, 
.btn-deep-purple:active.focus, 
.btn-deep-purple:active:focus, 
.btn-deep-purple:active:hover, 
.open > .dropdown-toggle.btn-deep-purple,
.open > .dropdown-toggle.btn-deep-purple.focus, 
.open > .dropdown-toggle.btn-deep-purple:focus, 
.open > .dropdown-toggle.btn-deep-purple:hover {
    background: #7E57C2;
    border-color: #7E57C2;
}
.btn-group .btn.btn-deep-purple:not(.active) + .btn.btn-deep-purple,
.input-group-btn .btn.btn-deep-purple:not(.active) + .btn.btn-deep-purple {
    border-left-color: #5E35B1;
}


/* 3.14.12 Component - Button - Indigo */

.btn.btn-indigo {
    color: #fff;
    background: #3F51B5;
    border-color: #3F51B5;
}
.btn-indigo:hover, 
.btn-indigo:focus, 
.btn-indigo:active, 
.btn-indigo:active:focus,
.btn-indigo.focus,
.btn-indigo.active,
.btn-indigo.active.focus, 
.btn-indigo.active:focus, 
.btn-indigo.active:hover, 
.btn-indigo:active.focus, 
.btn-indigo:active:focus, 
.btn-indigo:active:hover, 
.open > .dropdown-toggle.btn-indigo,
.open > .dropdown-toggle.btn-indigo.focus, 
.open > .dropdown-toggle.btn-indigo:focus, 
.open > .dropdown-toggle.btn-indigo:hover {
    background: #5C6BC0;
    border-color: #5C6BC0;
}
.btn-group .btn.btn-indigo:not(.active) + .btn.btn-indigo,
.input-group-btn .btn.btn-indigo:not(.active) + .btn.btn-indigo {
    border-left-color: #3949AB;
}


/* 3.14.13 Component - Button - Light Blue */

.btn.btn-light-blue {
    color: #fff;
    background: #03A9F4;
    border-color: #03A9F4;
}
.btn-light-blue:hover, 
.btn-light-blue:focus, 
.btn-light-blue:active, 
.btn-light-blue:active:focus,
.btn-light-blue.focus,
.btn-light-blue.active,
.btn-light-blue.active.focus, 
.btn-light-blue.active:focus, 
.btn-light-blue.active:hover, 
.btn-light-blue:active.focus, 
.btn-light-blue:active:focus, 
.btn-light-blue:active:hover, 
.open > .dropdown-toggle.btn-light-blue,
.open > .dropdown-toggle.btn-light-blue.focus, 
.open > .dropdown-toggle.btn-light-blue:focus, 
.open > .dropdown-toggle.btn-light-blue:hover {
    background: #29B6F6;
    border-color: #29B6F6;
}
.btn-group .btn.btn-light-blue:not(.active) + .btn.btn-light-blue,
.input-group-btn .btn.btn-light-blue:not(.active) + .btn.btn-light-blue {
    border-left-color: #039BE5;
}


/* 3.14.14 Component - Button - Light Green */

.btn.btn-light-green {
    color: #fff;
    background: #8BC34A;
    border-color: #8BC34A;
}
.btn-light-green:hover, 
.btn-light-green:focus, 
.btn-light-green:active, 
.btn-light-green:active:focus,
.btn-light-green.focus,
.btn-light-green.active,
.btn-light-green.active.focus, 
.btn-light-green.active:focus, 
.btn-light-green.active:hover, 
.btn-light-green:active.focus, 
.btn-light-green:active:focus, 
.btn-light-green:active:hover, 
.open > .dropdown-toggle.btn-light-green,
.open > .dropdown-toggle.btn-light-green.focus, 
.open > .dropdown-toggle.btn-light-green:focus, 
.open > .dropdown-toggle.btn-light-green:hover {
    background: #9CCC65;
    border-color: #9CCC65;
}
.btn-group .btn.btn-light-green:not(.active) + .btn.btn-light-green,
.input-group-btn .btn.btn-light-green:not(.active) + .btn.btn-light-green {
    border-left-color: #7CB342;
}


/* 3.14.15 Component - Button - Lime */

.btn.btn-lime {
    color: #fff;
    background: #CDDC39;
    border-color: #CDDC39;
}
.btn-lime:hover, 
.btn-lime:focus, 
.btn-lime:active, 
.btn-lime:active:focus,
.btn-lime.focus,
.btn-lime.active,
.btn-lime.active.focus, 
.btn-lime.active:focus, 
.btn-lime.active:hover, 
.btn-lime:active.focus, 
.btn-lime:active:focus, 
.btn-lime:active:hover, 
.open > .dropdown-toggle.btn-lime,
.open > .dropdown-toggle.btn-lime.focus, 
.open > .dropdown-toggle.btn-lime:focus, 
.open > .dropdown-toggle.btn-lime:hover {
    background: #D4E157;
    border-color: #D4E157;
}
.btn-group .btn.btn-lime:not(.active) + .btn.btn-lime,
.input-group-btn .btn.btn-lime:not(.active) + .btn.btn-lime {
    border-left-color: #C0CA33;
}


/* 3.14.16 Component - Button - Yellow */

.btn.btn-yellow {
    color: #fff;
    background: #FFEB3B;
    border-color: #FFEB3B;
}
.btn-yellow:hover, 
.btn-yellow:focus, 
.btn-yellow:active, 
.btn-yellow:active:focus,
.btn-yellow.focus,
.btn-yellow.active,
.btn-yellow.active.focus, 
.btn-yellow.active:focus, 
.btn-yellow.active:hover, 
.btn-yellow:active.focus, 
.btn-yellow:active:focus, 
.btn-yellow:active:hover, 
.open > .dropdown-toggle.btn-yellow,
.open > .dropdown-toggle.btn-yellow.focus, 
.open > .dropdown-toggle.btn-yellow:focus, 
.open > .dropdown-toggle.btn-yellow:hover {
    background: #FFEE58;
    border-color: #FFEE58;
}
.btn-group .btn.btn-yellow:not(.active) + .btn.btn-yellow,
.input-group-btn .btn.btn-yellow:not(.active) + .btn.btn-yellow {
    border-left-color: #FDD835;
}


/* 3.14.17 Component - Button - Amber */

.btn.btn-amber {
    color: #fff;
    background: #FFC107;
    border-color: #FFC107;
}
.btn-amber:hover, 
.btn-amber:focus, 
.btn-amber:active, 
.btn-amber:active:focus,
.btn-amber.focus,
.btn-amber.active,
.btn-amber.active.focus, 
.btn-amber.active:focus, 
.btn-amber.active:hover, 
.btn-amber:active.focus, 
.btn-amber:active:focus, 
.btn-amber:active:hover, 
.open > .dropdown-toggle.btn-amber,
.open > .dropdown-toggle.btn-amber.focus, 
.open > .dropdown-toggle.btn-amber:focus, 
.open > .dropdown-toggle.btn-amber:hover {
    background: #FFCA28;
    border-color: #FFCA28;
}
.btn-group .btn.btn-amber:not(.active) + .btn.btn-amber,
.input-group-btn .btn.btn-amber:not(.active) + .btn.btn-amber {
    border-left-color: #FFB300;
}


/* 3.14.18 Component - Button - Deep Orange */

.btn.btn-deep-orange {
    color: #fff;
    background: #FF5722;
    border-color: #FF5722;
}
.btn-deep-orange:hover, 
.btn-deep-orange:focus, 
.btn-deep-orange:active, 
.btn-deep-orange:active:focus,
.btn-deep-orange.focus,
.btn-deep-orange.active,
.btn-deep-orange.active.focus, 
.btn-deep-orange.active:focus, 
.btn-deep-orange.active:hover, 
.btn-deep-orange:active.focus, 
.btn-deep-orange:active:focus, 
.btn-deep-orange:active:hover, 
.open > .dropdown-toggle.btn-deep-orange,
.open > .dropdown-toggle.btn-deep-orange.focus, 
.open > .dropdown-toggle.btn-deep-orange:focus, 
.open > .dropdown-toggle.btn-deep-orange:hover {
    background: #FF7043;
    border-color: #FF7043;
}
.btn-group .btn.btn-deep-orange:not(.active) + .btn.btn-deep-orange,
.input-group-btn .btn.btn-deep-orange:not(.active) + .btn.btn-deep-orange {
    border-left-color: #F4511E;
}


/* 3.14.19 Component - Button - Brown */

.btn.btn-brown {
    color: #fff;
    background: #795548;
    border-color: #795548;
}
.btn-brown:hover, 
.btn-brown:focus, 
.btn-brown:active, 
.btn-brown:active:focus,
.btn-brown.focus,
.btn-brown.active,
.btn-brown.active.focus, 
.btn-brown.active:focus, 
.btn-brown.active:hover, 
.btn-brown:active.focus, 
.btn-brown:active:focus, 
.btn-brown:active:hover, 
.open > .dropdown-toggle.btn-brown,
.open > .dropdown-toggle.btn-brown.focus, 
.open > .dropdown-toggle.btn-brown:focus, 
.open > .dropdown-toggle.btn-brown:hover {
    background: #8D6E63;
    border-color: #8D6E63;
}
.btn-group .btn.btn-brown:not(.active) + .btn.btn-brown,
.input-group-btn .btn.btn-brown:not(.active) + .btn.btn-brown {
    border-left-color: #6D4C41;
}


/* 3.14.20 Component - Button - Blue Grey */

.btn.btn-blue-grey {
    color: #fff;
    background: #607D8B;
    border-color: #607D8B;
}
.btn-blue-grey:hover, 
.btn-blue-grey:focus, 
.btn-blue-grey:active, 
.btn-blue-grey:active:focus,
.btn-blue-grey.focus,
.btn-blue-grey.active,
.btn-blue-grey.active.focus, 
.btn-blue-grey.active:focus, 
.btn-blue-grey.active:hover, 
.btn-blue-grey:active.focus, 
.btn-blue-grey:active:focus, 
.btn-blue-grey:active:hover, 
.open > .dropdown-toggle.btn-blue-grey,
.open > .dropdown-toggle.btn-blue-grey.focus, 
.open > .dropdown-toggle.btn-blue-grey:focus, 
.open > .dropdown-toggle.btn-blue-grey:hover {
    background: #78909C;
    border-color: #78909C;
}
.btn-group .btn.btn-blue-grey:not(.active) + .btn.btn-blue-grey,
.input-group-btn .btn.btn-blue-grey:not(.active) + .btn.btn-blue-grey {
    border-left-color: #546E7A;
}


/* 3.14.21 Component - Button - Grey */

.btn.btn-grey {
    color: #fff;
    background: #9E9E9E;
    border-color: #9E9E9E;
}
.btn-grey:hover, 
.btn-grey:focus, 
.btn-grey:active, 
.btn-grey:active:focus,
.btn-grey.focus,
.btn-grey.active,
.btn-grey.active.focus, 
.btn-grey.active:focus, 
.btn-grey.active:hover, 
.btn-grey:active.focus, 
.btn-grey:active:focus, 
.btn-grey:active:hover, 
.open > .dropdown-toggle.btn-grey,
.open > .dropdown-toggle.btn-grey.focus, 
.open > .dropdown-toggle.btn-grey:focus, 
.open > .dropdown-toggle.btn-grey:hover {
    background: #BDBDBD;
    border-color: #BDBDBD;
}
.btn-group .btn.btn-grey:not(.active) + .btn.btn-grey,
.input-group-btn .btn.btn-grey:not(.active) + .btn.btn-grey {
    border-left-color: #757575;
}


/* 3.15 Component - Panel */

.panel {
    border: none;
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.15);
    box-shadow: 0 2px 3px rgba(0,0,0,0.15);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.panel.panel-no-rounded-corner .panel-heading,
.panel.panel-no-rounded-corner .panel-body,
.panel.panel-no-rounded-corner .panel-footer {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}
.panel-heading {
    padding: 10px 15px;
    border: none;
}
.panel-heading + .table,
.panel-heading + .slimScrollDiv {
    border-top: 1px solid #eee;
}
.panel-heading-btn {
    float: right;
}
.panel-heading-btn > a {
    margin-left: 8px;
}
.panel-heading .btn-group .btn {
    margin-top: -7px;
}
.panel-heading .btn-group .btn.btn-sm {
    margin-top: -5px;
}
.panel-heading .btn-group .btn.btn-xs {
    margin-top: -1px;
}
.panel-heading .label.pull-left,
.panel-heading .label.pull-right {
    line-height: 15px;
}
.panel-heading .progress.pull-right,
.panel-heading .progress.pull-left {
    width: 40%;
    min-width: 120px;
}
.panel-heading + .alert {
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.panel-with-tabs.panel-default .panel-heading {
    background: #E0E0E0;
    color: #333;
}
.panel-heading .nav-tabs {
    margin-top: -10px;
    margin-right: -15px;
}
.panel-heading .nav-tabs > li > a {
    padding: 10px 15px;
    line-height: 20px;
}
.panel-title {
    line-height: 20px;
    font-size: 14px;
}
.panel-title .accordion-toggle {
    margin: -10px -15px;
    padding: 10px 15px;
}
.panel-title .accordion-toggle.accordion-toggle-styled .fa:before {
    content: '\f056';
}
.panel-title .accordion-toggle.accordion-toggle-styled.collapsed .fa:before {
    content: '\f055';
}
.panel-title .pull-right {
    line-height: 20px;
}
.panel-toolbar {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 10px 15px;
    background: #fff;
}
.panel-toolbar + .form-control {
    margin: -1px 0 0;
    border-right: none;
    border-left: none;
}
.panel-group .panel {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.form-control + .panel-footer {
    border-top: none;
}
.panel-body {
    padding: 15px;
}
.panel-body.no-border {
    border: none !important;
}
.panel-body.panel-table,
.panel-body.panel-form,
.panel-body.no-padding,
.panel-body.panel-full-width {
    padding: 0 !important;
}
.panel-body.with-table > .table {
    border: 0;
    margin: 0;
}
.panel-body.with-table > .table tr:last-child th,
.panel-body.with-table > .table tr:last-child td{
    border-bottom: 0;
}
.panel-default > .panel-heading + .panel-collapse .panel-body {
    border-top: 1px solid #eee;
}
.panel-footer {
    background: #fff;
    border-top: 1px solid #eee;
}
.panel .tab-content {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
}
.panel-default > .panel-heading {
    background: #fafafa;
}
.panel-inverse > .panel-heading,
.panel-success > .panel-heading,
.panel-warning > .panel-heading,
.panel-danger > .panel-heading,
.panel-primary > .panel-heading,
.panel-info > .panel-heading {
    color: #fff;
}
.panel-inverse > .panel-heading { background: #212121; }
.panel-success > .panel-heading { background: #009688; }
.panel-warning > .panel-heading { background: #FF9800; }
.panel-danger > .panel-heading { background: #F44336; }
.panel-primary > .panel-heading { background: #2196F3; }
.panel-info > .panel-heading { background: #00BCD4; }


/* 3.16.Component - Panel - Panel Expand */

.panel.panel-expand {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    overflow: hidden;
    z-index: 1080;
}
.panel-expand .height-xs,
.panel-expand .height-sm,
.panel-expand .height-md,
.panel-expand .height-lg,
.panel-expand .height-full {
    height: 100% !important;
}
@keyframes panelExpand {
    from { top: 50%; left: 50%; right: 50%; bottom: 50%; }
    to { top: 0; left: 0; right: 0; bottom: 0; }
}
@-webkit-keyframes panelExpand {
    from { top: 50%; left: 50%; right: 50%; bottom: 50%; }
    to { top: 0; left: 0; right: 0; bottom: 0; }
}
.panel.panel-expand > .panel-heading .fa.fa-expand:before {
    content: '\f066';
}
.panel.panel-expand,
.panel.panel-expand > .panel-heading,
.panel.panel-expand > .panel-body {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.panel.panel-expand > .panel-body {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 40px;
    overflow-y: scroll;
    z-index: 1020;
}
.panel.panel-expand > .panel-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}


/* 3.17 Component - Panel - Panel loading */

.panel.panel-loading .panel-body {
    position: relative;
    z-index: 0;
}
.panel.panel-loading.panel-expand .panel-body {
    position: absolute;
}
.panel.panel-loading .panel-body .panel-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.9;
    filter: alpha(opacity=90);
    animation: fadeIn .2s;
    -webkit-animation: fadeIn .2s;
    z-index: 1020;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}


/* 3.18 Component - Modal Setting */

.modal-content {
    border: none;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.modal-header {
    padding: 12px 15px;
    border-bottom-color: #E0E0E0;
}
.modal-header .close {
    margin-top: 2px;
}
.modal-body {
    padding: 15px;
}
.modal-footer {
    border-top-color: #E0E0E0;
    padding: 14px 15px 15px;
}

.modal-message .modal-dialog {
    width: 100%;
}
.modal-message .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.modal-message .modal-header,
.modal-message .modal-body,
.modal-message .modal-footer {
    width: 60%;
    border: none;
    margin: 0 auto;
}
.modal-backdrop.fade.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
}


/* 3.19 Component - Media Object */

.media,
.media-body {
    overflow: hidden;
    zoom: 1;
}
.media .media-object {
    width: 128px;
}
.media.media-lg .media-object {
    width: 256px;
}
.media.media-sm .media-object {
    width: 64px;
}
.media.media-xs .media-object {
    width: 32px;
}
.media > .pull-left,
.media > .media-left {
    padding-right: 15px;
}
.media > .pull-right,
.media > .media-right {
    padding-left: 15px;
}
.media a:not(.btn):hover,
.media a:not(.btn):focus,
.media a:not(.btn):hover .media-heading,
.media a:not(.btn):focus .media-heading,
.media a:not(.btn).media-heading:hover,
.media a:not(.btn).media-heading:focus {
    color: #212121;
    text-decoration: none;
}
.media-list.media-list-with-divider > li + li {
    border-top: 1px solid #eee;
    padding-top: 20px;
}


/* 3.20 Component - Table */

.table {
    border-color: #E0E0E0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
}
.table > thead > tr > th {
    color: #212121;
    font-weight: 600;
    border-bottom: 2px solid #E0E0E0 !important;
}
.table > thead > tr > th, 
.table > tbody > tr > th, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > tbody > tr > td, 
.table > tfoot > tr > td {
    border-color: #E0E0E0;
    padding: 10px 10px;
    background: #fff;
}
.table-condensed > thead > tr > th, 
.table-condensed > tbody > tr > th, 
.table-condensed > tfoot > tr > th, 
.table-condensed > thead > tr > td, 
.table-condensed > tbody > tr > td, 
.table-condensed > tfoot > tr > td {
    padding: 2px 2px;
}
.table-hover > tbody > tr:hover > td, 
.table-hover > tbody > tr:hover > th {
    background: #e8ecf1 !important;
}
.table-striped > tbody > tr:nth-child(odd) > td, 
.table-striped > tbody > tr:nth-child(odd) > th {
    background: #F5F5F5;
}
.table.table-inverse {
    background: none;
}
.table.table-inverse > thead > tr > th, 
.table.table-inverse > tbody > tr > th, 
.table.table-inverse > tfoot > tr > th, 
.table.table-inverse > thead > tr > td, 
.table.table-inverse > tbody > tr > td, 
.table.table-inverse > tfoot > tr > td {
    border-color: #999 !important;
    border-color: rgba(0,0,0,0.2) !important;
    background: none;
}
.table.table-inverse,
.table.table-inverse > thead > tr > th, 
.table.table-inverse > tbody > tr > th, 
.table.table-inverse > tfoot > tr > th {
    color: #fff;
}
.table > thead > tr > td.info, 
.table > tbody > tr > td.info, 
.table > tfoot > tr > td.info, 
.table > thead > tr > th.info, 
.table > tbody > tr > th.info, 
.table > tfoot > tr > th.info, 
.table > thead > tr.info > td, 
.table > tbody > tr.info > td, 
.table > tfoot > tr.info > td, 
.table > thead > tr.info > th, 
.table > tbody > tr.info > th, 
.table > tfoot > tr.info > th {
    background: #E1F5FE;
    border-color: #81D4FA;
}
.table > thead > tr > td.success, 
.table > tbody > tr > td.success, 
.table > tfoot > tr > td.success, 
.table > thead > tr > th.success, 
.table > tbody > tr > th.success, 
.table > tfoot > tr > th.success, 
.table > thead > tr.success > td, 
.table > tbody > tr.success > td, 
.table > tfoot > tr.success > td, 
.table > thead > tr.success > th, 
.table > tbody > tr.success > th, 
.table > tfoot > tr.success > th {
    background: #E0F2F1;
    border-color: #80CBC4;
}
.table > thead > tr > td.danger, 
.table > tbody > tr > td.danger, 
.table > tfoot > tr > td.danger, 
.table > thead > tr > th.danger, 
.table > tbody > tr > th.danger, 
.table > tfoot > tr > th.danger, 
.table > thead > tr.danger > td, 
.table > tbody > tr.danger > td, 
.table > tfoot > tr.danger > td, 
.table > thead > tr.danger > th, 
.table > tbody > tr.danger > th, 
.table > tfoot > tr.danger > th {
    background: #FFEBEE;
    border-color: #EF9A9A;
}
.table > thead > tr > td.warning, 
.table > tbody > tr > td.warning, 
.table > tfoot > tr > td.warning, 
.table > thead > tr > th.warning, 
.table > tbody > tr > th.warning, 
.table > tfoot > tr > th.warning, 
.table > thead > tr.warning > td, 
.table > tbody > tr.warning > td, 
.table > tfoot > tr.warning > td, 
.table > thead > tr.warning > th, 
.table > tbody > tr.warning > th, 
.table > tfoot > tr.warning > th {
    background: #FFF3E0;
    border-color: #FFCC80;
}
.table > thead > tr > td.active, 
.table > tbody > tr > td.active, 
.table > tfoot > tr > td.active, 
.table > thead > tr > th.active, 
.table > tbody > tr > th.active, 
.table > tfoot > tr > th.active, 
.table > thead > tr.active > td, 
.table > tbody > tr.active > td, 
.table > tfoot > tr.active > td, 
.table > thead > tr.active > th, 
.table > tbody > tr.active > th, 
.table > tfoot > tr.active > th {
    background: #F5F5F5;
    border-color: #e9e9e9;
}
.table.table-without-bg,
.table.table-without-bg tr td,
.table.table-without-bg tr th {
    background: none;
}


/* 3.21 Component - Well */

.well {
    padding: 15px;
    background: #fff;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.well-sm {
    padding: 10px;
}
.well-lg {
    padding: 30px;
}


/* 3.22 Component - Jumbotron */

.jumbotron {
    background: #eee;
}
.jumbotron h1, 
.jumbotron .h1 {
    font-size: 56px;
}
.jumbotron p {
    font-size: 18px;
}


/* 3.23 Component - List Group */

a.list-group-item.active, 
a.list-group-item.active:hover, 
a.list-group-item.active:focus {
    background: #2196F3;
}
a.list-group-item.active > .badge, 
.nav-pills > .active > a > .badge {
    color: #212121;
}
.nav.nav-pills.nav-sm > li {
    margin: 0 0 3px;
}
.nav.nav-pills.nav-sm > li a {
    padding: 8px 10px;
    line-height: 1.5;
}


/* 3.24 Component - Carousel */

.carousel .carousel-control .fa {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: block;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    text-align: center;
    line-height: 30px;
    margin-left: -15px;
}
.carousel .carousel-control.left .fa {
    margin-left: 15px;
}



/* -------------------------------
   4.0 Predefined CSS Setting
------------------------------- */

/* 4.1 Predefined Classes */

.row { margin: 0 -10px; }
.row > [class*="col-"] {  padding: 0 10px; }

.row.row-space-0 { margin: 0; }
.row.row-space-2 { margin: 0 -1px; }
.row.row-space-4 { margin: 0 -2px; }
.row.row-space-6 { margin: 0 -3px; }
.row.row-space-8 { margin: 0 -4px; }
.row.row-space-10 { margin: 0 -5px; }
.row.row-space-12 { margin: 0 -6px; }
.row.row-space-14 { margin: 0 -7px; }
.row.row-space-16 { margin: 0 -8px; }
.row.row-space-18 { margin: 0 -9px; }
.row.row-space-18 { margin: 0 -10px; }
.row.row-space-22 { margin: 0 -11px; }
.row.row-space-24 { margin: 0 -12px; }
.row.row-space-26 { margin: 0 -13px; }
.row.row-space-28 { margin: 0 -14px; }
.row.row-space-30 { margin: 0 -15px; }
.row.row-space-0 > [class*="col-"] {  padding: 0; }
.row.row-space-2 > [class*="col-"] {  padding: 0 1px; }
.row.row-space-4 > [class*="col-"] {  padding: 0 2px; }
.row.row-space-6 > [class*="col-"] {  padding: 0 3px; }
.row.row-space-8 > [class*="col-"] {  padding: 0 4px; }
.row.row-space-10 > [class*="col-"] {  padding: 0 5px; }
.row.row-space-12 > [class*="col-"] {  padding: 0 6px; }
.row.row-space-14 > [class*="col-"] {  padding: 0 7px; }
.row.row-space-16 > [class*="col-"] {  padding: 0 8px; }
.row.row-space-18 > [class*="col-"] {  padding: 0 9px; }
.row.row-space-20 > [class*="col-"] {  padding: 0 10px; }
.row.row-space-22 > [class*="col-"] {  padding: 0 11px; }
.row.row-space-24 > [class*="col-"] {  padding: 0 12px; }
.row.row-space-26 > [class*="col-"] {  padding: 0 13px; }
.row.row-space-28 > [class*="col-"] {  padding: 0 14px; }
.row.row-space-30 > [class*="col-"] {  padding: 0 15px; }

.semi-bold { font-weight: 600; }

.overflow-auto { overflow: auto !important; }
.overflow-hidden { overflow: hidden !important; }
.overflow-visible { overflow: visible !important; }
.overflow-scroll { overflow: scroll !important; }
.overflow-x-hidden { overflow-x: hidden !important; }
.overflow-x-visible { overflow-x: visible !important; }
.overflow-x-scroll { overflow-x: scroll !important; }
.overflow-y-hidden { overflow-y: hidden !important; }
.overflow-y-visible { overflow-y: visible !important; }
.overflow-y-scroll { overflow-y: scroll !important; }

.m-auto { margin: 0 auto !important; }
.m-0 { margin: 0px !important; }
.m-1 { margin: 1px !important; }
.m-2 { margin: 2px !important; }
.m-3 { margin: 3px !important; }
.m-4 { margin: 4px !important; }
.m-5 { margin: 5px !important; }
.m-10 { margin: 10px !important; }
.m-15 { margin: 15px !important; }
.m-20 { margin: 20px !important; }
.m-25 { margin: 25px !important; }
.m-30 { margin: 30px !important; }
.m-35 { margin: 35px !important; }
.m-40 { margin: 40px !important; }

.m-t-0 { margin-top: 0px !important; }
.m-t-1 { margin-top: 1px !important; }
.m-t-2 { margin-top: 2px !important; }
.m-t-3 { margin-top: 3px !important; }
.m-t-4 { margin-top: 4px !important; }
.m-t-5 { margin-top: 5px !important; }
.m-t-10 { margin-top: 10px !important; }
.m-t-15 { margin-top: 15px !important; }
.m-t-20 { margin-top: 20px !important; }
.m-t-25 { margin-top: 25px !important; }
.m-t-30 { margin-top: 30px !important; }
.m-t-35 { margin-top: 35px !important; }
.m-t-40 { margin-top: 40px !important; }

.m-r-0 { margin-right: 0px !important; }
.m-r-1 { margin-right: 1px !important; }
.m-r-2 { margin-right: 2px !important; }
.m-r-3 { margin-right: 3px !important; }
.m-r-4 { margin-right: 4px !important; }
.m-r-5 { margin-right: 5px !important; }
.m-r-10 { margin-right: 10px !important; }
.m-r-15 { margin-right: 15px !important; }
.m-r-20 { margin-right: 20px !important; }
.m-r-25 { margin-right: 25px !important; }
.m-r-30 { margin-right: 30px !important; }
.m-r-35 { margin-right: 35px !important; }
.m-r-40 { margin-right: 40px !important; }

.m-b-0 { margin-bottom: 0px !important; }
.m-b-1 { margin-bottom: 1px !important; }
.m-b-2 { margin-bottom: 2px !important; }
.m-b-3 { margin-bottom: 3px !important; }
.m-b-4 { margin-bottom: 4px !important; }
.m-b-5 { margin-bottom: 5px !important; }
.m-b-10 { margin-bottom: 10px !important; }
.m-b-15 { margin-bottom: 15px !important; }
.m-b-20 { margin-bottom: 20px !important; }
.m-b-25 { margin-bottom: 25px !important; }
.m-b-30 { margin-bottom: 30px !important; }
.m-b-35 { margin-bottom: 35px !important; }
.m-b-40 { margin-bottom: 40px !important; }

.m-l-0 { margin-left: 0px !important; }
.m-l-1 { margin-left: 1px !important; }
.m-l-2 { margin-left: 2px !important; }
.m-l-3 { margin-left: 3px !important; }
.m-l-4 { margin-left: 4px !important; }
.m-l-5 { margin-left: 5px !important; }
.m-l-10 { margin-left: 10px !important; }
.m-l-15 { margin-left: 15px !important; }
.m-l-20 { margin-left: 20px !important; }
.m-l-25 { margin-left: 25px !important; }
.m-l-30 { margin-left: 30px !important; }
.m-l-35 { margin-left: 35px !important; }
.m-l-40 { margin-left: 40px !important; }

.p-0 { padding: 0px !important; }
.p-1 { padding: 1px !important; }
.p-2 { padding: 2px !important; }
.p-3 { padding: 3px !important; }
.p-4 { padding: 4px !important; }
.p-5 { padding: 5px !important; }
.p-10 { padding: 10px !important; }
.p-15, .wrapper { padding: 15px !important; }
.p-20 { padding: 20px !important; }
.p-25 { padding: 25px !important; }
.p-30 { padding: 30px !important; }
.p-35 { padding: 35px !important; }
.p-40 { padding: 40px !important; }

.p-t-0 { padding-top: 0px !important; }
.p-t-1 { padding-top: 1px !important; }
.p-t-2 { padding-top: 2px !important; }
.p-t-3 { padding-top: 3px !important; }
.p-t-4 { padding-top: 4px !important; }
.p-t-5 { padding-top: 5px !important; }
.p-t-10 { padding-top: 10px !important; }
.p-t-15 { padding-top: 15px !important; }
.p-t-20 { padding-top: 20px !important; }
.p-t-25 { padding-top: 25px !important; }
.p-t-30 { padding-top: 30px !important; }
.p-t-35 { padding-top: 35px !important; }
.p-t-40 { padding-top: 40px !important; }

.p-r-0 { padding-right: 0px !important; }
.p-r-1 { padding-right: 1px !important; }
.p-r-2 { padding-right: 2px !important; }
.p-r-3 { padding-right: 3px !important; }
.p-r-4 { padding-right: 4px !important; }
.p-r-5 { padding-right: 5px !important; }
.p-r-10 { padding-right: 10px !important; }
.p-r-15 { padding-right: 15px !important; }
.p-r-20 { padding-right: 20px !important; }
.p-r-25 { padding-right: 25px !important; }
.p-r-30 { padding-right: 30px !important; }
.p-r-35 { padding-right: 35px !important; }
.p-r-40 { padding-right: 40px !important; }

.p-b-0 { padding-bottom: 0px !important; }
.p-b-1 { padding-bottom: 1px !important; }
.p-b-2 { padding-bottom: 2px !important; }
.p-b-3 { padding-bottom: 3px !important; }
.p-b-4 { padding-bottom: 4px !important; }
.p-b-5 { padding-bottom: 5px !important; }
.p-b-10 { padding-bottom: 10px !important; }
.p-b-15 { padding-bottom: 15px !important; }
.p-b-20 { padding-bottom: 20px !important; }
.p-b-25 { padding-bottom: 25px !important; }
.p-b-30 { padding-bottom: 30px !important; }
.p-b-35 { padding-bottom: 35px !important; }
.p-b-40 { padding-bottom: 40px !important; }

.p-l-0 { padding-left: 0px !important; }
.p-l-1 { padding-left: 1px !important; }
.p-l-2 { padding-left: 2px !important; }
.p-l-3 { padding-left: 3px !important; }
.p-l-4 { padding-left: 4px !important; }
.p-l-5 { padding-left: 5px !important; }
.p-l-10 { padding-left: 10px !important; }
.p-l-15 { padding-left: 15px !important; }
.p-l-20 { padding-left: 20px !important; }
.p-l-25 { padding-left: 25px !important; }
.p-l-30 { padding-left: 30px !important; }
.p-l-35 { padding-left: 35px !important; }
.p-l-40 { padding-left: 40px !important; }

.f-s-8 { font-size: 8px !important; }
.f-s-9 { font-size: 9px !important; }
.f-s-10 { font-size: 10px !important; }
.f-s-11 { font-size: 11px !important; }
.f-s-12 { font-size: 12px !important; }
.f-s-13 { font-size: 13px !important; }
.f-s-14 { font-size: 14px !important; }
.f-s-15 { font-size: 15px !important; }
.f-s-16 { font-size: 16px !important; }
.f-s-17 { font-size: 17px !important; }
.f-s-18 { font-size: 18px !important; }
.f-s-19 { font-size: 19px !important; }
.f-s-20 { font-size: 20px !important; }

.text-center { text-align: center !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }

.pull-left { float: left !important; }
.pull-right { float: right !important; }
.pull-none { float: none !important; }

.f-w-100 { font-weight: 100 !important; }
.f-w-200 { font-weight: 200 !important; }
.f-w-300 { font-weight: 300 !important; }
.f-w-400 { font-weight: 400 !important; }
.f-w-500 { font-weight: 500 !important; }
.f-w-600 { font-weight: 600 !important; }
.f-w-700 { font-weight: 700 !important; }

.table-valign-middle th, 
.table-valign-middle td { 
    vertical-align: middle !important;
}
.table-th-valign-middle th,
.table-td-valign-middle td { 
    vertical-align: middle !important;
}
.table-valign-top th, 
.table-valign-top td { 
    vertical-align: top !important;
}
.table-th-valign-top th,
.table-td-valign-top td { 
    vertical-align: top !important;
}
.table-valign-bottom th, 
.table-valign-bottom td { 
    vertical-align: bottom !important;
}
.table-th-valign-bottom th,
.table-td-valign-bottom td { 
    vertical-align: bottom !important;
}
.vertical-box {
    display: table; 
    table-layout: fixed; 
    border-spacing: 0; 
    height: 100%;
    width: 100%;
}
.vertical-box-column {
    display: table-cell;
    vertical-align: top;
    height: 100%;
}
.vertical-box-row {
    display: table-row;
    height: 100%;
}
.vertical-box-row > .vertical-box-cell {
    position: relative;
    height: 100%;
    width: 100%;
    float: none;
}
.vertical-box-row > .vertical-box-cell > .vertical-box-inner-cell {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.panel-expand .vertical-box .vertical-box-column {
    display: table-cell;
}
.page-content-full-height .content {
    position: absolute;
    left: 0;
    top: 60px;
    right: 0;
    bottom: 0;
    -webkit-transform: translateZ(0);
}
.panel-expand .page-content-full-height .content {
    -webkit-transform: none;
}
.no-rounded-corner { 
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}
.rounded-corner {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}
.no-border { border: 0 !important; }
.border-top-1 { border-top: 1px solid #eee !important; }
.border-right-1 { border-right: 1px solid #eee !important; }
.border-bottom-1 { border-bottom: 1px solid #eee !important; }
.border-left-1 { border-left: 1px solid #eee !important; }
.no-box-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/* Red */

.bg-red,
.bg-red-500,
.bg-danger,
.panel-red .panel-heading,
.panel-danger .panel-heading { 
    background: #F44336 !important; 
}
.bg-red-50 { background: #FFEBEE !important; }
.bg-red-100 { background: #FFCDD2 !important; }
.bg-red-200 { background: #EF9A9A !important; }
.bg-red-300 { background: #E57373 !important; }
.bg-red-400 { background: #EF5350 !important; }
.bg-red-600 { background: #E53935 !important; }
.bg-red-700 { background: #D32F2F !important; }
.bg-red-800 { background: #C62828 !important; }
.bg-red-900 { background: #B71C1C !important; }
.text-red,
.text-danger,
.text-red-500 { 
    color: #F44336 !important; 
}
a.text-red:hover,
a.text-red:focus,
a.text-danger:hover,
a.text-danger:focus { 
    color: #D32F2F !important; 
}
.text-red-50 { color: #FFEBEE !important; }
.text-red-100 { color: #FFCDD2 !important; }
.text-red-200 { color: #EF9A9A !important; }
.text-red-300 { color: #E57373 !important; }
.text-red-400 { color: #EF5350 !important; }
.text-red-600 { color: #E53935 !important; }
.text-red-700 { color: #D32F2F !important; }
.text-red-800 { color: #C62828 !important; }
.text-red-900 { color: #B71C1C !important; }


/* Pink */

.bg-pink,
.bg-pink-500,
.panel-pink .panel-heading { 
    background: #E91E63 !important; 
}
.bg-pink-50 { background: #FCE4EC !important; }
.bg-pink-100 { background: #F8BBD0 !important; }
.bg-pink-200 { background: #F48FB1 !important; }
.bg-pink-300 { background: #F06292 !important; }
.bg-pink-400 { background: #EC407A !important; }
.bg-pink-600 { background: #D81B60 !important; }
.bg-pink-700 { background: #C2185B !important; }
.bg-pink-800 { background: #AD1457 !important; }
.bg-pink-900 { background: #880E4F !important; }
.text-pink,
.text-pink-500 { 
    color: #E91E63 !important; 
}
a.text-pink:hover,
a.text-pink:focus { 
    color: #C2185B !important; 
}
.text-pink-50 { color: #FCE4EC !important; }
.text-pink-100 { color: #F8BBD0 !important; }
.text-pink-200 { color: #F48FB1 !important; }
.text-pink-300 { color: #F06292 !important; }
.text-pink-400 { color: #EC407A !important; }
.text-pink-600 { color: #D81B60 !important; }
.text-pink-700 { color: #C2185B !important; }
.text-pink-800 { color: #AD1457 !important; }
.text-pink-900 { color: #880E4F !important; }


/* Purple */

.bg-purple,
.bg-purple-500,
.panel-purple .panel-heading { 
    background: #9C27B0 !important; 
}
.bg-purple-50 { background: #F3E5F5 !important; }
.bg-purple-100 { background: #E1BEE7 !important; }
.bg-purple-200 { background: #CE93D8 !important; }
.bg-purple-300 { background: #BA68C8 !important; }
.bg-purple-400 { background: #AB47BC !important; }
.bg-purple-600 { background: #8E24AA !important; }
.bg-purple-700 { background: #7B1FA2 !important; }
.bg-purple-800 { background: #6A1B9A !important; }
.bg-purple-900 { background: #4A148C !important; }
.text-purple,
.text-purple-500 { 
    color: #9C27B0 !important; 
}
a.text-purple:hover,
a.text-purple:focus { 
    color: #7B1FA2 !important; 
}
.text-purple-50 { color: #F3E5F5 !important; }
.text-purple-100 { color: #E1BEE7 !important; }
.text-purple-200 { color: #CE93D8 !important; }
.text-purple-300 { color: #BA68C8 !important; }
.text-purple-400 { color: #AB47BC !important; }
.text-purple-600 { color: #8E24AA !important; }
.text-purple-700 { color: #7B1FA2 !important; }
.text-purple-800 { color: #6A1B9A !important; }
.text-purple-900 { color: #4A148C !important; }


/* Deep Purple */

.bg-deep-purple,
.bg-deep-purple-500,
.panel-deep-purple .panel-heading { 
    background: #673AB7 !important; 
}
.bg-deep-purple-50 { background: #EDE7F6 !important; }
.bg-deep-purple-100 { background: #D1C4E9 !important; }
.bg-deep-purple-200 { background: #B39DDB !important; }
.bg-deep-purple-300 { background: #9575CD !important; }
.bg-deep-purple-400 { background: #7E57C2 !important; }
.bg-deep-purple-600 { background: #5E35B1 !important; }
.bg-deep-purple-700 { background: #512DA8 !important; }
.bg-deep-purple-800 { background: #4527A0 !important; }
.bg-deep-purple-900 { background: #311B92 !important; }
.text-deep-purple,
.text-deep-purple-500 { 
    color: #673AB7 !important; 
}
a.text-deep-purple:hover,
a.text-deep-purple:focus { 
    color: #512DA8 !important; 
}
.text-deep-purple-50 { color: #EDE7F6 !important; }
.text-deep-purple-100 { color: #D1C4E9 !important; }
.text-deep-purple-200 { color: #B39DDB !important; }
.text-deep-purple-300 { color: #9575CD !important; }
.text-deep-purple-400 { color: #7E57C2 !important; }
.text-deep-purple-600 { color: #5E35B1 !important; }
.text-deep-purple-700 { color: #512DA8 !important; }
.text-deep-purple-800 { color: #4527A0 !important; }
.text-deep-purple-900 { color: #311B92 !important; }


/* Indigo */

.bg-indigo,
.bg-indigo-500,
.panel-indigo .panel-heading { 
    background: #3F51B5 !important; 
}
.bg-indigo-50 { background: #E8EAF6 !important; }
.bg-indigo-100 { background: #C5CAE9 !important; }
.bg-indigo-200 { background: #9FA8DA !important; }
.bg-indigo-300 { background: #7986CB !important; }
.bg-indigo-400 { background: #5C6BC0 !important; }
.bg-indigo-600 { background: #3949AB !important; }
.bg-indigo-700 { background: #303F9F !important; }
.bg-indigo-800 { background: #283593 !important; }
.bg-indigo-900 { background: #1A237E !important; }
.text-indigo,
.text-indigo-500 { 
    color: #3F51B5 !important; 
}
a.text-indigo:hover,
a.text-indigo:focus { 
    color: #303F9F !important; 
}
.text-indigo-50 { color: #E8EAF6 !important; }
.text-indigo-100 { color: #C5CAE9 !important; }
.text-indigo-200 { color: #9FA8DA !important; }
.text-indigo-300 { color: #7986CB !important; }
.text-indigo-400 { color: #5C6BC0 !important; }
.text-indigo-600 { color: #3949AB !important; }
.text-indigo-700 { color: #303F9F !important; }
.text-indigo-800 { color: #283593 !important; }
.text-indigo-900 { color: #1A237E !important; }


/* Blue */

.bg-blue,
.bg-primary,
.bg-blue-500,
.panel-blue .panel-heading,
.panel-primary .panel-heading { 
    background: #2196F3 !important; 
}
.bg-blue-50 { background: #E3F2FD !important; }
.bg-blue-100 { background: #BBDEFB !important; }
.bg-blue-200 { background: #90CAF9 !important; }
.bg-blue-300 { background: #64B5F6 !important; }
.bg-blue-400 { background: #42A5F5 !important; }
.bg-blue-600 { background: #1E88E5 !important; }
.bg-blue-700 { background: #1976D2 !important; }
.bg-blue-800 { background: #1565C0 !important; }
.bg-blue-900 { background: #0D47A1 !important; }
.text-blue,
.text-primary,
.text-blue-500 { 
    color: #2196F3 !important; 
}
a.text-blue:hover,
a.text-blue:focus,
a.text-primary:hover,
a.text-primary:focus { 
    color: #1976D2 !important; 
}
.text-blue-50 { color: #E3F2FD !important; }
.text-blue-100 { color: #BBDEFB !important; }
.text-blue-200 { color: #90CAF9 !important; }
.text-blue-300 { color: #64B5F6 !important; }
.text-blue-400 { color: #42A5F5 !important; }
.text-blue-600 { color: #1E88E5 !important; }
.text-blue-700 { color: #1976D2 !important; }
.text-blue-800 { color: #1565C0 !important; }
.text-blue-900 { color: #0D47A1 !important; }


/* Light Blue */

.bg-light-blue,
.bg-light-blue-500,
.panel-light-blue .panel-heading,
.panel-info .panel-heading { 
    background: #03A9F4 !important; 
}
.bg-light-blue-50 { background: #E1F5FE !important; }
.bg-light-blue-100 { background: #B3E5FC !important; }
.bg-light-blue-200 { background: #81D4FA !important; }
.bg-light-blue-300 { background: #4FC3F7 !important; }
.bg-light-blue-400 { background: #29B6F6 !important; }
.bg-light-blue-600 { background: #039BE5 !important; }
.bg-light-blue-700 { background: #0288D1 !important; }
.bg-light-blue-800 { background: #0277BD !important; }
.bg-light-blue-900 { background: #01579B !important; }
.text-light-blue,
.text-light-blue-500 { 
    color: #03A9F4 !important; 
}
a.text-light-blue:hover,
a.text-light-blue:focus { 
    color: #0288D1 !important; 
}
.text-light-blue-50 { color: #E1F5FE !important; }
.text-light-blue-100 { color: #B3E5FC !important; }
.text-light-blue-200 { color: #81D4FA !important; }
.text-light-blue-300 { color: #4FC3F7 !important; }
.text-light-blue-400 { color: #29B6F6 !important; }
.text-light-blue-600 { color: #039BE5 !important; }
.text-light-blue-700 { color: #0288D1 !important; }
.text-light-blue-800 { color: #0277BD !important; }
.text-light-blue-900 { color: #01579B !important; }


/* Cyan */

.bg-cyan,
.bg-cyan-500,
.panel-cyan .panel-heading { 
    background: #00BCD4 !important; 
}
.bg-cyan-50 { background: #E0F7FA !important; }
.bg-cyan-100 { background: #B2EBF2 !important; }
.bg-cyan-200 { background: #80DEEA !important; }
.bg-cyan-300 { background: #4DD0E1 !important; }
.bg-cyan-400 { background: #26C6DA !important; }
.bg-cyan-600 { background: #00ACC1 !important; }
.bg-cyan-700 { background: #0097A7 !important; }
.bg-cyan-800 { background: #00838F !important; }
.bg-cyan-900 { background: #006064 !important; }
.text-cyan,
.text-cyan-500 { 
    color: #00BCD4 !important; 
}
a.text-cyan:hover,
a.text-cyan:focus { 
    color: #0097A7 !important; 
}
.text-cyan-50 { color: #E0F7FA !important; }
.text-cyan-100 { color: #B2EBF2 !important; }
.text-cyan-200 { color: #80DEEA !important; }
.text-cyan-300 { color: #4DD0E1 !important; }
.text-cyan-400 { color: #26C6DA !important; }
.text-cyan-600 { color: #00ACC1 !important; }
.text-cyan-700 { color: #0097A7 !important; }
.text-cyan-800 { color: #00838F !important; }
.text-cyan-900 { color: #006064 !important; }


/* Teal */

.bg-teal,
.bg-success,
.bg-teal-500,
.panel-teal .panel-heading,
.panel-success .panel-heading { 
    background: #009688 !important; 
}
.bg-teal-50 { background: #E0F2F1 !important; }
.bg-teal-100 { background: #B2DFDB !important; }
.bg-teal-200 { background: #80CBC4 !important; }
.bg-teal-300 { background: #4DB6AC !important; }
.bg-teal-400 { background: #26A69A !important; }
.bg-teal-600 { background: #00897B !important; }
.bg-teal-700 { background: #00796B !important; }
.bg-teal-800 { background: #00695C !important; }
.bg-teal-900 { background: #004D40 !important; }
.text-teal,
.text-success,
.text-teal-500 { 
    color: #009688 !important; 
}
a.text-teal:hover,
a.text-teal:focus,
a.text-success:hover,
a.text-success:focus { 
    color: #00796B !important; 
}
.text-teal-50 { color: #E0F2F1 !important; }
.text-teal-100 { color: #B2DFDB !important; }
.text-teal-200 { color: #80CBC4 !important; }
.text-teal-300 { color: #4DB6AC !important; }
.text-teal-400 { color: #26A69A !important; }
.text-teal-600 { color: #00897B !important; }
.text-teal-700 { color: #00796B !important; }
.text-teal-800 { color: #00695C !important; }
.text-teal-900 { color: #004D40 !important; }


/* Green */

.bg-green,
.bg-green-500,
.panel-green .panel-heading { 
    background: #4CAF50 !important; 
}
.bg-green-50 { background: #E8F5E9 !important; }
.bg-green-100 { background: #C8E6C9 !important; }
.bg-green-200 { background: #A5D6A7 !important; }
.bg-green-300 { background: #81C784 !important; }
.bg-green-400 { background: #66BB6A !important; }
.bg-green-600 { background: #43A047 !important; }
.bg-green-700 { background: #388E3C !important; }
.bg-green-800 { background: #2E7D32 !important; }
.bg-green-900 { background: #1B5E20 !important; }
.text-green,
.text-green-500 { 
    color: #4CAF50 !important; 
}
a.text-green:hover,
a.text-green:focus { 
    color: #388E3C !important; 
}
.text-green-50 { color: #E8F5E9 !important; }
.text-green-100 { color: #C8E6C9 !important; }
.text-green-200 { color: #A5D6A7 !important; }
.text-green-300 { color: #81C784 !important; }
.text-green-400 { color: #66BB6A !important; }
.text-green-600 { color: #43A047 !important; }
.text-green-700 { color: #388E3C !important; }
.text-green-800 { color: #2E7D32 !important; }
.text-green-900 { color: #1B5E20 !important; }


/* Light Green */

.bg-light-green,
.bg-light-green-500,
.panel-light-green .panel-heading { 
    background: #8BC34A !important; 
}
.bg-light-green-50 { background: #F1F8E9 !important; }
.bg-light-green-100 { background: #DCEDC8 !important; }
.bg-light-green-200 { background: #C5E1A5 !important; }
.bg-light-green-300 { background: #AED581 !important; }
.bg-light-green-400 { background: #9CCC65 !important; }
.bg-light-green-600 { background: #7CB342 !important; }
.bg-light-green-700 { background: #689F38 !important; }
.bg-light-green-800 { background: #558B2F !important; }
.bg-light-green-900 { background: #FF6F00 !important; }
.text-light-green,
.text-light-green-500 { 
    color: #8BC34A !important; 
}
a.text-light-green:hover,
a.text-light-green:focus { 
    color: #689F38 !important; 
}
.text-light-green-50 { color: #F1F8E9 !important; }
.text-light-green-100 { color: #DCEDC8 !important; }
.text-light-green-200 { color: #C5E1A5 !important; }
.text-light-green-300 { color: #AED581 !important; }
.text-light-green-400 { color: #9CCC65 !important; }
.text-light-green-600 { color: #7CB342 !important; }
.text-light-green-700 { color: #689F38 !important; }
.text-light-green-800 { color: #558B2F !important; }
.text-light-green-900 { color: #33691E !important; }


/* Lime */

.bg-lime,
.bg-lime-500,
.panel-lime .panel-heading { 
    background: #CDDC39 !important; 
}
.bg-lime-50 { background: #F9FBE7 !important; }
.bg-lime-100 { background: #F0F4C3 !important; }
.bg-lime-200 { background: #E6EE9C !important; }
.bg-lime-300 { background: #DCE775 !important; }
.bg-lime-400 { background: #D4E157 !important; }
.bg-lime-600 { background: #C0CA33 !important; }
.bg-lime-700 { background: #AFB42B !important; }
.bg-lime-800 { background: #9E9D24 !important; }
.bg-lime-900 { background: #827717 !important; }
.text-lime,
.text-lime-500 { 
    color: #CDDC39 !important; 
}
a.text-light-lime:hover,
a.text-light-lime:focus { 
    color: #AFB42B !important; 
}
.text-lime-50 { color: #F9FBE7 !important; }
.text-lime-100 { color: #F0F4C3 !important; }
.text-lime-200 { color: #E6EE9C !important; }
.text-lime-300 { color: #DCE775 !important; }
.text-lime-400 { color: #D4E157 !important; }
.text-lime-600 { color: #C0CA33 !important; }
.text-lime-700 { color: #AFB42B !important; }
.text-lime-800 { color: #9E9D24 !important; }
.text-lime-900 { color: #827717 !important; }


/* Yellow */

.bg-yellow,
.bg-yellow-500,
.panel-yellow .panel-heading { 
    background: #FFEB3B !important; 
}
.bg-yellow-50 { background: #FFFDE7 !important; }
.bg-yellow-100 { background: #FFF9C4 !important; }
.bg-yellow-200 { background: #FFF59D !important; }
.bg-yellow-300 { background: #FFF176 !important; }
.bg-yellow-400 { background: #FFEE58 !important; }
.bg-yellow-600 { background: #FDD835 !important; }
.bg-yellow-700 { background: #FBC02D !important; }
.bg-yellow-800 { background: #F9A825 !important; }
.bg-yellow-900 { background: #F57F17 !important; }
.text-yellow,
.text-yellow-500 { 
    color: #FFEB3B !important; 
}
a.text-yellow:hover,
a.text-yellow:focus { 
    color: #FBC02D !important; 
}
.text-yellow-50 { color: #FFFDE7 !important; }
.text-yellow-100 { color: #FFF9C4 !important; }
.text-yellow-200 { color: #FFF59D !important; }
.text-yellow-300 { color: #FFF176 !important; }
.text-yellow-400 { color: #FFEE58 !important; }
.text-yellow-600 { color: #FDD835 !important; }
.text-yellow-700 { color: #FBC02D !important; }
.text-yellow-800 { color: #F9A825 !important; }
.text-yellow-900 { color: #F57F17 !important; }


/* Ember */

.bg-amber,
.bg-amber-500,
.panel-amber .panel-heading { 
    background: #FFC107 !important; 
}
.bg-amber-50 { background: #FFF8E1 !important; }
.bg-amber-100 { background: #FFECB3 !important; }
.bg-amber-200 { background: #FFE082 !important; }
.bg-amber-300 { background: #FFD54F !important; }
.bg-amber-400 { background: #FFCA28 !important; }
.bg-amber-600 { background: #FFB300 !important; }
.bg-amber-700 { background: #FFA000 !important; }
.bg-amber-800 { background: #FF8F00 !important; }
.bg-amber-900 { background: #FF6F00 !important; }
.text-amber,
.text-amber-500 { 
    color: #FFC107 !important; 
}
a.text-amber:hover,
a.text-amber:focus { 
    color: #FFA000 !important; 
}
.text-amber-50 { color: #FFF8E1 !important; }
.text-amber-100 { color: #FFECB3 !important; }
.text-amber-200 { color: #FFE082 !important; }
.text-amber-300 { color: #FFD54F !important; }
.text-amber-400 { color: #FFCA28 !important; }
.text-amber-600 { color: #FFB300 !important; }
.text-amber-700 { color: #FFA000 !important; }
.text-amber-800 { color: #FF8F00 !important; }
.text-amber-900 { color: #FF6F00 !important; }


/* Orange */

.bg-orange,
.bg-warning,
.bg-orange-500,
.panel-orange .panel-heading,
.panel-warning .panel-heading { 
    background: #FF9800 !important; 
}
.bg-orange-50 { background: #FFF3E0 !important; }
.bg-orange-100 { background: #FFE0B2 !important; }
.bg-orange-200 { background: #FFCC80 !important; }
.bg-orange-300 { background: #FFB74D !important; }
.bg-orange-400 { background: #FFA726 !important; }
.bg-orange-600 { background: #FB8C00 !important; }
.bg-orange-700 { background: #F57C00 !important; }
.bg-orange-800 { background: #EF6C00 !important; }
.bg-orange-900 { background: #E65100 !important; }
.text-orange,
.text-warning,
.text-orange-500 { 
    color: #FF9800 !important; 
}
a.text-orange:hover,
a.text-orange:focus,
a.text-warning:hover,
a.text-warning:focus { 
    color: #F57C00 !important; 
}
.text-orange-50 { color: #FFF3E0 !important; }
.text-orange-100 { color: #FFE0B2 !important; }
.text-orange-200 { color: #FFCC80 !important; }
.text-orange-300 { color: #FFB74D !important; }
.text-orange-400 { color: #FFA726 !important; }
.text-orange-600 { color: #FB8C00 !important; }
.text-orange-700 { color: #F57C00 !important; }
.text-orange-800 { color: #EF6C00 !important; }
.text-orange-900 { color: #E65100 !important; }


/* Deep Orange */

.bg-deep-orange,
.bg-deep-orange-500,
.panel-deep-orange .panel-heading { 
    background: #FF5722 !important; 
}
.bg-deep-orange-50 { background: #FBE9E7 !important; }
.bg-deep-orange-100 { background: #FFCCBC !important; }
.bg-deep-orange-200 { background: #FFAB91 !important; }
.bg-deep-orange-300 { background: #FF8A65 !important; }
.bg-deep-orange-400 { background: #FF7043 !important; }
.bg-deep-orange-600 { background: #F4511E !important; }
.bg-deep-orange-700 { background: #E64A19 !important; }
.bg-deep-orange-800 { background: #D84315 !important; }
.bg-deep-orange-900 { background: #BF360C !important; }
.text-deep-orange,
.text-deep-orange-500 { 
    color: #FF5722 !important; 
}
a.text-deep-orange:hover,
a.text-deep-orange:focus { 
    color: #E64A19 !important; 
}
.text-deep-orange-50 { color: #FBE9E7 !important; }
.text-deep-orange-100 { color: #FFCCBC !important; }
.text-deep-orange-200 { color: #FFAB91 !important; }
.text-deep-orange-300 { color: #FF8A65 !important; }
.text-deep-orange-400 { color: #FF7043 !important; }
.text-deep-orange-600 { color: #F4511E !important; }
.text-deep-orange-700 { color: #E64A19 !important; }
.text-deep-orange-800 { color: #D84315 !important; }
.text-deep-orange-900 { color: #BF360C !important; }


/* Brown */

.bg-brown,
.bg-brown-500,
.panel-brown .panel-heading { 
    background: #795548 !important; 
}
.bg-brown-50 { background: #EFEBE9 !important; }
.bg-brown-100 { background: #D7CCC8 !important; }
.bg-brown-200 { background: #BCAAA4 !important; }
.bg-brown-300 { background: #A1887F !important; }
.bg-brown-400 { background: #8D6E63 !important; }
.bg-brown-600 { background: #6D4C41 !important; }
.bg-brown-700 { background: #5D4037 !important; }
.bg-brown-800 { background: #4E342E !important; }
.bg-brown-900 { background: #3E2723 !important; }
.text-brown,
.text-brown-500 { 
    color: #795548 !important; 
}
a.text-brown:hover,
a.text-brown:focus { 
    color: #5D4037 !important; 
}
.text-brown-50 { color: #EFEBE9 !important; }
.text-brown-100 { color: #D7CCC8 !important; }
.text-brown-200 { color: #BCAAA4 !important; }
.text-brown-300 { color: #A1887F !important; }
.text-brown-400 { color: #8D6E63 !important; }
.text-brown-600 { color: #6D4C41 !important; }
.text-brown-700 { color: #5D4037 !important; }
.text-brown-800 { color: #4E342E !important; }
.text-brown-900 { color: #3E2723 !important; }


/* Grey */

.bg-grey,
.bg-grey-500 { 
    background: #9E9E9E !important; 
}
.bg-grey-50 { background: #FAFAFA !important; }
.bg-grey-100 { background: #F5F5F5 !important; }
.bg-grey-200 { background: #EEEEEE !important; }
.bg-grey-300,
.panel-default .panel-heading { 
    background: #E0E0E0 !important; 
}
.bg-grey-400 { background: #BDBDBD !important; }
.bg-grey-600 { background: #757575 !important; }
.bg-grey-700 { background: #616161 !important; }
.bg-grey-800 { background: #424242 !important; }
.bg-grey-900,
.panel-grey .panel-heading { 
    background: #212121 !important; 
}
.text-grey,
.text-muted,
.text-grey-500 { 
    color: #9E9E9E !important; 
}
a.text-grey:hover,
a.text-grey:focus { 
    color: #616161 !important; 
}
.text-grey-50 { color: #FAFAFA !important; }
.text-grey-100 { color: #F5F5F5 !important; }
.text-grey-200 { color: #EEEEEE !important; }
.text-grey-300 { color: #E0E0E0 !important; }
.text-grey-400 { color: #BDBDBD !important; }
.text-grey-600 { color: #757575 !important; }
.text-grey-700 { color: #616161 !important; }
.text-grey-800 { color: #424242 !important; }
.text-grey-900 { color: #212121 !important; }


/* Blue Grey */

.bg-blue-grey, 
.bg-blue-grey-500 { 
    background: #607D8B !important; 
}
.bg-blue-grey-50 { background: #ECEFF1 !important; }
.bg-blue-grey-100 { background: #CFD8DC !important; }
.bg-blue-grey-200 { background: #B0BEC5 !important; }
.bg-blue-grey-300 { background: #90A4AE !important; }
.bg-blue-grey-400 { background: #78909C !important; }
.bg-blue-grey-600 { background: #546E7A !important; }
.bg-blue-grey-700 { background: #455A64 !important; }
.bg-blue-grey-800 { background: #37474F !important; }
.bg-blue-grey-900,
.panel-blue-grey .panel-heading { 
    background: #263238 !important; 
}
.text-blue-grey,
.text-blue-grey-500 { 
    color: #607D8B !important;
}
a.text-blue-grey:hover,
a.text-blue-grey:focus { 
    color: #455A64 !important; 
}
.text-blue-grey-50 { color: #ECEFF1 !important; }
.text-blue-grey-100 { color: #CFD8DC !important; }
.text-blue-grey-200 { color: #B0BEC5 !important; }
.text-blue-grey-300 { color: #90A4AE !important; }
.text-blue-grey-400 { color: #78909C !important; }
.text-blue-grey-600 { color: #546E7A !important; }
.text-blue-grey-700 { color: #455A64 !important; }
.text-blue-grey-800 { color: #37474F !important; }
.text-blue-grey-900 { color: #263238 !important; }

.text-black,
.text-inverse {
    color: #000 !important;
}
.bg-black,
.bg-inverse,
.panel-black .panel-heading,
.panel-inverse .panel-heading { 
    background: #000 !important;
}
.text-white {
    color: #fff !important;
}
.bg-white,
.panel-white .panel-heading { 
    background: #fff !important;
}



.no-bg { background: none !important; }

.height-xs { height: 150px !important; }
.height-sm { height: 300px !important; }
.height-md { height: 450px !important; }
.height-lg { height: 600px !important; }
.height-full { height: 100% !important; }
.height-50 { height: 50px !important; }
.height-100 { height: 100px !important; }
.height-150 { height: 150px !important; }
.height-200 { height: 200px !important; }
.height-250 { height: 250px !important; }
.height-300 { height: 300px !important; }
.height-350 { height: 350px !important; }
.height-400 { height: 400px !important; }
.height-450 { height: 450px !important; }
.height-500 { height: 500px !important; }
.height-550 { height: 550px !important; }
.height-600 { height: 600px !important; }

.width-xs { width: 150px !important; }
.width-sm { width: 300px !important; }
.width-md { width: 450px !important; }
.width-lg { width: 600px !important; }
.width-full { width: 100% !important; }
.width-50 { width: 50px !important; }
.width-100 { width: 100px !important; }
.width-150 { width: 150px !important; }
.width-200 { width: 200px !important; }
.width-250 { width: 250px !important; }
.width-300 { width: 300px !important; }
.width-350 { width: 350px !important; }
.width-400 { width: 400px !important; }
.width-450 { width: 450px !important; }
.width-500 { width: 500px !important; }
.width-550 { width: 550px !important; }
.width-600 { width: 600px !important; }

.animated {
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.fade {
    opacity: 0;
    -webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;
}
.text-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.text-nowrap {
    white-space: nowrap !important;
}
.underline {
    border-bottom: 1px solid #E0E0E0 !important;
}


/* -------------------------------
   5.0 Plugins Setting
------------------------------- */

/*  5.1 Plugins - jQuery Draggable */

.row > [class*="col-"].ui-sortable {
    min-height: 50px;
}
.theme-panel [class*="col-"].ui-sortable,
.panel [class*="col-"].ui-sortable {
    min-height: 0px;
}
.ui-sortable .panel-heading {
    cursor: move;
}
.ui-sortable.ui-sortable-disabled .panel-heading {
    cursor: default;
}
.ui-sortable-placeholder { 
    border: 1px dashed #82868b !important;
    visibility: visible !important;
    background: none;
    margin: 0 !important;
}
.panel.ui-sortable-placeholder {
    margin: -1px -1px 19px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}


/* 5.2 Plugins - Slimscrollbar */

.slimScrollBar {
    width: 5px !important;
    right: 0 !important;
}
.slimScrollRail {
    width: 5px !important;
    right: 0 !important;
}


/* 5.3 Plugins - Gritter Notification */

.gritter-item-wrapper {
    background: none !important;
    overflow: hidden !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px;
}
.gritter-top,
.gritter-bottom,
.gritter-item {
    background: url(../../img/transparent/black-0.8.png) !important;
    background: rgba(0,0,0,0.8) !important;
}
.gritter-close,
.gritter-light .gritter-close {
    left: auto !important;
    right: 15px !important;
    top: 0 !important;
    background: #E91E63 !important;
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    display: block !important;
}
.gritter-close:before,
.gritter-light .gritter-close:before {
    content: '\f00d' !important;
    font-family: FontAwesome !important;
    font-size: 12px !important;
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    color: #fff !important;
    text-indent: 0 !important;
    position: absolute !important;
    text-align: center !important;
    right: 0 !important;
    top: 0 !important;
}
.gritter-with-image {
    width: 210px !important;
}
.gritter-image {
    margin: 2px 10px 0 0 !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}
.gritter-title {
    font-size: 14px !important;
    line-height: 16px !important;
    padding-bottom: 2px !important;
    font-weight: 500 !important;
    color: #fff !important;
    text-shadow: none !important;
}
.gritter-light .gritter-title {
    color: #333 !important;
    font-weight: 600 !important;
}
.gritter-item {
    font-family: inherit !important;
    color: #BDBDBD !important;
    font-size: 11px !important;
    padding: 2px 15px 5px !important;
}
.gritter-light .gritter-item, 
.gritter-light .gritter-bottom, 
.gritter-light .gritter-top {
    background: url(../../img/transparent/white-0.98.png) !important;
    background: rgba(255,255,255,0.98) !important;
    color: #999 !important;
}


/* 5.4 Plugins - Data Table */

.dataTables_wrapper table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before, 
.dataTables_wrapper table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    background: #212121;
    border-radius: 2px;
    border: none;
    top: 10px;
    left: 8px;
    line-height: 16px;
    box-shadow: none;
    text-align: center;
    text-indent: initial;
    font-family: 'Roboto';
}
.dataTables_wrapper table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, 
.dataTables_wrapper table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
    background: #424242;
    font-size: 18px;
}
body div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 5px 0;
}
.dataTables_wrapper table.dataTable {
    margin: 10px 0 !important;
}
.dt-buttons.btn-group {
    float: left;
}
.dataTables_length,
div.dataTables_info {
    float: left;
    margin-right: 15px;
}
.dataTables_empty {
    text-align: center;
}
table.DTFC_Cloned tr.even {
    background: none !important;
}
div.dataTables_scrollHead .table,
.DTFC_LeftWrapper div.DTFC_LeftHeadWrapper table,
.DTFC_RightWrapper div.DTFC_RightHeadWrapper table {
    margin-bottom: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}
.dataTables_scrollBody .table thead > tr > th,
.DTFC_LeftBodyLiner .table thead > tr > th {
    border-bottom: none !important;
}
.dataTables_scrollBody,
.DTFC_LeftBodyLiner {
    border-bottom: 2px solid #ebeced;
}
.dataTables_wrapper .dataTables_scrollBody table.dataTable,
.dataTables_wrapper .DTFC_LeftBodyLiner table.dataTable,
.dataTables_scroll + .dataTables_info {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}
.dataTables_wrapper .dataTables_scrollBody table.dataTable > tbody > tr > td,
.dataTables_wrapper .DTFC_LeftWrapper table.dataTable > tbody > tr > td  {
    border-bottom: none !important;
}
.table > tbody + thead > tr > th,
.table.fixedHeader-floating thead > tr > th {
    border-top: none;
    border-bottom-width: 1px !important;
}
table.dataTable tbody > tr.selected, 
table.dataTable tbody > tr.selected td,
table.dataTable tbody > tr > .selected {
    background: #FFFDE7 !important;
    color: #212121;
}
table.dataTable th.focus, 
table.dataTable td.focus {
    position: relative;
    z-index: 1000;
    outline: 2px solid #212121 !important;
}
div.dt-autofill-select,
div.DTCR_pointer {
    background-color: #2196F3 !important;
}
div.dt-autofill-handle {
    border-color: #2196F3 !important;
    background-color: #2196F3 !important;
}
table.dt-rowReorder-float {
    outline: 2px solid #F44336 !important;
}
tr.dt-rowReorder-moving {
    outline: 2px solid #212121 !important;
}


/* 5.5 Plugins - Datepicker */

.datepicker table tr td.today, 
.datepicker table tr td.today:hover, 
.datepicker table tr td.today.disabled, 
.datepicker table tr td.today.disabled:hover {
    background: #eee !important;
}
.datepicker table tr td.active:hover, 
.datepicker table tr td.active:hover:hover, 
.datepicker table tr td.active.disabled:hover, 
.datepicker table tr td.active.disabled:hover:hover, 
.datepicker table tr td.active:focus, 
.datepicker table tr td.active:hover:focus, 
.datepicker table tr td.active.disabled:focus, 
.datepicker table tr td.active.disabled:hover:focus, 
.datepicker table tr td.active:active, 
.datepicker table tr td.active:hover:active, 
.datepicker table tr td.active.disabled:active, 
.datepicker table tr td.active.disabled:hover:active, 
.datepicker table tr td.active.active,
.datepicker table tr td span.active.active,
.datepicker table tr td.active:hover.active, 
.datepicker table tr td.active.disabled.active, 
.datepicker table tr td.active.disabled:hover.active, 
.open .dropdown-toggle.datepicker table tr td.active, 
.open .dropdown-toggle.datepicker table tr td.active:hover, 
.open .dropdown-toggle.datepicker table tr td.active.disabled, 
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
    background: #2196F3 !important;
    color: #fff !important;
}
.datepicker {
    min-width: 250px !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}
.datepicker.dropdown-menu {
    min-width: 250px;
    border: 1px solid #ddd !important;
    -webkit-box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2) !important;
}
.datepicker-dropdown:before {
    border-bottom-color: #ddd !important;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
    border-top-color: #ddd !important;
}
.datepicker table {
    width: 100%;
}	        
.input-daterange .input-group-addon {
    background: #e0e0e0 !important;
    border: none !important;
    text-shadow: none !important;
}
.irs-from, .irs-to, .irs-single {
    background: #212121 !important;
}
.datepicker-full-width .datepicker-inline {
    width: 100%;
}


/* 5.6 Plugins - Colorpicker */

.colorpicker {
    border: 1px solid #ddd !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}
.colorpicker-hue, .colorpicker-alpha {
    margin-bottom: 0;
}


/* 5.7 Plugins - Bootstrap Timepicker */

.bootstrap-timepicker .bootstrap-timepicker-widget.dropdown-menu + .form-control {
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}
.bootstrap-timepicker-widget table td input {
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
    border: 1px solid #ddd !important;
    max-width: 200px;
}
.bootstrap-timepicker-widget table td a:hover,
.bootstrap-timepicker-widget table td a:focus {
    background: #2196F3 !important;
    border: none !important;
    color: #fff;
}


/* 5.8 Plugins - jQuery Autocomplete */

.ui-widget {
    font-family: 'Roboto', "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px;
}
.ui-autocomplete {
    padding: 5px 0 !important;
    -webkit-box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2) !important;
    border: 1px solid #ccc !important;
}
.ui-stats-hover, 
.ui-widget-content .ui-stats-hover, 
.ui-widget-header .ui-stats-hover, 
.ui-stats-focus, 
.ui-widget-content .ui-stats-focus, 
.ui-widget-header .ui-stats-focus {
    border: none !important;
    background: #2196F3 !important;
    color: #fff !important;
    margin: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    
}
.ui-menu .ui-menu-item a {
    padding: 5px 10px;
}


/* 5.9 Plugins - Combobox */

.combobox-container {
    display: table;
    border-collapse: separate;
    width: 100%;
}
.combobox-container input, .combobox-container .uneditable-input {
    border: 1px solid #ccc;
    background: #fff;
    font-size: 14px;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    border-radius: 4px 0 0 4px !important;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.combobox-container input:focus, .combobox-container .uneditable-input:focus {
    outline: 0;
    border-color: #616161;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.combobox-container .add-on,
.combobox-container input {
    display: table-cell !important;
}
.combobox-container .add-on {
    width: 1% !important;
    padding: 5px 10px !important;
    white-space: nowrap;
    float: none !important;
    background: #e0e0e0 !important;
    color: #555 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.btn .combobox-clear {
    margin: 0 !important;
    width: 8px !important;
    position: relative;
    opacity: 1.0;
    filter: alpha(opacity=100);
}
.combobox-container .icon-remove:before {
    content: '\f00d';
    font-family: FontAwesome;
    font-size: 14px;
    font-style: normal;
    position: absolute;
    right: -2px;
    top: 1px;
    text-shadow: none;
}


/* 5.10 Plugins - Bootstrap Select */

body .bootstrap-select .btn:focus {
    outline: none !important;
}


/* 5.11 Plugins - TagIt */

.tagit.ui-widget {
    border: 1px solid #ccc !important;
    background: #fff !important;
    padding: 3px 12px 2px !important;
    margin-bottom: 0 !important;
}
ul.tagit input[type="text"] {
    background: #fff !important;
    width: auto !important;
}
ul.tagit li.tagit-choice-editable {
    background: #b6c2c9 !important;
    border: none !important;
    color: #fff !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    padding: 2px 24px 3px 7px !important;
}
ul.tagit.inverse li.tagit-choice-editable { background: #212121 !important; }
ul.tagit.white li.tagit-choice-editable { background: #fff !important; border: 1px solid #ddd !important; color: #000 !important; }
ul.tagit.primary li.tagit-choice-editable { background: #2196F3 !important; }
ul.tagit.info li.tagit-choice-editable { background: #03A9F4 !important; }
ul.tagit.success li.tagit-choice-editable { background: #009688 !important; }
ul.tagit.warning li.tagit-choice-editable { background: #FF9800 !important; }
ul.tagit.danger li.tagit-choice-editable { background: #F44336 !important; }
ul.tagit li.tagit-choice .tagit-close {
    right: 2px !important;
    line-height: 22px !important;
    text-align: center !important;
    top: 0 !important;
    margin: 0 !important;
}
ul.tagit li.tagit-choice .tagit-close:hover,
ul.tagit li.tagit-choice .tagit-close:focus {
    text-decoration: none !important;
}
ul.tagit li.tagit-choice .tagit-close:hover .ui-icon-close:before,
ul.tagit li.tagit-choice .tagit-close:focus .ui-icon-close:before {
    color: #666;
    color: rgba(0,0,0,0.4);
}
ul.tagit.inverse li.tagit-choice .tagit-close:hover .ui-icon-close:before,
ul.tagit.inverse li.tagit-choice .tagit-close:focus .ui-icon-close:before {
    color: #666;
    color: rgba(255,255,255,0.4);
}
.tagit .ui-icon-close {
    background: none !important;
    height: 22px !important;
    width: 16px !important;
    text-indent: 0 !important;
}
.tagit.white .ui-icon-close:before {
    color: #000;
}
.tagit .ui-icon-close:before {
    content: '\f00d';
    font-family: FontAwesome;
    font-size: 10px;
    color: #fff;
}


/* 5.12 Plugins - Parsley */

.parsley-errors-list {
    padding: 0 !important;
    list-style-type: none !important;
    margin: 0 !important;
    color: #B71C1C;
    font-size: 13px !important;
    line-height: inherit !important;
}
.parsley-errors-list.filled {
    margin-top: 5px !important;
}
.parsley-error,
.parsley-success {
    color: #212121 !important;
}
.parsley-error {
    background: #FFEBEE !important;
    border-color: #F44336 !important;
}
.parsley-success {
    background: #E0F2F1 !important;
    border-color: #009688 !important;
}
.parsley-error:focus,
.parsley-success:focus {
    color: #212121 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}


/* 5.13 Plugins - CKEditor / wysihtml5 */

.panel-form .cke_chrome {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.panel-form .wysihtml5-toolbar {
    padding: 15px 15px 5px;
}
.panel-form .wysihtml5-sandbox {
    -webkit-border-radius: 0 0 4px 4px !important;
    -moz-border-radius: 0 0 4px 4px !important;
    border-radius: 0 0 4px 4px !important;
}


/* 5.14 Plugins - Bootstrap Wizard */
    
.bwizard-steps {
    font-size: 16px !important;
    border-radius: 2px !important;
    overflow: hidden !important;
    display: table !important;
    width: 100% !important;
}
.bwizard-steps li:before,
.bwizard-steps li:after {
    border: none !important;
}
.bwizard-steps li {
    float: none !important;
    display: table-cell !important;
    padding: 15px !important;
    line-height: 20px !important;
    background: #eee !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}
.bwizard-steps li:hover,
.bwizard-steps li:focus {
    background: #E0E0E0 !important;
}
.bwizard-steps.clickable li:hover:not(.active) a,
.bwizard-steps.clickable li:focus:not(.active) a {
    color: #333 !important;
}
.bwizard-steps li small {
    font-size: 12px !important;
    color: #999 !important;
    display: block !important;
    line-height: 16px !important;
    margin-top: 2px;
}
.bwizard-steps li a {
    margin-left: 30px !important;
    display: block !important;
    text-decoration: none !important;
}
.bwizard-steps li.active small {
    color: #b2e6e6 !important;
}
.bwizard-steps .label {
    float: left !important;
    padding: 0 !important;
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
    text-align: center !important;
    margin-right: 10px !important;
    top: 0 !important;
    background: #6d7479 !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}
.bwizard-steps li.active,
.bwizard-steps li.active:hover,
.bwizard-steps li.active:focus {
    background: #009688 !important;
}
.bwizard-steps li.active .label {
    background: url(../../img/transparent/black-0.2.png) !important;
    background: rgba(0,0,0,0.2) !important;
}


/* 5.15 Plugins - Fullcalendar */

.fc .fc-day-grid-event {
    padding: 1px 2px;
}
.fc-event,
.fc .fc-event {
    border: none;
    background: #424242;
    border-radius: 0;
}
.fc-event-list .fc-event {
    background: none;
    border: none;
    color: #616161;
    padding: 10px 15px;
    font-size: 13px;
    margin-left: -15px;
    margin-right: -15px;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.fc-event-list .fc-event:hover,
.fc-event-list .fc-event:focus {
    color: #212121;
    cursor: pointer;
    background: #F5F5F5;
}
.fc-event-list .fc-event.ui-draggable-dragging {
    border: 1px dashed #E0E0E0;
}
.fc-event-list .fc-event + .fc-event {
    margin-top: -1px;
}
.fc-event-list .fc-event .fa {
    float: left;
    margin-right: 10px;
    font-size: 14px;
}
body .fc button {
    font-weight: 400;
    color: #212121;
    background: #fff;
    border-color: #c8c8c8;
    box-shadow: none;
    padding: 5px 10px;
    height: inherit;
    font-size: 12px;
    line-height: 1.5;
}
body .fc button:active, 
body .fc button:focus,
body .fc button:hover {
    background: #E0E0E0;
    border-color: #c8c8c8;
    outline: none;
}
body .fc button.fc-state-disabled:active, 
body .fc button.fc-state-disabled:focus,
body .fc button.fc-state-disabled:hover {
    background: #fff;
}
body .fc button.fc-state-active, 
body .fc button:active {
    background: #E0E0E0;
    border-color: #c8c8c8;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.1);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.1);
}
.fc .fc-unthemed .fc-divider, 
.fc .fc-unthemed .fc-popover, 
.fc .fc-unthemed .fc-row, 
.fc .fc-unthemed tbody, 
.fc .fc-unthemed td, 
.fc .fc-unthemed th, 
.fc .fc-unthemed thead {
    border-color: #E0E0E0;
}
.fc th {
    padding: 3px 15px;
    color: #212121;
    font-weight: 500;
    font-size: 13px;
    background: #F5F5F5;
}
.fc button .fc-icon {
    font-family: FontAwesome;
    margin: 0;
    top: 0;
    width: auto;
    height: auto;
    font-size: 12px;
}
.fc .fc-icon-left-single-arrow:after,
.fc .fc-icon-right-single-arrow:after {
    content: '\f053';
    font-size: 12px;
    top: 0;
    margin: 0;
}
.fc .fc-icon-right-single-arrow:after {
    content: "\f054";
}
.fc .fc-unthemed .fc-today {
    background: #FFF8F0;
}
.fc .fc-scroller {
    overflow: initial;
    height: auto !important;
}
.fc .fc-toolbar h2 {
    font-size: 24px;
    line-height: 30px;
}


/* 5.16 Plugins - jVectorMap */
			    
.jvectormap-zoomin, .jvectormap-zoomout {
    width: 16px !important;
    height: 16px !important;
    background: #000 !important;
}
.jvectormap-label {
    padding: 3px 6px !important;
    border: none !important;
    background: url(../../img/transparent/black-0.6.png) !important;
    background: rgba(38, 50, 56, 0.6) !important;
}


/* 5.17 Plugins - Flot Chart */

.legendColorBox > div {
    margin-right: 5px !important;
    border-radius: 5px !important;
    margin-top: 1px !important;
    border: none !important;
}
.legend > table tr td.legendColorBox {
    padding-left: 5px !important;
}
.legend > table tr td {
    padding: 5px 0 5px 0 !important;
}
.legend > div {
    border-radius: 2px !important;
    background: #000 !important;
}
.legend > table tr td.legendLabel {
    padding-right: 5px !important;
    color: #fff;
}
.legend > table tr + tr td {
    padding-top: 0px !important;
}
.legendColorBox > div > div {
    -webkit-border-radius: 2px !important;
    -moz-border-radius: 2px !important;
    border-radius: 2px !important;
    border-width: 4px !important;
}
.tickLabel,.legendLabel {
    font-size: 12px !important;
}
.tickLabel {
    color: #212121 !important;
}
.flot-tooltip {
    position: absolute;
    display: none;
    border: 0;
    padding: 6px 10px 7px;
    color: #fff;
    font-size: 12px;
    background: url(../../img/transparent/black-0.8.png);
    background-color: rgba(0,0,0,0.8);
    border-radius: 2px;
    opacity: 1.0;
    filter: alpha(opacity=100);
    z-index: 10000 !important;
}


/* 5.18 Plugins - Sparkline */

.jqstooltip {
    border: none !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: #333 !important;
    width: auto !important;
    height: auto !important;
    background: url(../../img/transparent/black-0.8.png) !important;
    background: rgba(0,0,0,0.8) !important;
}



/* -------------------------------
   6.0 Page Setting
------------------------------- */

/* 6.1 Page - Search Result */
 
.result-list:before,
.result-list:after {
    content: '';
    display: table;
    clear: both;
}
.result-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.result-list > li:before,
.result-list > li:after {
    content: '';
    display: table;
    clear: both;
}
.result-list > li {
    background: #fff;
    box-shadow: 0 1px #ccc;
    border-radius: 3px;
    overflow: hidden;
}
.result-list > li + li {
    margin-top: 10px;
}
.result-list > li > div {
    display: table-cell;
    vertical-align: top;
    padding: 20px;
    position: relative;
}
.result-list .result-image {
    width: 240px;
    padding: 0;
    overflow: hidden;
    background: #212121;
    vertical-align: middle;
}
.result-list .result-image a {
    display: block;
}
.result-list .result-image a:hover,
.result-list .result-image a:focus {
    opacity: 0.8;
    filter: alpha(opacity=80);
}
.result-list .result-image img {
    width: 100%;
}
.result-list .title {
    margin: 0 0 8px;
    font-size: 20px;
    line-height: 24px;
}
.result-list .title a {
    color: #333;
}
.result-list .location {
    color: #999;
}
.result-list .desc {
    margin-bottom: 20px;
    max-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
}
.result-list .btn-row a {
    color: #666;
    font-size: 20px;
    line-height: 14px;
    opacity: 0.2;
}
.result-list .btn-row a:hover,
.result-list .btn-row a:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}
.result-list .btn-row a + a {
    margin-left: 10px;
}
.result-list .result-info {

}
.result-list .result-price {
    width: 240px;
    font-size: 28px;
    text-align: center;
    border-left: 1px dashed #ddd;
    padding-top: 30px;
}
.result-list .result-price small {
    display: block;
    font-size: 12px;
    color: #999;
}
.result-list .result-price .btn {
    margin-top: 30px;
}


/* 6.2 Media Message */

.media-messaging .media:first-child {
    padding-top: 0;
}
.media-messaging .media:last-child {
    padding-bottom: 0;
}
.media-messaging .media {
    padding: 15px;
}
.media-messaging .media p {
    margin: 0;
}
.media-messaging .media + .media {
    margin-top: 0;
    padding-top: 15px;
}


/* 6.3 Widget Stats */

.widget {
    border-radius: 3px;
    margin-bottom: 20px;
    color: #fff;
    padding: 15px;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.15);
    box-shadow: 0 2px 3px rgba(0,0,0,0.15);
}
.widget.widget-stats {
    position: relative;
}
.widget-stats .stats-info h4 {
    font-size: 13px;
    margin: 5px 0;
    color: #fff;
    color: rgba(255,255,255,0.75);
}
.widget-stats .stats-icon {
    font-size: 42px;
    height: 56px;
    width: 56px;
    text-align: center;
    line-height: 56px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 0.2;
    filter: alpha(opacity=20);
}
.widget-stats .stats-icon i.material-icons {
    font-size: 56px;
}
.widget-stats .stats-info p {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 0;
}
.widget-stats .stats-link a {
    display: block;
    margin: 15px -15px -15px;
    padding: 7px 15px;
    background: url(../../img/transparent/black-0.4.png);
    background: rgba(0,0,0,0.4);
    text-align: right;
    color: #ddd;
    font-weight: normal;
    text-decoration: none;
}
.widget-stats .stats-link a:hover, 
.widget-stats .stats-link a:focus {
    background: url(../../img/transparent/black-0.6.png);
    background: rgba(0,0,0,0.6);
    color: #fff;
}
.widget-stats .stats-icon.stats-icon-lg {
    font-size: 60px;
    line-height: 60px;
    top: 15px;
    right: 15px;
    width: 60px;
    height: 60px;
}
.widget-stats .stats-icon.stats-icon-lg i {
    font-size: 60px;
}
.widget-stats .stats-title {
    position: relative;
    line-height: 1.1;
    font-size: 13px;
    margin: 2px 0 7px;
}
.widget-stats .stats-title,
.widget-stats .stats-desc {
    color: #fff;
    color: rgba(255,255,255,0.75);
    font-size: 13px;
}
.widget-stats .stats-desc {
    font-weight: normal;
    margin-bottom: 0;
}
.widget-stats .stats-number {
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 10px;
}
.widget-stats .stats-progress {
    background: url('../../img/transparent/black-0.2.png');
    background: rgba(0,0,0,0.2);
    height: 2px;
    margin: 0 -15px 12px;
}
.widget-stats .stats-progress .progress-bar {
    background: #fff;
}


/* 6.4 Todolist */

.todolist {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.todolist > li > a {
    display: block;
    color: #333;
}
.todolist > li > a:hover,
.todolist > li > a:focus {
    background: #fafafa;
    text-decoration: none;
}
.todolist > li > a:hover .todolist-input i:before,
.todolist > li > a:focus .todolist-input i:before {
    content: '\f14a';
    opacity: 0.4;
    filter: alpha(opacity=40);
    color: #212121;
}
.todolist > li.active > a .todolist-input i:before,
.todolist > li.active > a:hover .todolist-input i:before,
.todolist > li.active > a:focus .todolist-input i:before {
    content: '\f14a';
    color: #009688;
    opacity: 1.0;
    filter: alpha(opacity=100);
}
.todolist > li.active > a .todolist-title {
    text-decoration: line-through;
}
.todolist > li {
    border-bottom: 1px solid #ddd;
}
.todolist-input i {
    display: block;
    width: 14px;
    text-align: center;
}
.todolist-input,
.todolist-title {
    display: table-cell;
    padding: 10px;
}
.todolist-title {
    padding-right: 15px;
}
.todolist-input {
    text-align: center;
    font-size: 14px;
    border-right: 1px solid #ddd;
    padding-left: 15px;
}


/* 6.5 Page - Vector / Google Map */
            
.page-sidebar-minified .map {
    left: 60px;
}
.map {
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 220px;
    right: 0;
    z-index: 0;
    background: #263238;
    -webkit-transform: translateZ(0);
}
.page-with-wide-sidebar .map {
    left: 250px;
}
.map .jvectormap-zoomin,
.map .jvectormap-zoomout {
    width: 20px !important;
    height: 20px !important;
    font-size: 16px;
    line-height: 16px;
    left: 25px;
}
.map .jvectormap-zoomin {
    top: 60px;
}
.map .jvectormap-zoomout {
    top: 90px;
}
.map-float-table {
    position: absolute;
    right: 25px;
    bottom: 25px;
    background: url(../../img/transparent/black-0.6.png);
    background: rgba(0,0,0,0.6);
}
.map-content {
    padding: 0 25px;
    z-index: 100;
    position: relative;
}


/* 6.6 Page - Invoice */                
          
.invoice > div:not(.invoice-footer) {
    margin-bottom: 20px;
}
.invoice {
    background: #fff;
    padding: 20px;
}
.invoice-company {
    font-size: 20px;
}
.invoice-header {
    margin: 0 -20px;
    background: #eee;
    padding: 20px;
}
.invoice-from,
.invoice-to,
.invoice-date {
    display: table-cell;
    width: 1%;
}
.invoice-from,
.invoice-to {
    padding-right: 20px;
}
.invoice-from strong,
.invoice-to strong,
.invoice-date .date {
    font-size: 16px;
    font-weight: 600;
}
.invoice-date {
    text-align: right;
    padding-left: 20px;
}
.invoice-price {
    background: #eee;
    display: table;
    width: 100%;
}
.invoice-price .invoice-price-left,
.invoice-price .invoice-price-right {
    display: table-cell;
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    width: 75%;
    position: relative;
    vertical-align: middle;
}
.invoice-price .invoice-price-left .sub-price {
    display: table-cell;
    vertical-align: middle;
    padding: 0 20px;
}
.invoice-price small {
    font-size: 12px;
    font-weight: normal;
    display: block;
}
.invoice-price .invoice-price-row {
    display: table;
    float: left;
}
.invoice-price .invoice-price-right {
    width: 25%;
    background: #212121;
    color: #fff;
    font-size: 28px;
    text-align: right;
    vertical-align: bottom;
    font-weight: 300;
}
.invoice-price .invoice-price-right small {
    display: block;
    opacity: 0.6;
    filter: alpha(opacity=60);
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 12px;
}
.invoice-footer {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    font-size: 10px;
}
.invoice-note {
    color: #999;
    margin-top: 80px;
    font-size: 85%;
}


/* 6.7 Page - Login */
	    
.login {
    margin: 168px 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}
.login label {
    color: #707478;
}
.login .login-header {
    position: absolute;
    top: -80px;
    left: 50%;
    right: 0;
    width: 450px;
    padding: 0 40px;
    margin-left: -225px;
    font-weight: 300;
}
.login .login-header .brand {
    padding: 0;
    font-size: 28px;
}
.login .login-header .brand .logo {
    border: 14px solid transparent;
    border-color: #4DCACA #31A3A3 #1D8888;
    width: 28px;
    height: 28px;
    position: relative;
    font-size: 0;
    margin-right: 10px;
    top: -9px;
}
.login .login-header .brand small {
    font-size: 14px;
    display: block;
}
.login .login-header .icon {
    position: absolute;
    right: 40px;
    top: -2px;
    opacity: 0.1;
    filter: alpha(opacity=10);
}
.login .login-header .icon i {
    font-size: 70px;
}
.login .login-content {
    padding: 30px 40px;
    color: #999;
    width: 450px;
    margin: 0 auto;
}


/*  6.8 Page - Gallery */

.gallery {
    margin: 0 -10px;
}
.gallery-option-set {
    display: block;
    margin-bottom: -5px;
}
.gallery-option-set .btn {
    margin: 0 5px 5px 0;
}
.gallery .image {
    width: 25%;
    display: block;
    margin-right: -10px;
    overflow: hidden;
    padding: 10px;
}
.gallery .image .image-container {
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 2px 3px rgba(0,0,0,0.15);
    box-shadow: 0 2px 3px rgba(0,0,0,0.15);
}
.gallery .image img {
    width: 100%;
    height: 200px;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}
.gallery .image-inner {
    position: relative;
    background: #fff;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}
.gallery .image a {
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    transition: all .2s linear;
}
.gallery .image a:hover,
.gallery .image a:focus {
    opacity: 0.8;
    filter: alpha(opacity=80);
}
.gallery .image-caption {
    position: absolute;
    top: 15px;
    left: 0;
    background: url(../../img/transparent/black-0.6.png);
    background: rgba(0,0,0,0.6);
    color: #fff;
    padding: 5px 15px;
    margin: 0;
}
.gallery .image-info {
    background: #fff;
    padding: 15px;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
}
.gallery .image-info .title {
    margin: 0 0 10px;
    line-height: 18px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gallery .image-info .rating {
    line-height: 20px;
    margin: 0 0 3px;
}
.gallery .image-info .desc {
    line-height: 16px;
    font-size: 12px;
    height: 48px;
    overflow: hidden;
}
.gallery .rating span.star {
    font-family: FontAwesome;
    display: inline-block;
}
.gallery .rating span.star:before {
    content: "\f005";
    color: #999;
}
.gallery .rating span.star.active:before {
    color: #FF8500;
}


/* 6.9 Page - Inbox */
      
.nav-inbox > li > a {
    padding: 8px 15px;
    line-height: 20px;
}
.nav-inbox > li > a:before,
.nav-inbox > li > a:after {
    content: '';
    display: table;
    clear: both;
}
.nav-inbox > li > a i {
    font-size: 14px;
    line-height: 20px;
}
.nav-inbox > li > a i.material-icons {
    float: left;
    font-size: 18px;
    margin: 0 10px 0 0;
}
.table.table-email {
    line-height: 20px;
    margin-bottom: 0;
}
.email-footer {
    border-top: 1px solid #ccc;
    padding: 15px 8px;
    line-height: 26px;
}
.table.table-email .email-header-link {
    color: #333;
}
.table.table-email .email-select {
    width: 20px;
    font-size: 14px;
}
.table.table-email .email-select a {
    color: #999;
}
.table.table-email .email-btn {
    color: #ccc;
    font-size: 14px;
    margin-right: 15px;
    float: left;
}
.table.table-email .email-btn i.material-icons {
    font-size: 18px;
}
.table.table-email .email-sender {
    padding-right: 25px;
}
.table.table-email .email-btn + .email-btn {
    margin-left: -10px;
}
.table.table-email .email-btn:hover,
.table.table-email .email-btn:focus {
    color: #999;
}
.table.table-email tr:hover td {
    background: #fafafa;
}
.table.table-email tr.active td.email-select i:before,
.table.table-email tr.active th.email-select i:before {
    content: '\f14a';
}
.table.table-email thead tr.active th {
    background: none;
}
.email-btn-row {
    margin-bottom: 15px;
}
.email-btn-row .btn + .btn {
    margin-left: 5px;
}
.email-content {
    background: #fff;
    padding: 15px;
}


/* 6.10 Page - 404 Error Page */

.error {
    margin: 0 auto;
    text-align: center;
}
.error-code,
.error-content {
    position: absolute;
    left: 0;
    right: 0;
    -webkit-transform: translateZ(0);
}
.error-code {
    bottom: 60%;
    color: #212121;
    font-size: 96px;
    line-height: 100px;
}
.error-code .material-icons {
    font-size: 64px;
}
.error-content {
    top: 40%;
    bottom: 0;
    padding-top: 15px;
    background: #212121;
}
.error-message {
    color: #fff;
    font-size: 24px;
}
.error-desc {
    font-size: 14px;
    color: #9E9E9E;
}



/* -------------------------------
   7.0 Update - V1.1
------------------------------- */

/* 7.1 Timeline Setting */

.timeline {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.timeline:before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 5px;
    background: #212121;
    left: 20%;
    margin-left: -2.5px;
}
.timeline > li {
    position: relative;
    min-height: 50px;
}
.timeline > li + li {
    margin-top: 40px;
}
.timeline .timeline-time {
    position: absolute;
    left: 0;
    width: 15%;
    text-align: right;
    padding-top: 7px;
}
.timeline .timeline-time .date,
.timeline .timeline-time .time {
    display: block;
}
.timeline .timeline-time .date {
    line-height: 18px;
    font-size: 14px;
}
.timeline .timeline-time .time {
    line-height: 28px;
    font-size: 24px;
    color: #212121;
}
.timeline .timeline-icon {
    left: 15%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 5px;
}
.timeline .timeline-icon a {
    text-decoration: none;
    width: 50px;
    height: 50px;
    display: inline-block;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: #616161;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    border: 5px solid #212121;
    transition: background .2s linear;
    -moz-transition: background .2s linear;
    -webkit-transition: background .2s linear;
}
.timeline .timeline-icon i {
    line-height: 40px;
}
.timeline .timeline-icon a:hover,
.timeline .timeline-icon a:focus {
    background: #2196F3;
}
.timeline .timeline-body {
    margin-left: 25%;
    margin-right: 15%;
    background: #fff;
    position: relative;
    padding: 20px 30px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}
.timeline .timeline-body:before {
    content: '';
    display: block;
    position: absolute;
    border: 10px solid transparent;
    border-right-color: #fff;
    left: -20px;
    top: 20px;
}
.timeline-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #E0E0E0;
    line-height: 30px;
}
.timeline-header .userimage {
    float: left;
    width: 34px;
    height: 34px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    overflow: hidden;
    margin: -2px 10px -2px 0;
}
.timeline-header .username {
    font-size: 18px;
}
.timeline-header .username,
.timeline-header .username a {
    color: #212121;
}
.timeline img {
    max-width: 100%;
    display: block;
}
.timeline-content {
    font-size: 14px;
}
.timeline-header + .timeline-content,
.timeline-header + .timeline-footer,
.timeline-content + .timeline-footer {
    margin-top: 20px;
}
.timeline-content:before,
.timeline-content:after {
    content: '';
    display: table;
    clear: both;
}
.timeline-title {
    margin-top: 0;
}
.timeline-footer {
    margin: -20px -30px;
    padding: 20px 30px;
    background: #E0E0E0;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}
.timeline-footer a:not(.btn) {
    color: #616161;
}
.timeline-footer a:not(.btn):hover,
.timeline-footer a:not(.btn):focus {
    color: #212121;
}


/* 7.2 Coming Soon Page Setting */

.coming-soon .brand {
    line-height: 40px;
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 50px;
    color: #fff;
    text-align: center;
}
.coming-soon .brand .logo {
    border: 16px solid transparent;
    border-color: #4DCACA #31A3A3 #1D8888;
    margin-right: 5px;
    position: relative;
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: middle;
    top: -4px;
}
.coming-soon .coming-soon-header .desc,
.coming-soon .coming-soon-header .timer,
.coming-soon .coming-soon-header .brand {
    position: relative;
    z-index: 1020;
}
.coming-soon .coming-soon-header {
    background: url(../../img/coming-soon.jpg);
    padding: 100px 0 50px;
    position: relative;
}
.coming-soon .coming-soon-header:before {
    background: #000;
}
.coming-soon .coming-soon-header .bg-cover {
    background: #000;
    opacity: 0.6;
    filter: alpha(opacity=60);
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
}
.coming-soon .coming-soon-header .desc {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
}
.coming-soon .coming-soon-header .timer {
    margin-bottom: 50px;
    padding: 10px 0;
}
.coming-soon .coming-soon-header .timer:before,
.coming-soon .coming-soon-header .timer:after {
    content: '';
    display: table;
    clear: both;
}
.coming-soon .timer .is-countdown {
    background: none;
    border: none;
    width: 980px;
    margin: 0 auto;
}
.coming-soon .timer .is-countdown .countdown-section {
    padding: 0 10px;
}
.coming-soon .timer .is-countdown .countdown-section + .countdown-section {
    border-left: 1px solid #fff;
    border-left: 1px solid rgba(255,255,255,0.3);
}
.coming-soon .timer .countdown-amount {
    font-size: 36px;
    color: #fff;
}
.coming-soon .timer .countdown-period {
    font-size: 14px;
    color: #999;
}
.coming-soon .coming-soon-content {
    color: #333;
    text-align: center;
    padding: 25px 0;
}
.coming-soon .coming-soon-content .desc {
    margin-bottom: 25px;
    font-size: 14px;
}
.coming-soon .coming-soon-content .input-group {
    width: 480px;
    margin: 0 auto;
}



/* -------------------------------
   8.0 Update - V1.2
------------------------------- */

/* 8.1 Theme Panel Setting */

.theme-panel {
    position: fixed;
    right: -175px;
    top: 150px;
    z-index: 1020;
    background: #fff;
    padding: 15px;
    box-shadow: 0 0 2px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.4);
    -moz-box-shadow: 0 0 2px rgba(0,0,0,0.4);
    width: 175px;
    -webkit-transition: right .2s linear;
    -moz-transition: right .2s linear;
    transition: right .2s linear;
}
.theme-panel.active {
    right: 0;
}
.theme-panel .theme-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.theme-panel .theme-list + .theme-list {
    margin-top: 10px;
}
.theme-panel .theme-list > li {
    float: left;
}
.theme-panel .theme-list > li + li {
    margin-left: 5px;
}
.theme-panel .theme-list > li > a {
    text-decoration: none;
}
.theme-panel .theme-list > li.active > a  {
    position: relative;
}
.theme-panel .theme-list > li.active > a:before {
    content: '\f00c';
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 14px;
    color: #fff;
    opacity: 0.4;
    filter: alpha(opacity=40);
    text-align: center;
}
.theme-panel .theme-list > li.active > a.bg-white:before {
    color: #666;
}
.theme-panel .theme-list > li > a {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: block;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    transition: all .2s linear;
}
.theme-panel .theme-list > li > a:hover,
.theme-panel .theme-list > li > a:focus {
    opacity: 0.6;
    filter: alpha(opacity=60);
}
.theme-panel .theme-collapse-btn {
    position: absolute;
    left: -30px;
    top: 10%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    color: #333;
    background: #fff;
    border-radius: 4px 0 0 4px;
    text-align: center;
    box-shadow: -1px 0px 3px -1px rgba(0,0,0,0.4);
    -webkit-box-shadow: -1px 0px 3px -1px rgba(0,0,0,0.4);
    -moz-box-shadow: -1px 0px 3px -1px rgba(0,0,0,0.4);
}
.theme-panel .theme-panel-content {
    margin: -15px;
    padding: 15px;
    background: #fff;
    position: relative;
    z-index: 1020;
}
.theme-panel.active .theme-collapse-btn .fa:before {
    content: '\f00d';
}
.theme-panel .theme-list a.bg-white {
    border: 1px solid #E0E0E0;
}
.theme-panel .control-label {
    line-height: 30px;
}
.theme-panel .control-label.double-line {
    line-height: 15px;
}
.theme-panel .divider {
    height: 1px;
    margin: 15px 0;
    background: #f2f2f2;
}


/* 8.2 Powerange Slider Setting */

.slider-wrapper {
    padding: 15px 30px !important;
    margin-bottom: 10px !important;
}
.slider-wrapper.slider-without-range {
    padding: 15px 0 !important;
}
.slider-wrapper.slider-vertical {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.range-min, .range-max {
    color: #212121 !important;
}
.range-handle {
    cursor: pointer !important;
}
.range-quantity { background: #009688 !important; }
.red .range-quantity { background: #F44336 !important; }
.blue .range-quantity { background: #2196F3 !important; }
.purple .range-quantity { background: #673AB7 !important; }
.orange .range-quantity { background: #FF9800 !important; }
.black .range-quantity { background: #212121 !important; }


/* 8.3 Plugins DateTimepicker  */

.datetimepicker table tr td.active,
.datetimepicker table tr span.active {
    background-image: none !important;
    background: #2196F3!important;
    color: #fff !important;
}
.datetimepicker table {
    width: 100%;
}
.datetimepicker th, 
.datetimepicker td {
    padding: 0px 5px !important;
    width: 30px !important;
    height: 30px !important;
}


/* 8.4 Plugins wysihtml5 Sandbox  */

.wysihtml5-sandbox {
    width: 100% !important;
}


/* 8.5 Plugins Typeahead  */

.twitter-typeahead .tt-hint {
    line-height: 22px;
    padding: 5px 11px;
    opacity: 0.6;
    filter: alpha(opacity=60);
}
.tt-suggestion.tt-is-under-cursor {
    background: #edf0f5;
    color: #222;
}


/*  8.6 Plugins Select 2 Choice */

.select2-container.form-control .select2-choice, 
.select2-container.form-control .select2-choice {
    background: none !important;
    margin: -6px -12px !important;
    padding: 6px 12px !important;
    line-height: 20px !important;
    border:none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none;
    height: 32px !important;
}
.select2-container.form-control.input-sm .select2-choice, 
.select2-container.form-control.input-sm .select2-choice {
    height: 30px !important;
}
.select2-container .select2-choice .select2-arrow {
    background: none !important;
    border-left: 1px solid #E0E0E0 !important;
}
.select2-container.form-control .select2-choice abbr {
    top: 10px !important;
}
.select2-container.form-control.input-sm .select2-choice abbr {
    top: 9px !important;
}
.select2-container .select2-choice .select2-arrow b {
    background-position: 0 3px !important;
}
.select2-container.form-control.input-sm .select2-choice .select2-arrow b {
    background-position: 0 2px !important;
}
.select2-drop-active {
    border-color: #E0E0E0 !important;
    -webkit-box-shadow: 0 2px 2px -1px rgba(0,0,0,0.2) !important;
    box-shadow: 0 2px 2px -1px rgba(0,0,0,0.2) !important;
}
.select2-drop.select2-drop-above {
    -webkit-box-shadow: 0 -2px 2px -1px rgba(0,0,0,0.2) !important;
    box-shadow: 0 -2px 2px -1px rgba(0,0,0,0.2) !important;
}
.select2-search input {
    border-color: #E0E0E0 !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
}
.select2-drop {
    border-color: #E0E0E0 !important;
    padding-top: 5px !important;
    border-top: 1px solid #E0E0E0 !important;
}
.select2-results .select2-highlighted {
    background: #edf0f5 !important;
    color: #222 !important;
}
.select2-container.select2-container-multi.form-control {
    height: auto !important;
    width: 240px !important;
}
.select2-container.select2-container-multi.form-control .select2-choices {
    margin: -6px -12px !important;
    border: none !important;
    box-shadow: none !important;
    min-height: 22px !important;
    height: auto !important;
    background: none !important;
}
.select2-container-multi .select2-choices .select2-search-choice {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-color: #ddd !important;
    background: #fff !important;
}


/*  8.7 Login Page V2 */

.login-v2 {
    background: url(../../img/transparent/black-0.4.png);
    background: rgba(0,0,0,0.4);
    color: #ccc;
    width: 450px;
    margin: 168px auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.login-v2 .login-content {
    padding: 40px;
}   
.login.login-v2 label {
    color: #ccc;
}
.login.login-v2 a {
    color: #fff;
}
.login.login-v2 .login-header {
    color: #fff;
}
.login.login-v2 .login-header .icon {
    opacity: 0.4;
    filter: alpha(opacity=40);
}
.login-v2 .form-control {
    background: url(../../img/transparent/black-0.4.png);
    background: rgba(0,0,0,0.4);
    border: none;
    color: #fff;
}
.login-cover,
.login-cover-bg,
.login-cover-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.login-cover-image img {
    max-width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.login-cover-bg {
    background: #666666; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover,  #666666 0%, #000000 100%); /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,#666666), color-stop(100%,#000000)); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover,  #666666 0%,#000000 100%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover,  #666666 0%,#000000 100%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover,  #666666 0%,#000000 100%); /* IE10+ */
    background: radial-gradient(ellipse at center,  #666666 0%,#000000 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    opacity: 0.6;
    filter: alpha(opacity=60);
}
.login-bg-list {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1020;
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 20px;
}
.login-bg-list > li {
    width: 80px;
    height: 60px;
    overflow: hidden;
    float: left;
    transition: all .2s linear;
    -webkit-transition:  all .2s linear;
}
.login-bg-list > li a {
    display: block;
    height: 60px;
}
.login-bg-list > li.active {
    outline: 3px solid #fff;
    z-index: 1020;
    position: relative;
}
.login-bg-list > li img {
    max-height: 100%;
}



/* -------------------------------
   9.0 Update - V1.3
------------------------------- */

/* 9.1 Widget Chart Setting */

.widget-chart {
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.widget-chart .chart-title {
    font-size: 18px !important;
    color: #fff;
    font-weight: normal !important;
    margin: 2px 5px 7px;
}
.widget-chart .chart-title small {
    display: block;
    margin-top: 5px;
    font-size: 13px;
    color: #616D72;
    color: rgba(255,255,255,0.5);
}
.widget-chart .widget-chart-content {
    padding: 15px;
    margin-right: 230px;
}
.widget-chart .widget-chart-sidebar {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 230px;
    padding: 15px;
}
.widget-chart-sidebar .chart-number {
    font-size: 24px;
    font-weight: normal;
    color: #fff;
    margin-bottom: 10px;
}
.widget-chart-sidebar .chart-number small {
    font-size: 13px;
    color: #fff;
    color: rgba(255,255,255,0.5);
    display: block;
}
.widget-chart-sidebar .chart-legend {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: normal;
    position: absolute;
    bottom: 10px;
}
.widget-chart-sidebar .chart-legend > li {
    margin-bottom: 5px;
    color: #fff;
}
.widget-chart-sidebar .chart-legend > li span {
    color: #fff;
    color: rgba(255,255,255,0.5);
}
.morris-inverse .morris-hover {
    background: url('../../img/transparent/black-0.4.png');
    background: rgba(0,0,0,0.4) !important;
    border: none !important;
    padding: 8px !important;
    color: #ccc !important;
}
.morris-hover {
    z-index: 0 !important;
}


/* 9.2 List Group Item Inverse Setting */

.list-group-item.list-group-item-inverse {
    background: #212121;
    border-color: #000;
    color: #fff;
}
.list-group-item.list-group-item-inverse .label-inverse, 
.list-group-item.list-group-item-inverse .badge-inverse {
    background: #212121;
    background: rgba(0,0,0,0.5);
}
.list-group-item.list-group-item-inverse:hover, 
.list-group-item.list-group-item-inverse:focus {
    color: #fff;
    background: #000;
    background: #111;
}


/* 9.3 Bootstrap Calendar Setting */

.bootstrap-calendar .calendar {
    background: #fff;
    border: none;
    border-radius: 0;
    padding: 10px 0;
}
.bootstrap-calendar .calendar td {
    border: none !important;
}
.bootstrap-calendar .calendar .table td {
    padding: 3px 10px;
}
.bootstrap-calendar .calendar a {
    line-height: 24px;
}
.bootstrap-calendar .calendar .week_days td {
    background: none;
    color: #a9acae;
}
.bootstrap-calendar .calendar .visualmonthyear {
    color: #212121;
    font-weight: normal;
}
.bootstrap-calendar .calendar .table.header td {
    color: #707478;
    vertical-align: middle;
}
.bootstrap-calendar .calendar .table.header td.year {
    font-size: 18px;
    font-weight: 300;
}
.bootstrap-calendar .calendar .table.header {
    margin-bottom: 5px;
}
.bootstrap-calendar .calendar .table.header .year {
    width: 72%;
}
.bootstrap-calendar .calendar .icon-arrow-right,
.bootstrap-calendar .calendar .icon-arrow-left { 
    cursor: pointer;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}
.bootstrap-calendar .calendar .icon-arrow-right:hover,
.bootstrap-calendar .calendar .icon-arrow-left:hover,
.bootstrap-calendar .calendar .icon-arrow-right:focus,
.bootstrap-calendar .calendar .icon-arrow-left:focus {
    color: #212121;
}
.bootstrap-calendar .calendar .icon-arrow-left:before {
    content: '\f060';
}
.bootstrap-calendar .calendar .icon-arrow-right:before {
    content: '\f061';
}
.bootstrap-calendar .calendar .table {
    margin-bottom: 0;
}
.bootstrap-calendar .calendar .event a {
    display: block;
    border-radius: 30px;
    background: #fff;
    color: #fff;
    margin: 0 auto;
}
.bootstrap-calendar .calendar .event .popover a {
    color: #428bca;
}
.bootstrap-calendar .calendar .event .popover a:hover, 
.bootstrap-calendar .calendar .event .popover a:focus {
    color: #2a6496;
    text-decoration: underline;
}
.bootstrap-calendar .calendar .event,
.bootstrap-calendar .calendar .event:hover,
.bootstrap-calendar .calendar .event:focus,
.bootstrap-calendar .calendar .event a:hover,
.bootstrap-calendar .calendar .event a:focus {
    text-shadow: none;
    color: #212121;
    box-shadow: none;
    font-weight: normal;
}
.bootstrap-calendar .calendar .event a:hover,
.bootstrap-calendar .calendar .event a:focus {
    color: #fff;
    opacity: 0.8;
}
.bootstrap-calendar .calendar .event,
.bootstrap-calendar .calendar .event:hover,
.bootstrap-calendar .calendar .event:focus {
    background: none;
    text-align: center;
}
.bootstrap-calendar .calendar .fa-circle {
    position: absolute;
    right: 0;
    top: 0;
}


/* 9.4 Registered Users List Setting */

.registered-users-list {
    list-style-type: none;
    margin: 7.5px;
    padding: 0;
}
.registered-users-list  > li {
    width: 25%;
    font-size: 13px;
    float: left;
    padding: 7.5px;
    color: #333;
}
.registered-users-list  > li .username {
    font-size: 13px;
    color: #333;
    margin-bottom: 0;
}
.registered-users-list > li img {
    border-radius:  3px;
    max-width: 100%;
}
.registered-users-list > li > a {
    display: block;
    margin-bottom: 10px;
}
.registered-users-list > li .username small {
    display: block;
    font-size: 12px;
    color: #707478;
    margin-top: 3px;
}


/* 9.5 Chat List Setting */

.panel-body .chats {
    padding: 0;
}
.slimScrollDiv .chats {
    padding-right: 15px;
}
.chats {
    list-style-type: none;
    margin: 0;
    padding: 12px 15px 15px;
}
.chats > li + li {
    margin-top: 15px;
}
.chats > li:before,
.chats > li:after {
    content: '';
    display: table;
    clear: both;
}
.chats .name {
    color: #212121;
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    text-decoration: none;
    font-size: 13px;
}
.chats .name .label {
    font-size: 10px;
    padding: 1px 4px 2px;
    margin-left: 3px;
    position: relative;
    top: -1px;
}
.chats .date-time {
    font-size: 12px;
    display: block;
    float: right;
    color: #999;
    margin-top: 3px;
}
.chats .image {
    float: left;
    width: 60px;
    height: 60px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
}
.chats .image img {
    max-width: 100%;
}
.chats .image + .message {
    margin-left: 75px;
}
.chats .message {
    border: 1px solid #ccd0d4;
    padding: 8px 12px;
    font-size: 13px;
    position: relative;
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.chats .message:before,
.chats .message:after {
    content: '';
    position: absolute;
    top: 10px;
    left: -14px;
    border: 7px solid transparent;
    border-right-color: #ccd0d4;
}
.chats .message:after {
    left: -13px;
    border-right-color: #fff;
}
.chats .right .name {
    text-align: right;
}
.chats .right .date-time {
    float: left;
}
.chats .right .image {
    float: right;
}
.chats .right .image + .message {
    margin-right: 75px;
    margin-left: 0;
}
.chats .right .message:before,
.chats .right .message:after {
    left: auto;
    right: -14px;
    border-right-color: transparent;
    border-left-color: #ccd0d4;
}
.chats .right .message:after {
    right: -13px;
    border-left-color: #fff;
}


/* 9.6 Gallery v2 - Superbox Setting */

.superbox {
    font-size: 0;
}
.superbox-list {
    display: inline-block;
    width:12.5%;
    margin: 0;
    position: relative;
}
.superbox-list.active:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    border: 10px solid transparent;
    border-bottom-color: #212121;
    margin-left: -10px;
}
.superbox-show {
    text-align: center;
    position: relative;
    background: #212121;
    width: 100%;
    float: left;
    padding: 25px;
    display: none;
}
.superbox-img {
    max-width: 100%;
    width: 100%;
    cursor: pointer;
}
.superbox-current-img {
    -webkit-box-shadow: 0 5px 35px rgba(0,0,0,.65);
    box-shadow: 0 5px 35px rgba(0,0,0,.65);
    max-width: 100%;
}
.superbox-img:hover {
    opacity: 0.8;
}
.superbox-close {
    opacity: 0.7;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
    /* background: url(assets/plugins/superbox/img/close.gif) no-repeat center center; */
    width: 35px;
    height: 35px;
}



/* -------------------------------
   10.0 Update - V1.4
------------------------------- */

/* 10.1 Page - Email Inbox v2 */

.list-email {
    margin-bottom: 0;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}
.list-email > li.list-group-item {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
}
.list-email > li.list-group-item {
    padding: 15px 15px 15px 20px;
    position: relative;
}
.list-email > li.list-group-item + li.list-group-item {
    border-top: 1px solid #E0E0E0;
}
.list-email > li.list-group-item:before,
.list-email > li.list-group-item:after {
    content: '';
    display: table;
    clear: both;
}
.list-email > li.list-group-item:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
    background: #fff;
}
.list-email > li.list-group-item.success:before {
    background: #009688;
}
.list-email > li.list-group-item.info:before {
    background-color: #03A9F4;
}
.list-email > li.list-group-item.danger:before {
    background-color: #F44336;
}
.list-email > li.list-group-item.primary:before {
    background-color: #2196F3;
}
.list-email > li.list-group-item.inverse:before {
    background-color: #212121;
}
.list-email > li.list-group-item.warning:before {
    background-color: #FF9800;
}
.list-email > li.list-group-item.purple:before {
    background-color: #673AB7;
}
.list-email > li.list-group-item.default:before {
    background-color: #b6c2c9;
}
.list-email .email-user {
    float: left;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    overflow: hidden;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    color: #6e7179;
    background: #f4f6f7;
}
.list-email .email-user img {
    max-width: 100%;
    display: block;
}
.list-email .email-user + .email-info {
    margin-left: 65px;
}
.list-email .email-checkbox + .email-user + .email-info {
    margin-left: 95px;
}
.list-email .email-checkbox + .email-info {
    margin-left: 35px;
}
.list-email .email-title {
    margin-top: 3px;
    margin-bottom: 3px;
    line-height: 20px;
}
.list-email .email-title .label {
    position: relative;
    top: -1px;
}
.list-email .email-title,
.list-email .email-title a {
    color: #212121;
}
.list-email .email-time {
    float: right;
    font-size: 11px;
    color: #C8CCCF;
    margin-left: 10px;
}
.list-email .email-checkbox {
    float: left;
    width: 20px;
    margin-right: 10px;
    height: 50px;
    line-height: 50px;
}
.list-email .email-checkbox label {
    color: #E0E0E0;
    display: block;
    cursor: pointer;
}
.list-email .email-checkbox label input[type=checkbox] {
    display: none;
}
.list-email .email-checkbox label .fa {
    font-size: 18px;
    transition: all .2s linear;
    -webkit-transition: all .2s linear;
}
.list-email .email-checkbox label.active {
    color: #707478;
}
.list-email .email-checkbox label.active .fa:before {
    content: '\f046';
}
.list-email > li.selected {
    background: #FFFDE7;
}


/* 10.2 Page - Email Detail v2 */

.attached-document {
    list-style-type: none;
    margin: 15px 0 0;
    padding: 0;
}
.attached-document > li {
    width: 180px;
    float: left;
    background: #E0E0E0;
    color: #707478;
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
}
.attached-document > li:before {
    content: '\f0c6';
    font-family: FontAwesome;
    position: absolute;
    top: -10px;
    right: 5px;
    font-size: 18px;
}
.attached-document .document-file {
    height: 70px;
    background: rgba(0,0,0,0.1);
    overflow: hidden;
    text-align: center;
    line-height: 70px;
    font-size: 32px;
}
.attached-document .document-name a {
    color: #212121;
}
.attached-document > li img {
    max-width: 100%;
}
.attached-document > li .document-name {
    padding: 5px 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



/* -------------------------------
   11.0 Update - V1.5
------------------------------- */

/* 11.1 Pace Loader Setting */

.pace-inactive {
    opacity: 0;
}
.pace.pace-inactive {
    z-index: 0;
}
.ie8 .pace-inactive,
.ie8 .pace .pace-activity {
    display: none;
}
.pace {
    background: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
    z-index: 1020;
}
.pace-progress {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    text-align: center;
    height: 3px;
    background: #009688;
    -webkit-transition: width 1s;
    -moz-transition: width 1s;
    -o-transition: width 1s;
    transition: width 1s;
    z-index: 2000;
}
.pace:before {
    content: '';
    background: none;
    position: fixed;
    top: 60px;
    right: 0;
    left: 0;
    height: 3px;
}
.pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 71px;
    right: 8px;
    width: 14px;
    height: 14px;
    border: solid 2px transparent;
    border-top-color: #009688;
    border-left-color: #009688;
    border-radius: 10px;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}
@-webkit-keyframes pace-spinner {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes pace-spinner {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes pace-spinner {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes pace-spinner {
  0% { -ms-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes pace-spinner {
  0% { transform: rotate(0deg); transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}
.pace-top .pace:before,
.pace-top .pace-progress {
    top: 0;
}
.pace-top .pace .pace-activity {
    top: 11px;
}



/* -------------------------------
   12.0 Update - V1.6
------------------------------- */

/* 12.1 Unlimited Nav Tabs */

.tab-overflow {
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}
.tab-overflow .nav.nav-tabs {
    white-space: nowrap;
    overflow: hidden;
    display: table;
    margin: 0;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}
.tab-overflow .nav.nav-tabs > li,
.tab-overflow.nav.nav-tabs > li {
    display: table-cell;
    float: none;
}
.tab-overflow .nav.nav-tabs > li > a {
    margin-left: -1px;
}
.tab-overflow .next-button,
.tab-overflow .prev-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    display: none !important;
    opacity: 0;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    transition: all .2s linear;
}
.tab-overflow .prev-button {
    left: 0;
    right: auto;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .next-button,
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .prev-button {
    -webkit-box-shadow: 0 0 15px #000;
    box-shadow: 0 0 15px #000;
}
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .prev-button {
    -webkit-box-shadow: 0 0 15px #000;
    box-shadow: 0 0 15px #000;
}
.tab-overflow .next-button a,
.tab-overflow .prev-button a {
    margin: 0;
    background: #BDBDBD;
}
.tab-overflow .prev-button > a {
    -webkit-border-radius: 3px 0 3px;
    -moz-border-radius: 3px 0 3px;
    border-radius: 3px 0 3px;
}
.tab-overflow .nav-tabs-inverse .next-button > a,
.tab-overflow .nav-tabs-inverse .prev-button > a,
.nav.nav-tabs.nav-tabs-inverse > li > a,
.nav.nav-tabs.nav-tabs-inverse > li > a:hover,
.nav.nav-tabs.nav-tabs-inverse > li > a:focus {
    background: #212121;
}
.nav.nav-tabs.nav-tabs-inverse > li.active > a,
.nav.nav-tabs.nav-tabs-inverse > li.active > a:hover,
.nav.nav-tabs.nav-tabs-inverse > li.active > a:focus {
    background: #fff;
    color: #212121;
}
.tab-overflow.overflow-right .next-button,
.tab-overflow.overflow-left .prev-button {
    display: block !important;
    opacity: 1;
    background: none;
}
.tab-overflow .nav-tabs > li.prev-button + li.active > a {
    -webkit-border-radius: 0 2px 0 0;
    -moz-border-radius: 0 2px 0 0;
    border-radius: 0 2px 0 0;
}



/* -------------------------------
   13.0 Update - V1.7
------------------------------- */

/* 13.1 Light Sidebar Setting */

.page-with-light-sidebar .sidebar,
.page-with-light-sidebar .sidebar-bg {
    background: #fff;
}
.page-with-light-sidebar .sidebar .nav > li > a i {
    color: #9e9e9e;
}
.page-with-light-sidebar .sidebar .nav > li.nav-header {
    padding: 10px 20px 5px;
    color: #9e9e9e;
    font-size: 12px;
}
.page-with-light-sidebar .sidebar .nav > li > a {
    color: #000;
    font-weight: normal;
}
.page-with-light-sidebar .sidebar .nav > li.nav-profile > a {
    color: #fff;
}
.page-with-light-sidebar .sidebar .nav > li.nav-profile:before {
    background: rgba(0,0,0,0.5);
}
.page-with-light-sidebar .sidebar .nav > li.active > a, 
.page-with-light-sidebar .sidebar .nav > li.active > a:hover, 
.page-with-light-sidebar .sidebar .nav > li.active > a:focus {
    background: #616161;
    color: #fff;
}
.page-with-light-sidebar .sidebar .nav > li.active > a i {
    color: #fff;
}
.page-with-light-sidebar .sidebar .nav > li.has-sub .sub-menu > li > a {
    color: #616161;
}
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active > a,
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active > a:hover,
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active > a:focus {
    background: none;
}
.page-with-light-sidebar .sidebar .nav > li.has-sub .sub-menu,
.page-with-light-sidebar .sidebar .nav > li.has-sub .sub-menu > li.active > a,
.page-with-light-sidebar .sidebar .nav > li.has-sub .sub-menu > li > a:hover, 
.page-with-light-sidebar .sidebar .nav > li.has-sub .sub-menu > li > a:focus {
    background: rgba(0,0,0,0.05);
}
.page-with-light-sidebar .sidebar .nav.nav-profile { 
    border-bottom: 1px solid #E0E0E0;
}



/* 13.2 Wide Sidebar Setting */

.page-with-wide-sidebar .sidebar,
.page-with-wide-sidebar .sidebar+.sidebar-bg,
.page-with-wide-sidebar.page-sidebar-minified .sidebar-bg.sidebar-right, 
.page-with-wide-sidebar.page-sidebar-minified .sidebar.sidebar-right {
    width: 250px;
}
.page-with-wide-sidebar .content {
    margin-left: 250px;
}
.page-with-wide-sidebar.page-with-right-sidebar .content {
    margin-right: 250px;
    margin-left: 0;
}
.page-with-wide-sidebar.page-with-two-sidebar .content {
    margin-left: 250px;
    margin-right: 250px;
}
.page-with-wide-sidebar.page-sidebar-minified .sidebar,
.page-with-wide-sidebar.page-sidebar-minified .sidebar+.sidebar-bg {
    width: 60px;
}
.page-with-wide-sidebar.page-sidebar-minified .content {
    margin-left: 60px;
}


/* 13.3 Navbar Header Brand Without Background Setting */

.navbar-header.navbar-header-without-bg .navbar-brand {
    background: none !important;
}


/* 13.4 Plugins - Jstree Setting */

.jstree-default .jstree-clicked,
.jstree-default .jstree-wholerow-clicked {
    background: #eee !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.jstree-default .jstree-hovered,
.jstree-default .jstree-wholerow-hovered {
    background: #f5f5f5 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.jstree-open > .jstree-anchor > .fa-folder:before {
    content: '\f07c';
    color: #212121;
}
.jstree-default .jstree-checkbox {
    background: none;
}
.jstree-default .jstree-checkbox:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    right: 4px;
    bottom: 4px;
    border: 1px solid #b4b6b7;
    display: block;
    background: #fff;
}
.jstree-default .jstree-anchor > .jstree-checkbox.jstree-undetermined:after {
    content: '';
    position: absolute;
    left: 7px;
    right: 7px;
    bottom: 7px;
    top: 7px;
    display: block;
    background: #b4b6b7;
}
.jstree-default.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:after, 
.jstree-default .jstree-checked > .jstree-checkbox:after {
    content: '\f00c';
    font-family: FontAwesome;
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    top: 5px;
    display: block;
    line-height: 16px;
    font-size: 12px;
    font-style: initial;
    color: #212121;
}


/* 13.5 Top Navbar Mega Menu Setting */

.dropdown.dropdown-lg {
    position: static;
}
.dropdown-menu.dropdown-menu-lg {
    left: 20px;
    right: 20px;
    padding: 20px;
}
.dropdown-header {
    font-size: 14px;
    font-weight: normal;
    color: #000;
    padding: 0;
    margin: 0;
}
.dropdown-menu.dropdown-menu-lg .nav > li > a {
    padding: 0;
    background: none;
    line-height: 24px;
}
.navbar-default .navbar-nav > .active > a, 
.navbar-default .navbar-nav > .active.open > a,
.navbar-default .navbar-nav > .active > a:focus, 
.navbar-default .navbar-nav > .active > a:hover {
    background: #f4f6f7 !important;
    opacity: 1.0;
    filter: alpha(opacity=100);
}


/* 13.6 Login & Register Page with News Feed Setting */

.login.login-with-news-feed,
.register.register-with-news-feed {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
}
.login.login-with-news-feed .news-feed,
.register.register-with-news-feed .news-feed {
    position: fixed;
    left: 0;
    right: 500px;
    top: 0;
    bottom: 0;
    -webkit-transform: translateZ(0);
    overflow: hidden;
}
.login.login-with-news-feed .news-image,
.register.register-with-news-feed .news-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
.login.login-with-news-feed .news-image img,
.register.register-with-news-feed .news-image img {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    max-height: 100%;
    min-width: 100%;
    top: -1960px;
    bottom: -1960px;
    left: -1960px;
    right: -1960px;
    margin: auto;
}
.login.login-with-news-feed .news-caption,
.register.register-with-news-feed .news-caption {
    background: rgba(255,255,255,0.15);
    background: rgba(0, 0, 0, 0.7);
    color: #999;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px 60px;
    font-size: 14px;
    z-index: 20;
    font-weight: 300;
    min-width: 680px;
}
.login.login-with-news-feed .news-caption .caption-title,
.register.register-with-news-feed .news-caption .caption-title {
    color: #fff;
    color: rgba(255,255,255,0.8);
    font-weight: 300;
    font-size: 28px;
}
.login.login-with-news-feed .news-caption .caption-title i,
.register.register-with-news-feed .news-caption .caption-title i {
    font-size: 28px;
}
.login.login-with-news-feed .right-content,
.register.register-with-news-feed .right-content {
    float: right;
    width: 500px;
}
.login.login-with-news-feed .login-content,
.register.register-with-news-feed .register-content {
    width: auto;
    padding: 30px 60px;
}
.register.register-with-news-feed .register-content {
    padding: 20px 60px 30px;
}
.login.login-with-news-feed .login-header,
.register.register-with-news-feed .register-header {
    position: relative;
    top: 0;
    margin: 0;
    left: 0;
    right: 0;
    padding: 100px 20px 0;
}
.register.register-with-news-feed .register-header {
    padding: 60px 60px 0;
    font-size: 32px;
    font-weight: 300;
}
.register.register-with-news-feed .register-header small {
    color: #707478;
    display: block;
    font-size: 14px;
    margin-top: 10px;
    line-height: 20px;
}
.login.login-with-news-feed .login-header .brand {
    color: #212121;
}
.login.login-with-news-feed .login-header .brand small {
    color: #707478;
}
.login.login-with-news-feed .login-header .icon {
    top: 98px;
    right: 10px;
}


/* 13.7 Plugins - Bootstrap Date Range Picker */

.daterangepicker td.active, 
.daterangepicker td.active:hover {
    background: #2196F3 !important;
    text-shadow: none !important;
    color: #fff;
}
.daterangepicker .calendar th, 
.daterangepicker .calendar td {
    padding: 7px !important;
}
.daterangepicker td.in-range {
    background: #eee;
}
.daterangepicker .ranges li.active, 
.daterangepicker .ranges li:hover {
    background: #2196F3 !important;
    border-color: #2196F3 !important;
    color: #fff !important;
}
.daterangepicker .ranges li {
    color: #212121 !important;
}


/* 13.8 Plugins - Select 2 */

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #2196F3 !important;
}
.select2-container .select2-selection--single,
.select2-container--default .select2-selection--multiple {
    min-height: 34px !important;
    border-color: #E0E0E0 !important;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 12px !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 34px !important;
    font-size: 14px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 5px !important;
    height: 32px !important;
}
.select2-dropdown {
    border: none !important;
    -webkit-box-shadow: 0 2px 5px -1px rgba(0,0,0,.2) !important;
    box-shadow: 0 2px 5px -1px rgba(0,0,0,.2) !important;
}
.select2-container--default .select2-results__group {
    color: #212121 !important;
}
.select2-container--default .select2-search--inline .select2-search__field {
    height: 23px;
    padding-left: 5px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    padding: 6px 12px !important;
    height: 34px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
    border-color: #9fa2a5 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-top: 6px !important;
}


/* 13.9 Top Navbar Language Setting */

.navbar-language .flag-icon {
    font-size: 20px;
    margin: 5px 10px 0;
    float: left;
}
.navbar-language .dropdown-menu .flag-icon {
    margin: 1px 7px 0;
    font-size: 15px;
}


/* 13.10 Profile Page Setting */

.profile-container {
    background: #fff;
    padding: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.profile-container:before,
.profile-container:after {
    content: '';
    display: table;
    clear: both;
}
.profile-left {
    width: 200px;
    float: left;
}
.profile-right {
    margin-left: 240px;
    padding-right: 20px;
}
.profile-image {
    height: 175px;
    line-height: 175px;
    text-align: center;
    font-size: 72px;
    margin-bottom: 10px;
    border: 2px solid #E0E0E0;
    overflow: hidden;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.profile-image img {
    display: block;
    max-width: 100%;
}
.profile-highlight {
    padding: 12px 15px;
    background: #FEFDE1;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.profile-highlight h4 {
    margin: 0 0 7px;
    font-size: 12px;
    font-weight: bold;
}
.table.table-profile > thead > tr > th {
    border-bottom: none !important;
}
.table.table-profile > thead > tr > th h4 {
    font-size: 20px;
    margin-top: 0;
}
.table.table-profile > thead > tr > th h4 small {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-top: 5px;
}
.table.table-profile > thead > tr > th,
.table.table-profile > tbody > tr > td {
    border: none;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #212121;
}
.table.table-profile > tbody > tr > td.field {
    width: 120px;
    text-align: right;
    font-weight: 600;
    color: #777;
}
.table.table-profile > tbody > tr.highlight > td {
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}
.table.table-profile > tbody > tr.divider > td {
    padding: 0 !important;
    height: 10px;
}
.profile-section + .profile-section {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #E0E0E0;
}
.profile-section:before,
.profile-section:after {
    content: '';
    display: table;
    clear: both;
}
.profile-section .title {
    font-size: 20px;
    margin: 0 0 15px;
}
.profile-section .title small {
    font-weight: normal;
}


/* -------------------------------
   14.0 Update - V1.8
------------------------------- */

/* 14.1 Full Color Panel */

.panel-body[class*="bg-"] {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
}


/* 14.2 Bootstrap Date Timepicker */

body .bootstrap-datetimepicker-widget.dropdown-menu {
    width: auto;
}
body .bootstrap-datetimepicker-widget .timepicker-hour, 
body .bootstrap-datetimepicker-widget .timepicker-minute, 
body .bootstrap-datetimepicker-widget .timepicker-second {
    border: 2px solid #E0E0E0;
    border-radius: 3px;
}
body .bootstrap-datetimepicker-widget.dropdown-menu,
body .bootstrap-datetimepicker-widget a[data-action] {
    color: #212121;
}
body .bootstrap-datetimepicker-widget a[data-action] {
    padding: 0;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
body .bootstrap-datetimepicker-widget table td {
    height: 32px;
    width: 32px;
    line-height: 32px;
}



/* -------------------------------
   15.0 Update - V1.9
------------------------------- */

/* 15.1 Sidebar Transparent */

.sidebar.sidebar-transparent {
    background: none;
}
.sidebar.sidebar-transparent .nav > li.nav-profile {
    background: url('../../img/transparent/black-0.3.png');
    background: rgba(0, 0, 0, 0.25);
}
.sidebar.sidebar-transparent .nav > li.nav-profile:before {
    display: none;
}
.sidebar.sidebar-transparent .nav > li.nav-profile > a:hover,
.sidebar.sidebar-transparent .nav > li.nav-profile > a:focus {
    background: none;
}
.sidebar.sidebar-transparent .nav.nav-profile {
    border-bottom: 1px solid rgba(255,255,255,0.15);
}
.sidebar.sidebar-transparent .nav > li.has-sub > .sub-menu {
    background: url('../../img/transparent/black-0.5.png');
    background: rgba(26, 34, 41, 0.5);
}
.sidebar.sidebar-transparent .sub-menu {
    background: none;
}
.page-sidebar-toggled.page-sidebar-minified .sidebar.sidebar-transparent + .sidebar-bg,
.sidebar.sidebar-transparent + .sidebar-bg {
    background: url('../../img/sidebar_cover.jpg');
    background-size: 100% 100%;
}
.sidebar.sidebar-transparent + .sidebar-bg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../img/transparent/black-0.5.png');
    background: rgba(45, 53, 60, 0.75);
}
.page-sidebar-minified .sidebar.sidebar-transparent .nav > li.has-sub .sub-menu {
    background: url('../../img/transparent/black-0.7.png');
    background: rgba(26, 34, 41, 0.85);
}
.sidebar.sidebar-transparent .nav > li.nav-header {
    color: #fff;
}
.page-sidebar-minified .sidebar.sidebar-transparent .nav > li.has-sub:hover > a, 
.page-sidebar-minified .sidebar.sidebar-transparent .nav > li.has-sub:focus > a,
.sidebar.sidebar-transparent .nav > li.expand > a, 
.sidebar.sidebar-transparent .nav > li > a:focus, 
.sidebar.sidebar-transparent .nav > li > a:hover {
    background: url('../../img/transparent/black-0.5.png');
    background: rgba(0, 0, 0, 0.5);
}


/* 15.2 Top Menu Element */

.page-with-top-menu {
    padding-top: 106px;
}
.page-with-top-menu .sidebar {
    padding-top: 106px;
}
.top-menu {
    background: #37474F;
    height: 46px;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1015;
}
.top-menu .nav {
    white-space: nowrap;
}
.top-menu .nav > li {
    display: inline-block;
    position: relative;
}
.top-menu .nav > li + li {
    margin-left: -3px;
}
.top-menu .nav > li > a {
    padding: 10px 20px;
    color: #cfd8dc;
    line-height: 26px;
    font-weight: 500;
}
.top-menu .nav > li > a .badge,
.top-menu .nav > li > a .label {
    margin-left: 5px;
}
.top-menu .nav > li > a .badge {
    background: #1b1f24;
}
.top-menu .nav > li > a .icon-img {
	float: left;
	margin: 0 10px 0 0;
	width: 24px;
	height: 24px;
	overflow: hidden;
	border-radius: 4px;
}
.top-menu .nav > li > a .icon-img img {
	max-width: 100%;
	max-height: 100%;
	display: block;
}
.top-menu .nav > li > a .badge.pull-right,
.top-menu .nav > li > a .label.pull-right {
    margin-top: 4px;
}
.top-menu .nav > li.active > a .label.label-theme,
.top-menu .nav > li.active > a .badge {
    background: url(../../img/transparent/black-0.4.png);
    background: rgba(0,0,0,0.4);
}
.top-menu .nav > li > a i {
    float: left;
    font-size: 24px;
    margin-right: 10px;
    line-height: 24px;
    width: 24px;
    text-align: center;
    color: #546E7A;
}
.top-menu .nav > li.active > a i {
    color: #fff;
}
.top-menu .nav > li > a .caret {
    border-width: 5px;
}
.top-menu .nav > li > a .caret.pull-right {
    margin-top: 11px;
    margin-left: 10px;
}
.top-menu .nav > li:hover > a,
.top-menu .nav > li:focus > a,
.top-menu .nav > li > a:hover,
.top-menu .nav > li > a:focus {
    background: none;
}


/* 15.2.1 Top Menu Sub Menu Setting */

.top-menu .nav .sub-menu {
    display: none;
    position: absolute;
    left: 0;
    min-width: 220px;
    max-width: 320px;
    top: 100%;
    background: #2F3C43;
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
}
.top-menu .sub-menu > li > a {
    padding: 5px 20px;
    display: block;
    color: #8a8f94;
    text-decoration: none;
    position: relative;
}
.top-menu .nav .sub-menu > li > a .caret {
    margin-top: 7px;
}
.top-menu .sub-menu .sub-menu {
    background: url(../../img/transparent/black-0.2.png);
    background: rgba(0,0,0,0.2);
    position: relative;
    left: 0;
    top: 0;
}
.top-menu .sub-menu > li.active > a,
.top-menu .sub-menu > li.active > a:hover,
.top-menu .sub-menu > li.active > a:focus,
.top-menu .sub-menu > li > a:hover,
.top-menu .sub-menu > li > a:focus {
    color: #fff;
}
.top-menu .nav > li.has-sub:hover > .sub-menu {
    display: block;
}
.top-menu .nav > li.active > a,
.top-menu .nav > li.active > a:hover,
.top-menu .nav > li.active > a:focus{
    background: #009688;
    color: #fff;
}


/* 15.2.2 Top Menu Left & Right Control Setting */

.top-menu .nav > li.menu-control {
    position: absolute;
    top: 0;
    width: 46px;
    background: #37474F;
    height: 46px;
    margin: 0;
}
.top-menu .nav > li.menu-control > a {
    padding: 0;
    text-align: center;
    line-height: 46px;
}
.top-menu .nav > li.menu-control > a i {
    float: none;
    margin: 0;
    font-size: 24px;
    display: block;
    line-height: 46px;
    color: #fff;
    width: auto;
}
.top-menu .nav > li.menu-control.menu-control-left {
    left: -56px;
}
.top-menu .nav > li.menu-control.menu-control-right {
    right: -56px;
}
@keyframes slideInLeft {
    from { left: -56px; }
    to { left: 0; }
}
@-webkit-keyframes slideInLeft {
    from { left: -56px; }
    to { left: 0; }
}
.top-menu .nav > li.menu-control.menu-control-left.show {
    -webkit-animation: slideInLeft .2s;
    animation: slideInLeft .2s;
    left: 0;
}
@keyframes slideInRight {
    from { right: -56px; }
    to { right: 0; }
}
@-webkit-keyframes slideInRight {
    from { right: -56px; }
    to { right: 0; }
}
.top-menu .nav > li.menu-control.menu-control-right.show {
    -webkit-animation: slideInRight .2s;
    animation: slideInRight .2s;
    right: 0;
}
.top-menu .nav > li.menu-control.menu-control-left > a {
    -webkit-box-shadow: 5px 0 8px -3px rgba(0,0,0,0.5);
    box-shadow: 5px 0 8px -3px rgba(0,0,0,0.5);
}
.top-menu .nav > li.menu-control.menu-control-right > a {
    -webkit-box-shadow: -5px 0 8px -3px rgba(0,0,0,0.5);
    box-shadow: -5px 0 8px -3px rgba(0,0,0,0.5);
}


/* 15.3 Boxed Layout Setting */

.boxed-layout {
    background-image: url(../../img/pattern-cover.png);
}
.boxed-layout .page-container {
    width: 1080px;
    margin: 0 auto;
    -webkit-box-shadow: 0 0px 30px rgba(0,0,0,0.5);
    box-shadow: 0 0px 30px rgba(0,0,0,0.5);
    min-height: 100%;
    background: #f7f7f7;
    overflow: hidden;
    position: relative;
}
.boxed-layout .page-container .header,
.boxed-layout .page-container .sidebar,
.boxed-layout .page-container .sidebar-bg {
    left: auto;
}
.boxed-layout .page-container .header {
    width: 1080px;
    right: auto;
}
.boxed-layout .top-menu,
.boxed-layout .pace {
    width: 1080px;
    left: 50%;
    margin-left: -540px;
}
.boxed-layout .pace .pace-progress,
.boxed-layout .pace .pace-activity,
.boxed-layout .page-with-top-menu .top-menu {
    position: absolute;
}
.boxed-layout .page-sidebar-minified .sidebar,
.boxed-layout .page-with-top-menu.page-sidebar-minified .sidebar {
    position: relative;
    float: left;
    padding-top: 0;
}


/* 15.4 Nvd3 Chart Setting */

.nvtooltip {
    font-family: 'Roboto', "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 12px !important;
    background: #fff !important;
    -webkit-box-shadow: 0 0px 4px rgba(0,0,0,0.25) !important;
    box-shadow: 0 0px 4px rgba(0,0,0,0.25) !important;
    border: none !important;
    padding: 5px 10px !important;
}
.nvtooltip table td.legend-color-guide div {
    border: none !important;
}
.nvtooltip table thead strong {
    text-decoration: underline !important;
}



/* -------------------------------
   16.0 Update - V2.0
------------------------------- */

/* 16.1 Material Loader */

.material-loader {
    position: fixed;
    width: 200px;
    height: 100px;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -100px;
}
.material-loader .message {
    line-height: 100px;
    text-align: center;
}
.ie8 .material-loader .message {
    padding-left: none;
}
.material-loader .circular {
    animation: rotate 2s linear infinite;
    height: 36px;
    width: 36px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -24px;
    margin-top: -55px;
}
.material-loader .path {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate{
    100%{
        transform: rotate(360deg);
    }
}
@keyframes dash{
    0%{
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}
@keyframes color{
    100%, 0%{
        stroke: #212121;
    }
}



/* -------------------------------
   17.0 Update - V2.1
------------------------------- */

/* 17.1 Material Icon Mobile Setting */

.material-icons {
    text-rendering: optimizeLegibility;
}


/* 17.2 Intro Js Setting */

.introjs-hint-dot {
    left: -20px !important;
    top: -20px !important;
}
.introjs-hint {
    width: 10px !important;
    height: 10px !important;
}
.introjs-helperNumberLayer {
    font-size: 12px;
    background: #F44336 !important;
    font-family: Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
    border: none !important;
    text-shadow: none !important;
    padding: 0 !important;
    text-align: center !important;
}


/* 17.3 Summernote Setting */

.note-editor.note-frame {
    border: none !important;
    margin: 0 !important;
}
.note-popover .popover-content, .panel-heading.note-toolbar {
    padding: 10px 15px !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}


/* 17.4 Input Group Addon Focus Setting */

.input-group-addon:focus {
    outline: none !important;
}


/* 17.5 Bootstrap Color Palette Setting */

.bootstrap-colorpalette .btn-color:focus {
    outline: none !important;
    border: 1px solid #000 !important;
}


/* 17.6 jQuery Simple Colorpicker Setting */

.simplecolorpicker span.color:focus,
.simplecolorpicker.icon:focus {
    outline: none !important;
}
.simplecolorpicker.inline {
    padding: 5px 0 0 !important;
}


/* 17.7 Dropzone Setting */

.dropzone {
    border: 2px dashed #E0E0E0 !important;
    background: white !important;
}
.dropzone.dz-clickable .dz-message, 
.dropzone.dz-clickable .dz-message * {
    cursor: pointer !important;
}
.dropzone .dz-message {
    font-weight: 500 !important;
    color: #242a30 !important;
    font-size: 24px !important;
}
.dropzone .dz-message {
    text-align: center !important;
    margin: 45px 0 !important;
}
.dropzone .dz-message .dz-note {
    font-size: 14px !important;
    font-weight: normal !important;
    display: block !important;
    margin-top: 5px !important;
    color: #777 !important;
}
.dropzone .dz-preview .dz-image {
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}
.dropzone .dz-preview .dz-error-message {
    background: #F44336 !important;
}
.dropzone .dz-preview .dz-error-message:after {
    border-bottom-color: #F44336 !important;
}



/* -------------------------------
   18.0 Update - V3.0
------------------------------- */

/* 18.1 CSS Checkbox Setting */

.checkbox.checkbox-css {
	position: relative;
}
.checkbox.checkbox-css.checkbox-inline {
	padding-left: 0;
}
.checkbox.checkbox-css label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 8px;
	width: 14px;
	height: 14px;
	border-radius: 4px;
	border: 1px solid #ccd0d4;
}
.checkbox.checkbox-css input {
	display: none;
}
.checkbox.checkbox-css input:checked + label:after {
	content: '\f00c';
	position: absolute;
	top: 8px;
	left: 0;
	font-size: 10px;
	font-family: FontAwesome;
	color: #fff;
	line-height: 14px;
	width: 14px;
	text-align: center;
}
.checkbox.checkbox-css input:checked + label:before {
	background: #348fe2;
	border-color: #348fe2;
}
.checkbox.checkbox-css.checkbox-success input:checked + label:before {
	background: #00acac;
	border-color: #00acac;
}
.checkbox.checkbox-css.checkbox-info input:checked + label:before {
	background: #49b6d6;
	border-color: #49b6d6;
}
.checkbox.checkbox-css.checkbox-danger input:checked + label:before {
	background: #ff5b57;
	border-color: #ff5b57;
}
.checkbox.checkbox-css.checkbox-warning input:checked + label:before {
	background: #f59c1a;
	border-color: #f59c1a;
}
.checkbox.checkbox-css.checkbox-inverse input:checked + label:before {
	background: #2d353c;
	border-color: #2d353c;
}


/* 18.2 CSS Radio Button Setting */

.radio.radio-css {
	position: relative;
}
.radio.radio-css.radio-inline {
	padding-left: 0;
}
.radio.radio-css label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 8px;
	width: 14px;
	height: 14px;
	border-radius: 14px;
	border: 1px solid #ccd0d4;
}
.radio.radio-css input {
	display: none;
}
.radio.radio-css input:checked + label:after {
	content: '';
	position: absolute;
	top: 12px;
	left: 4px;
	width: 6px;
	height: 6px;
	border-radius: 6px;
	background: #fff;
}
.radio.radio-css input:checked + label:before {
	background: #348fe2;
	border-color: #348fe2;
}
.radio.radio-css.radio-success input:checked + label:before {
	background: #00acac;
	border-color: #00acac;
}
.radio.radio-css.radio-info input:checked + label:before {
	background: #49b6d6;
	border-color: #49b6d6;
}
.radio.radio-css.radio-danger input:checked + label:before {
	background: #ff5b57;
	border-color: #ff5b57;
}
.radio.radio-css.radio-warning input:checked + label:before {
	background: #f59c1a;
	border-color: #f59c1a;
}
.radio.radio-css.radio-inverse input:checked + label:before {
	background: #2d353c;
	border-color: #2d353c;
}


/* 18.3 Bootstrap SweetAlert Setting */

.sweet-alert .btn:focus {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.sweet-alert .sa-icon.sa-success {
  border-color: #00acac !important;
}
.sweet-alert .btn-success:focus {
	border-color: #008a8a !important;
}
.sweet-alert .sa-icon.sa-success .sa-placeholder {
	border-color: rgba(0, 172, 172, 0.2) !important;
}
.sweet-alert .sa-icon.sa-success .sa-line {
	background-color: #00acac !important;
}
.sweet-alert .sa-icon.sa-info {
	border-color: #49b6d6 !important;
}
.sweet-alert .btn-info:focus {
	border-color: #3a92ab !important;
}
.sweet-alert .btn-warning:focus {
	border-color: #c47d15 !important;
}
.sweet-alert .sa-icon.sa-warning .sa-body,
.sweet-alert .sa-icon.sa-warning .sa-dot {
	background-color: #f59c1a !important;
}
.sweet-alert .sa-icon.sa-warning {
	border-color: #f59c1a !important;
}
.sweet-alert .btn-danger:focus {
	border-color: #cc4946 !important;
}
.sweet-alert .sa-icon.sa-error .sa-line {
	background-color: #ff5b57 !important;
}
.sweet-alert .sa-icon.sa-error {
	border-color: #ff5b57 !important;
}


/* 18.4 Bootstrap 4 - Cards Setting */

.card {
	position: relative;
	display: block;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	  -ms-flex-direction: column;
		  flex-direction: column;
	background-color: #fff;
	border: none;
	border-radius: 4px;
	margin-bottom: 15px;
}
.card-block {
	padding: 15px;
}
.card-title {
	margin-bottom: 15px;
}
.card-subtitle {
	margin-top: -5px;
	margin-bottom: 0;
}
.card-text:last-child {
	margin-bottom: 0;
}
.card-link:hover {
	text-decoration: none;
}
.card-link + .card-link {
  margin-left: 30px;
}
.card > .list-group:first-child .list-group-item:first-child {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.card > .list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.card-header {
	padding: 10px 15px;
	margin-bottom: 0;
	margin-top: 0;
	background-color: #fafafa;
	border-bottom: 1px solid #e2e7eb;
	font-weight: 600;
}
.card-header:first-child {
	border-radius: 4px 4px 0 0;
}
.card-footer {
	padding: 10px 15px;
	background-color: #fafafa;
	border-top: 1px solid #e2e7eb;
	font-weight: 600;
}
.card-footer:last-child {
	border-radius: 0 0 4px 4px;
}
.card-header-tabs {
	margin-right: -5px;
	margin-bottom: -5px;
	margin-left: -5px;
	border-bottom: 0;
}
.card-header-pills {
	margin-right: -5px;
	margin-left: -5px;
	margin-bottom: 0px;
}
.card-primary {
	background-color: #348fe2;
	border-color: #348fe2;
}
.card-primary .card-header,
.card-primary .card-footer {
	background-color: transparent;
}
.card-success {
	background-color: #00acac;
	border-color: #00acac;
}
.card-success .card-header,
.card-success .card-footer {
	background-color: transparent;
}
.card-info {
	background-color: #49b6d6;
	border-color: #49b6d6;
}
.card-info .card-header,
.card-info .card-footer {
	background-color: transparent;
}
.card-warning {
	background-color: #f59c1a;
	border-color: #f59c1a;
}
.card-warning .card-header,
.card-warning .card-footer {
	background-color: transparent;
}
.card-danger {
	background-color: #ff5b57;
	border-color: #ff5b57;
}
.card-danger .card-header,
.card-danger .card-footer {
	background-color: transparent;
}
.card-outline-primary {
	background-color: transparent;
	border-color: #0275d8;
}
.card-outline-secondary {
	background-color: transparent;
	border-color: #ccc;
}
.card-outline-info {
	background-color: transparent;
	border-color: #5bc0de;
}
.card-outline-success {
	background-color: transparent;
	border-color: #5cb85c;
}
.card-outline-warning {
	background-color: transparent;
	border-color: #f0ad4e;
}
.card-outline-danger {
	background-color: transparent;
	border-color: #d9534f;
}
.card-inverse {
	color: rgba(255, 255, 255, 0.65);
}
.card-inverse .card-header,
.card-inverse .card-footer {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0.2);
}
.card-inverse .card-header,
.card-inverse .card-footer,
.card-inverse .card-title,
.card-inverse .card-blockquote {
	color: #fff;
}
.card-inverse .card-link,
.card-inverse .card-text,
.card-inverse .card-subtitle,
.card-inverse .card-blockquote .blockquote-footer {
	color: rgba(255, 255, 255, 0.65);
}
.card-inverse .card-link:focus, .card-inverse .card-link:hover {
	color: #fff;
}
.card-blockquote {
	padding: 0;
	margin-bottom: 0;
	border-left: 0;
}
.card-img {
	border-radius: 4px;
	max-width: 100%;
}
.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 15px;
}
.card-img-top {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	max-width: 100%;
}
.card-img-bottom {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	max-width: 100%;
}

@media (min-width: 576px) {
	.card-deck {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-flow: row wrap;
			-ms-flex-flow: row wrap;
				flex-flow: row wrap;
	}
	.card-deck .card {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		-webkit-flex: 1 0 0%;
			-ms-flex: 1 0 0%;
				flex: 1 0 0%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
			-ms-flex-direction: column;
				flex-direction: column;
	}
  	.card-deck .card:not(:first-child) {
		margin-left: 15px;
  	}
  	.card-deck .card:not(:last-child) {
    	margin-right: 15px;
  	}
}

@media (min-width: 576px) {
	.card-group {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-flow: row wrap;
			-ms-flex-flow: row wrap;
				flex-flow: row wrap;
	}
	.card-group .card {
		-webkit-box-flex: 1;
		-webkit-flex: 1 0 0%;
			-ms-flex: 1 0 0%;
				flex: 1 0 0%;
	}
	.card-group .card + .card {
		margin-left: 0;
		border-left: 0;
		border-left: 1px solid #e2e7eb;
	}
	.card-group .card:first-child {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
	.card-group .card:first-child .card-img-top {
		border-top-right-radius: 0;
	}
	.card-group .card:first-child .card-img-bottom {
		border-bottom-right-radius: 0;
	}
	.card-group .card:last-child {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
	.card-group .card:last-child .card-img-top {
		border-top-left-radius: 0;
	}
	.card-group .card:last-child .card-img-bottom {
		border-bottom-left-radius: 0;
	}
	.card-group .card:not(:first-child):not(:last-child) {
		border-radius: 0;
	}
	.card-group .card:not(:first-child):not(:last-child) .card-img-top,
	.card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
		border-radius: 0;
	}
}

@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		   -moz-column-count: 3;
				column-count: 3;
		-webkit-column-gap: 1.25rem;
		   -moz-column-gap: 1.25rem;
				column-gap: 1.25rem;
	}
	.card-columns .card {
		display: inline-block;
		width: 100%;
		margin-bottom: 15px;
	}
}

.list-group-item {
	border-color: #e2e7eb;
}
.nav-tabs.card-header-tabs {
	background: none;
	margin-bottom: -11px;
}
.nav-tabs.card-header-tabs > li > a {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
	border-bottom-color: transparent !important;
}
.nav-tabs.card-header-tabs > li > a:hover,
.nav-tabs.card-header-tabs > li > a:focus {
	background: none;
}
.nav-tabs.card-header-tabs > li > a.active,
.nav-tabs.card-header-tabs > li.active > a {
	border-bottom-color: #fff !important;
	background: #fff !important;
	color: #000;
}
.nav-pills > li > a.active, 
.nav-pills > li > a.active:focus, 
.nav-pills > li > a.active:hover {
	background: #242a30;
	color: #fff;
}
.card.card-inverse footer {
	color: rgba(255,255,255,0.75);
}
.card[class*="card-outline-"] {
	border-width: 1px;
	border-style: solid;
}

/*Scroll table start*/
.extShipmentList {
    height: 219px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1px;
    padding-top: 15px !important;
    overflow: auto
}
.extingList {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1px;
    padding-top: 0px !important;
    overflow: auto
}
.extRAList {
    height: 250px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1px;
    padding-top: 0px !important;
    overflow: auto
}
/* for custom scrollbar for webkit browser*/
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.3);
}
.div_Scroll_BottomList {
    /* height: 182px; */
    height: auto;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1px;
    border-radius: 5px;
    padding-top: 2px !important;
    overflow: auto;
    max-height: 450px;
}
.po_DetailsData {
    height: 150px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    /*margin-top: 1px;
    border-radius: 5px;
    padding-top: 2px !important;*/
    overflow: auto;
}
.documentsTab {
    height: 232px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1px;
    padding-top: 0px !important;
    overflow: auto
}
.log_Scroll_BottomList {
    max-height: 640px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1px;
    border-radius: 5px;
    padding-top: 2px !important;
    overflow: auto;
}
.projectAccessList {
    max-height: 442px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1px;
    border-radius: 5px;
    padding-top: 0px !important;
    overflow: auto;
}
.po_Modal_List {
    max-height: 500px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1px;
    border-radius: 5px;
    padding-top: 2px !important;    
}
/*static header with scroll rows*/
.table-fixed thead {
    width: 97%;
}

.table-fixed tbody {
    height: 230px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
    display: block;
}

.table-fixed tbody td, .table-fixed thead > tr > th {
    float: left;
    padding: 3px 9px;
    font-size: 12px;
    border-bottom-width: 0;
}
/*end of static table*/
.axis_tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

    .axis_tooltip .axis_tooltip_text {
        visibility: hidden;
        width: 250px;
        height: 76px;
        background-color: white;
        color: black;
        text-align: left;
        border-radius: 5px;
        padding: 5px 5px;
        position: absolute;
        z-index: 1;
        top: 186%;
        /*right: -37px; this is for in modal*/
        /*right:-235px;*/
        right:0px;
            border: 1px solid #000;
        font-size: 12px
    }
    /*css for up arrow*/
        .axis_tooltip .axis_tooltip_text::after {
            content: "";
            position: absolute;
            bottom: 100%;
            /*right: 34px; arrow  horizotal position*/
            margin-left: -5px;
            /*setting Arrow size set 10 for effect*/
            border-width: 0px;
            border-style: solid;
            border-color: transparent transparent black transparent
        }
    .axis_tooltip:hover .axis_tooltip_text {
        visibility: visible;
     
    }

    .axis_model_dialog_overflow{
        overflow:unset !important;
    }

.tbl_axis.table-hover > tbody > tr:hover {
    background-color: #C2E4F5;   
}



.tbl_PoItemList.table-hover > tbody > tr:hover {
    background-color: #c1e4f7;
}
.tbl_PoItemList.table-hover > tbody > tr {
    vertical-align :middle !important;
}
.tbl_PoItemList.table-hover.table > tbody > tr td {
    padding: 0px 10px 0px 10px;
    margin: 20px 0px 0px 0px !important;
}
.tbl_axis.table-hover > tbody > tr td {
    padding:5px 5px;
}

.tbl_shipment.table-hover.table > tbody > tr td {
    padding: 2px 2px;    
}

.tbl_listing.table-hover > tbody > tr td {
    padding: 3px 9px;
    font-size: 12px
}
.tbl_shipment.table {
    margin-bottom: 0px !important;
}
/*React select styling*/


/*// The main container element*/ 
.Collapsible {
    background-color: inherit;
}

/*//The content within the collaspable area*/
.Collapsible__contentInner {
    padding: 0px; 
    border-top: 0;
  
}

    .Collapsible__contentInner .table-hover > tbody > tr td {
        padding: 3px 9px;
        font-size: 12px
    }

    .Collapsible__contentInner p:last-child {
        margin-bottom: 0;
    }

/*//The link which when clicked opens the collapsable area*/
.Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
  
    padding: 10px;
    /*background: #E6E7E7;*/
    color: white;
}

.Collapsible__trigger:after {
    /*font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;*/
}

.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    /*background-color: grey;*/
}

.CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
    background-color: darkslateblue;
}

.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    /*background-color: #CBB700;*/
    color: black;
}
.axis_material_icons {
    font-size: 18px
}
.rc-calendar {
    width: 200px !important;
}

.rc-calendar-body {
    padding: 2px !important;
    height: 198px !important;
}

.rc-calendar-footer {
    border-top: 1px solid #ccc;
    padding: 2px !important;
    text-align: center;
    position: relative;
}

.rc-calendar-input-wrap {
    position: relative;
    padding: 2px !important;
    border-bottom: 1px solid #e9e9e9;
}

.rc-calendar-date {
    color: #000 !important;
}

.rc-calendar-last-month-cell .rc-calendar-date, .rc-calendar-next-month-btn-day .rc-calendar-date {
    color: #bbb !important;
}

.rc-calendar-today-btn:hover, .rc-calendar-ok-btn:hover, .rc-calendar-time-picker-btn:hover {
    color: cadetblue !important;
}

.rc-calendar-today-btn, .rc-calendar-ok-btn, .rc-calendar-time-picker-btn {
    color: #0450c2 !important;
}

.rc-calendar-today .rc-calendar-date {
    border: 1px solid #3fc7fa !important;
}

.rc-calendar-selected-day .rc-calendar-date {
    background: #3C78EF !important;
}

.rc-calendar-date:hover {
    background: #3C78EF !important;
    cursor: pointer;
}
