.panel-body {
    min-height: 100px;
}

.login-button {
    background-color: #2196F3;
    color: white;
}

.login-logo {
    height: 145px;
}

.text-white {
    color: #ffffff;
}

.background-black {
    background-color: #000000;
}

.text-axis-blue {
    color: #2196F3 !important;
}

.axis-blue-btn {
    background-color: #2196F3;
    border-color: #2196F3;
}

.icon-hand {
    cursor: pointer;
}

.panel-axis .panel-heading {
    background: #075799 !important;
}

.panel-axis > .panel-heading {
    color: #ffffff;
}

.btn.btn-scroll-to-top, .label.label-theme, .page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, .page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, .page-sidebar-minified .sidebar .nav > li.has-sub.active > a, .sidebar .nav > li.active > a, .sidebar .nav > li.active > a:focus, .sidebar .nav > li.active > a:hover {
    background: #2196F3;
}

.spinner-axis {
    height: 40px;
    width: 40px;
    border: 2px solid #fff;
    border-top: 2px solid #24B498;
    border-radius: 100%;
}

.width-20 {
    width: 20px;
}

.modal-dialog {
    height: 100%;
    overflow: auto;
}

.text-break {
    word-wrap: break-word;
}

.no-padding {
    padding: 0;
}

.table-no-padding > thead > tr > th, .table-no-padding > tbody > tr > th, .table-no-padding > tfoot > tr > th, .table-no-padding > thead > tr > td, .table-no-padding > tbody > tr > td, .table-no-padding > tfoot > tr > td {
    padding: 8px;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 675px;
        margin: 30px auto;
    }
}

.nav > li > a {
    color: white;
}

.nav-tabs {
    background: #075799;
}

.pagination > .active > a {
    background: #2196F3 !important;
    border-color: #2196F3 !important;
}

.pagination-container {
    display: block;
    float: left;
}

.pagination-dropdown {
    height: 27px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

table.loading tbody {
    position: relative;
}

    table.loading tbody:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("../img/loading-icon.gif") no-repeat center;
        background-size: 50px 50px;
        content: "";
    }

.table-header-input {
    border: 0;
}

    .table-header-input::-webkit-input-placeholder {
        color: #000;
    }

    .table-header-input::-moz-placeholder {
        color: #000;
    }

.has-error .Select-control {
    border-color: red;
}

.has-error .control-label {
    color: black;
}

.tab-error {
    border-color: #ff0000;
}

.navbar-nav > li > a .label {
    background-color: #075799;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: #C9E6FF;
}

.dropdown-menu.media-list p {
    max-width: 700px;
}

.dropdown-menu.media-list {
    max-width: 700px;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #07d;
    animation: spinner .6s linear infinite;
}

.spinner {
    z-index: 2;
}

.overlay {
}

.react-datepicker__input-container {
    width: inherit;
}

.react-datepicker-wrapper {
    width: 100%;
}

.form-control {
    font-size: 13px;
}

.pro-number-list-container {
    margin-top: 20px;
    /*border: 1px solid;
    border-radius: 5px;*/
    padding-top: 15px !important;
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.btn-link:hover {
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.23);
    text-decoration: underline;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] {
    color: #000000;
}

.react-datepicker__close-icon::after {
    background-color: #1270BC !important;
    font-size: 9px !important;
    width: 14px !important;
    height: 14px !important;
    padding: 3px !important;
    right: 3px !important;
    top: 30% !important;
}

.navbar-dark {
    background-color: #003f72;
    background: #003f72;
    font-weight: bolder;
    min-width: 225px;
}

    .navbar-dark a {
        padding: 6px 6px;
        text-decoration: none;
        display: block;
    }

        .navbar-dark a:hover {
            background-color: #0D47A1;
            color: #f5f5f5;
        }

  .dropdown-menutext {
    font-weight: bolder;
    color: #f5f5f5;
    margin-left: 5px;
    text-decoration: none;
}

.maincontent {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
}

.trv-report-viewer {
    overflow: hidden;
    height: 700px;
    width: 100%;
    position: relative;
}
.modal-stackedBackdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1100;
    background-color: rgba(0,0,0,.3);
}
.sideBarScroll {
    height: 100%;
    overflow-y: auto;
}

.rc-time-picker-panel {
    z-index: 1310 !important;
}

.select-outer-top .Select-menu-outer {
    top: auto;
    bottom: 100%;
}

/*.menu-outer-top .Select-menu-outer {
    bottom: 35px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
}*/