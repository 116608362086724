.form-group {
    margin-bottom: 7px;
    font-size:11px;
}
.form-control {
    /* height: 38px !important;  */
    height: 34px !important; 
    /* margin-bottom: 4px !important; */
    font-size: 11px;
}
.custom-placeholder {
    font-size: 11px; /* Adjust the text size of the placeholder */
  }

.form-control.input-lg {
    font-size: 11px !important;
    border-radius: 2px !important;
    height: 46px !important;
    padding: 10px 16px !important;
}

textarea.form-control {
    height: auto !important;
}
/*select dropdown*/
    .Select div.Select-input, .Select div.Select-control {
    height: 34px;
    /*height: auto;*/
}

tr {
    height: 34px;
}

td {
    align-content: center;
}

ul.nav.nav-tabs {
    align-content: center;
    /* padding-left: 5px; */
    height: 34px;
}

.panel-heading-btn {
    padding: 2px;
}

.item-form-body {
    max-height: 860px;
    overflow: scroll;
    width: 675px;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
    line-height: 34px !important;
}
/*consistent look for label text*/
.control-label {
    font-size:11px;  
}
input[type="number"] {
    /* height: 38px !important; */
    height: 34px !important;
}
label {   
    margin-bottom: 0px !important;  
}
/*Tab content*/
.panel {
    margin-bottom: 7px !important;
}
.date-control{
    height: 33px !important;
    margin-bottom: 4px !important;
    font-size: 11px;
}
@media (min-width: 1366px) {
    /* CSS that should be displayed if width is equal to or more than 1366px goes here */
    .axis-Upper-tab-content {
        margin-bottom: 0px !important;
        min-height: 349px !important;
        /* max-height: 349px !important; */
        /*min-height: 55% !important;
        max-height: 55% !important;*/
        /*overflow-y: scroll;*/
    }

    .axis-Bottom-tab-content {
        margin-bottom: 0px !important;
        min-height: 275px !important;
        /*min-height: 45% !important;*/      
        padding: 1px 13px 6px 17px;
    }
}
@media (max-width: 1366px) {
    /* CSS that should be displayed if width is equal to or less than 1366px goes here */
    .axis-Upper-tab-content {
        margin-bottom: 0px !important;
        height: auto;
    }

    .axis-Bottom-tab-content {
        margin-bottom: 0px !important;
        height: auto;
    }
}


/*nav tabs*/
.nav-tabs > li > a {
    margin-right: 5px;
    line-height: 6px;
    border-radius: 2px 2px 0 0;
}
.nav > li > a {
    position: relative;
    display: block;
    padding: 14px 15px;
}
.panel-heading {
    padding: 6px 15px;
    border: none;
    height: 34px;
}
